import * as React from "react";
import "./SettingsGroup.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AsolviDropDownList from "../../Elements/AsolviDropDownList/AsolviDropDownList";
import { AsolviDropDownItem } from "../../Elements/AsolviDropDownList/AsolviDropDownList";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';

export interface Props extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  languageDropDownItems: AsolviDropDownItem[];
  databasesDropDownItems: AsolviDropDownItem[];
  continueButton: () => void;
  customersDropDownItems: AsolviDropDownItem[];
  hideLanguage: boolean;
  hideDatabase: boolean;
  hideCustomers: boolean;
}
class SettingsGroup extends React.Component<Props, object>{
  render() {
    const { languageDropDownItems, t, continueButton, databasesDropDownItems, 
      customersDropDownItems, hideLanguage, hideDatabase, hideCustomers } = this.props;    
    return (
      <Container fluid>
        <Row className="titleRow">
          <Col>
            <Row>
              <Col>
                <h1><b>{t("SettingsGroup.settingsGroupSigninTo")}</b></h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <h1><b>{t("Standard.productName")}</b></h1>
              </Col>
            </Row>
          </Col>
        </Row>
        {!hideLanguage &&
          <Row className="dropdownRow">
            <Col>
              <AsolviDropDownList  dropDownTitle={t("Standard.selectLangauge")} asolviDropDownItems={languageDropDownItems}></AsolviDropDownList>
            </Col>
          </Row>
        }
        {!hideDatabase &&
          <Row className="dropdownRow">
            <Col>
              <AsolviDropDownList dropDownTitle={t("Standard.selectDatabase")} asolviDropDownItems={databasesDropDownItems}></AsolviDropDownList>
            </Col>
          </Row>
        }
        {!hideCustomers &&
          <Row className="dropdownRow">
            <Col>
              <AsolviDropDownList  dropDownTitle={t("Standard.selectCustomer")} asolviDropDownItems={customersDropDownItems}></AsolviDropDownList>
            </Col>
          </Row>
        }
          <Row className="continueButtonRow">
            <Col>
              <AsolviButton buttonText={t("Standard.continue")} onClick={continueButton} buttonType={"submit"} ></AsolviButton>
            </Col>
          </Row>
      </Container>
    );
  }
}
export default ReactI18Next.withTranslation()(SettingsGroup);