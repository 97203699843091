import React, { FC, useEffect, useState } from "react";
import { IGridColumn } from "../../Elements/AsolviAdvancedGrid/interfaces";
import { useTranslation } from "react-i18next";
import AsolviAdvancedGrid from "../../Elements/AsolviAdvancedGrid/AsolviAdvancedGrid";
import { ContactDTO } from "./Contacts";
import AsolviFadeInOut from "../../Elements/AsolviFadeInOut/AsolviFadeInOut";
import styles from "./ContactsGrid.module.css";
import { Spinner } from "react-bootstrap";

interface Props {
  contacts: ContactDTO[],
  isLoadingContacts: boolean,
  onSelectContact: (report: ContactDTO) => void,
}

interface ContactGridRow extends ContactDTO {
  __index: number;
}


const ContactsGrid: FC<Props> = ({contacts, isLoadingContacts, onSelectContact}) => {

  const { t } = useTranslation();

  const [contactRows, setContactRows] = useState<ContactGridRow[]>([]);

  useEffect(() => {
    setContactRows(contacts.map((x, index) => ({...x, __index: index} as ContactGridRow)));
  }, [contacts])

  const gridLayout: IGridColumn<ContactGridRow>[] = [{
    fieldName: "__index",
    visible: false,
    fieldLanguageKey: "",
  }, {
    fieldName: "FirstName",
    fieldLanguageKey: t("Contacts.Properties.FirstName"),
    editable: false,
    visible: true,
  }, {
      fieldName: "LastName",
      fieldLanguageKey: t("Contacts.Properties.FirstName"),
      editable: false,
      visible: true,
    }, {
      fieldName: "ContactName",
      fieldLanguageKey: t("Contacts.Properties.ContactName"),
      editable: false,
      visible: true,
    }, {
      fieldName: "Email1",
      fieldLanguageKey: t("Contacts.Properties.Email1"),
      editable: false,
      visible: true,
      colWidth: 280,
    }, {
      fieldName: "Email2",
      fieldLanguageKey: t("Contacts.Properties.Email2"),
      editable: false,
      visible: true,
    }, {
      fieldName: "Mobile",
      fieldLanguageKey: t("Contacts.Properties.Mobile"),
      editable: false,
      visible: true
    }, {
      fieldName: "Phone",
      fieldLanguageKey: t("Contacts.Properties.Phone"),
      editable: false,
      visible: true,
    }, {
      fieldName: "Fax",
      fieldLanguageKey: t("Contacts.Properties.Fax"),
      editable: false,
      visible: true,
    }, {
      fieldName: "WebAccess",
      fieldLanguageKey: t("Contacts.Properties.WebAccess"),
      editable: false,
      visible: true,
      colWidth: 180,
      cellComponent: (item) => (
        <div style={{display: "flex", justifyContent: "center"}}> {
          item.WebAccess
            ? <span className="k-icon k-i-check" style={{ color: "#2e8532" }}></span>
            : <span className="k-icon k-i-x" style={{ color: "#da1b1e" }}></span>
        } </div>
      ),
      filterType: "boolean",
    }]

  return (
    <div className={styles.gridContainer}>
      <AsolviFadeInOut show={isLoadingContacts} lazyChildren>
        <div className={styles.gridOverlay}>
          <Spinner animation="border" variant="dark" />
        </div>
      </AsolviFadeInOut>
      <AsolviAdvancedGrid
        data={contactRows}
        filterable
        dataKeyField="__index"
        gridLayout={gridLayout}
        handleItemDoubleClick={onSelectContact}
      />
    </div>
  )
}

export default ContactsGrid;
