import * as React from "react";
import "./AsolviButton.css";
import { Button } from '@progress/kendo-react-buttons';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import "@progress/kendo-theme-material/dist/all.scss";
import { Spinner } from "react-bootstrap";
import { CSSProperties, MouseEventHandler } from "react";

//Kendo ButtonType only accepts Type instead of plain string
export type ButtonType = "button" | "submit" | "reset" | undefined;

export interface Props {
  buttonText?: string,
  buttonType: ButtonType,
  onClick?: MouseEventHandler,
  className?: string,
  buttonIconName?: string,
  buttonDisabled?: boolean,
  loading?: boolean,
  rowStyle?: CSSProperties,
  colStyle?: CSSProperties,

  // Ideally this prop should not be necessary, because normal buttons don't have 100% width as default. But because it
  // was set to 100% by default everywhere in 2020 every usage of the button assumes that the width is 100%
  widthShouldNotBe100Percent?: boolean
}

class AsolviButton extends React.Component<Props, object> {

  render() {
    const { loading, buttonText, buttonType, className, onClick, buttonIconName, buttonDisabled, children,
      widthShouldNotBe100Percent, rowStyle, colStyle } = this.props;
    const style = widthShouldNotBe100Percent
      ? {}
      : { width: '100%' };

    return (
      <Row style={rowStyle}>
        <Col sm={12} style={colStyle}>
          <Button
            className={className}
            style={style}
            onClick={onClick}
            type={buttonType}
            icon={buttonIconName}
            disabled={ buttonDisabled || loading}
          >
            {children ? children : buttonText}
            {loading && <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />            
            }
          </Button>
        </Col>
      </Row>
    );
  }
}

export default AsolviButton;
