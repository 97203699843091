import * as React from 'react';
import { useState } from "react";
import "./TaskLogFilterGroup.css";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import AsolviInput from "../../../Elements/AsolviInput/AsolviInput";
import AsolviCheckbox from '../../../Elements/AsolviCheckbox/AsolviCheckbox';
import AsolviRadioButtonGroup from '../../../Elements/AsolviRadioButton/AsolviRadioButtonGroup';
import * as ReactI18Next from 'react-i18next';
import AsolviButton from '../../../Elements/AsolviButton/AsolviButton';
import AsolviDatepicker from '../../../Elements/AsolviDatepicker/AsolviDatepicker';
import * as TaskReducer from "../../../../reducers/TaskReducer";
import AsolviNotification from '../../../Elements/AsolviNotification/AsolviNotification';

export interface IProps {
    showServiceLog: boolean;
    showHelpdeskLog: boolean;
    showMachine: boolean;
    showMachineId: boolean;
    loadingData: boolean,
    searchFunction: () => void;
    extractData?: (data: TaskReducer.ITaskLogFilter) => void; 
    clearData?: boolean;
}

const TaskLogFilterGroup: React.FunctionComponent<IProps> = (Props) => {
    const { extractData, clearData } = Props;
    const { t } = ReactI18Next.useTranslation();
    const today = new Date();
    const [ProjectNo, setProjectNo] = useState<string>();
    const [MachineNo, setMachineNo] = useState<string>();
    const [ModelType, setModelType] = useState<string>();
    const [MachineIdNo, setMachineIdNo] = useState<string>();
    const [ReferenceInfo, setReferenceInfo] = useState<string>();
    const [CustomerName, setCustomerName] = useState<string>();
    const [PeriodType, setPeriodType] = useState<string>("thismonth");
    const [DateFrom, setDateFrom] = useState<Date>(today);
    const [DateTo, setDateTo] = useState<Date>(today);
    const [OpenJobs, setOpenJobs] = useState<boolean>(true);
    const [ClosedJobs, setClosedJobs] = useState<boolean>(true);
    const [ServiceJobs, setServiceJobs] = useState<boolean>(true);
    const [HelpdeskJobs, setHelpdeskJobs] = useState<boolean>(true);
    const [showNotify, setShowNotify] = React.useState<boolean>(false);
    const [notifyMessage, setNotifyMessage] = React.useState<string>("");
    const [notifyStyle, setNotifyStyle] = React.useState<any>(undefined);

    const [error, setError] = React.useState<any>(undefined);

    const handleCloseNotification = () => {          
        setShowNotify(false);
        setError(undefined);
    }  

    React.useEffect(()=>{
        let timeOut = {} as NodeJS.Timeout;
        if(error){
            setNotifyMessage(error);
            setNotifyStyle('error');
            setShowNotify(true);
            timeOut = setTimeout(() => {
                handleCloseNotification();
            }, 4000);
        }
        return () => {clearTimeout(timeOut)}
    },[error])


    React.useEffect(()=>{

        const sendData = () =>{
            if(extractData)
            {
                let data = {} as TaskReducer.ITaskLogFilter;
                data.ProjectNo = ProjectNo ? Number.parseInt(ProjectNo) : undefined; 
                data.MachineNo = MachineNo ? MachineNo : ""; 
                data.ModelType = ModelType ? ModelType :  ""; 
                data.MachineIdNo = MachineIdNo ? Number.parseInt(MachineIdNo) : undefined; 
                data.ReferenceInfo = ReferenceInfo ? ReferenceInfo : ""; 
                data.CustomerName = CustomerName ? CustomerName : "";  
                data.DatePeriod = PeriodType;       
                data.DateFrom = DateFrom;       
                data.DateTo = DateTo;       
                data.OpenJobs = OpenJobs;       
                data.ClosedJobs = ClosedJobs;  
                data.ServiceJobs = (Props.showServiceLog && ServiceJobs) || !Props.showHelpdeskLog;
                data.HelpdeskJobs = (Props.showHelpdeskLog && HelpdeskJobs) || !Props.showServiceLog;     
    
                extractData(data);
            }       
        }

        sendData();
      },[ProjectNo, MachineNo, ModelType, MachineIdNo, ReferenceInfo, CustomerName, PeriodType, DateFrom, DateTo, OpenJobs, ClosedJobs, ServiceJobs, HelpdeskJobs, Props.showServiceLog, Props.showHelpdeskLog, extractData]);
    
    React.useEffect(() => {
        if (clearData !== undefined && clearData) {  
            setProjectNo("");
            setMachineNo("");
            setModelType("");
            setMachineIdNo("");
            setReferenceInfo("");
            setCustomerName("");
            setPeriodType("thismonth");
            setDateFrom(today);
            setDateTo(today);
            setOpenJobs(true);
            setClosedJobs(true);
        }
    }, [clearData, today]);

    return (
        <> 
        <Container fluid className="filterGroup">                    
            <Row>                                
                <Col sm={6}>
                    <AsolviInput labelText = {t("TaskLogFilter.ProjectNo")} onChange={(e: any) => { setProjectNo(e.target.value); }} inputValue={ProjectNo}/>                
                    { Props.showMachine && <AsolviInput labelText = {t("TaskLogFilter.MachineNo")} 
                                                        onChange={(e: any) => { setMachineNo(e.target.value); }} inputValue={MachineNo}
                                            />
                    }
                    { Props.showMachine && <AsolviInput labelText = {t("TaskLogFilter.ModelType")} 
                                                        onChange={(e: any) => { setModelType(e.target.value); }} inputValue={ModelType}
                                            />                
                    }
                    { Props.showMachine && Props.showMachineId && <AsolviInput labelText = {t("TaskLogFilter.MachineIdNo")} 
                                                        onChange={(e: any) => { setMachineIdNo(e.target.value); }} inputValue={MachineIdNo}
                                            />            
                    }
                    <AsolviInput labelText = {t("TaskLogFilter.ReferenceInfo")} onChange={(e: any) => { setReferenceInfo(e.target.value); }} inputValue={ReferenceInfo}/>                
                    <AsolviInput labelText = {t("TaskLogFilter.CustomerName")} onChange={(e: any) => { setCustomerName(e.target.value); }} inputValue={CustomerName}/>                
                </Col>    
                <Col sm={2}> 
                    <span>{t("TaskLogFilter.SelectPeriod")}</span>
                    <AsolviRadioButtonGroup 
                        groupId="myfilter"
                        optionsList=
                        {[
                            {id: "nolimit", label: t("TaskLogFilter.NoDateLimit")},
                            {id: "manual", label: t("TaskLogFilter.ManualDateLimit")},
                            {id: "thismonth", label: t("TaskLogFilter.ThisMonthDateLimit")},
                            {id: "last30days", label: t("TaskLogFilter.Last30DaysDateLimit")},
                            {id: "thisyear", label: t("TaskLogFilter.ThisYearDateLimit")},
                            {id: "last365days", label: t("TaskLogFilter.Last365DaysDateLimit")},
                        ]} defaultValue="thismonth"
                        onChange={setPeriodType}
                    />
                </Col>                  
                <Col sm={4}>                   
                    {
                        PeriodType === "manual" && (
                    <>
                        <AsolviDatepicker labelText={t("TaskLogFilter.DateFrom")}
                                         inputValue={DateFrom}
                                         onSelect={(e: any) => { setDateFrom(e.target.value); }} />
                       
                        <AsolviDatepicker labelText={t("TaskLogFilter.DateTo")}
                                        inputValue={DateTo}
                                        onSelect={(e: any) => { setDateTo(e.target.value); }} />                        
                    </>
                        )
                    } 
                    <AsolviNotification
                        message={notifyMessage}
                        showNotify={showNotify}
                        showIcon={true}
                        closeAction={handleCloseNotification}
                        style={notifyStyle}
                        setPosition={{ position: "relative", top: "1%" }}
                    />                         
                </Col>
            </Row>
            <Row className = "JobFilterGroup">
                <Col sm={2}>
                </Col>
                <Col sm={1}>
                    <AsolviCheckbox label = {t("TaskLogFilter.OpenJobs")} onChange={(e: any) => { if(ClosedJobs)setOpenJobs(e.value);else setError(t("TaskLogFilter.ErrorMustSelectAtLeastOneJobStatus"))}} value={OpenJobs}/>                                    
                </Col>       
                <Col sm={1}>
                    <AsolviCheckbox label = {t("TaskLogFilter.ClosedJobs")} onChange={(e: any) => { if(OpenJobs)setClosedJobs(e.value);else setError(t("TaskLogFilter.ErrorMustSelectAtLeastOneJobStatus"))}} value={ClosedJobs}/>                
                </Col>              
                {
                    (Props.showServiceLog && Props.showHelpdeskLog) &&
                    <Col sm={1}>
                    
                        <AsolviCheckbox label = {t("TaskLogFilter.ServiceJobs")} onChange={(e: any) => { if(HelpdeskJobs)setServiceJobs(e.value);else setError(t("TaskLogFilter.ErrorMustSelectAtLeastOneTaskType"))}} value={ServiceJobs}/>                                    
                    </Col>
                }
                { 
                
                    (Props.showHelpdeskLog && Props.showServiceLog) &&                 
                    <Col sm={1}> 
                        <AsolviCheckbox label = {t("TaskLogFilter.HelpdeskJobs")} onChange={(e: any) => { if(ServiceJobs)setHelpdeskJobs(e.value);else setError(t("TaskLogFilter.ErrorMustSelectAtLeastOneTaskType"))}} value={HelpdeskJobs}/>
                    </Col>                                                    
                }
                {
                   (!Props.showServiceLog || !Props.showHelpdeskLog) &&                 
                   <Col sm={2}> 
                   </Col>   
                }

                <Col sm={3}>
                    <AsolviButton buttonDisabled={Props.loadingData} className="k-primary" buttonText={t("TaskLogFilter.Search")} onClick={Props.searchFunction} buttonType={"submit"} ></AsolviButton>
                </Col>             
            </Row>
        </Container>  
        </>                             
        );
}

export default TaskLogFilterGroup;