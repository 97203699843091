import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";
import { ReportDTO } from "../Reports";
import { useSelector } from "react-redux";
import { IAppState } from "../../../../store/Store";
import { ReportAPIs } from "../../../../sources/apiEndPoints";
import axios, { AxiosError } from "axios";
import TextFilter from "./Filters/TextFilter";
import SelectFilter from "./Filters/SelectFilter";
import DateFilter from "./Filters/DateFilter";
import AsolviButton from "../../../Elements/AsolviButton/AsolviButton";
import AsolviGrid, { IGridLayout } from "../../../Elements/AsolviGrid/AsolviGrid";
import { OperatorEnum } from "./Filters/TextFilterOperator";
import { DateOperatorEnum } from "./Filters/DateFilterOperator";
import AsolviInput from "../../../Elements/AsolviInput/AsolviInput";
import AsolviFadeInOut from "../../../Elements/AsolviFadeInOut/AsolviFadeInOut";
import styles from "./Report.module.css";
import { Spinner } from "react-bootstrap";

interface Props {
  report: ReportDTO,
  onBackButtonClicked: () => void,
}

interface FilterDTO {
  Name: string
  Field: string
  Type: string
  Values: FilterValueDescriptionDto[]
}
export interface ITextFilter {
  name: string
  field: string
  type: string
  value: string | undefined | null
  operator: OperatorEnum
}
export interface IDateFilter {
  name: string
  field: string
  type: 'D'
  firstDate: Date | undefined | null
  secondDate: Date | undefined | null
  operator: DateOperatorEnum
}
export interface ISelectFilter {
  name: string
  field: string
  type: string
  value: string | undefined | null
  values: FilterOption[]
  operator: OperatorEnum
}
interface FilterValueDescriptionDto {
  Value: string
  Description: String
}
interface FilterOption {
  value: string
  text: string
}

// TODO make into interface
type searchResultDTO = any;


function isDateFilter(filter : ITextFilter | IDateFilter | ISelectFilter): filter is IDateFilter {
  return filter.type === 'D';
}
function isSelectFilter(filter : ITextFilter | IDateFilter | ISelectFilter): filter is ISelectFilter {
  return ('values' in filter);
}
const Report: FC<Props> = ({report, onBackButtonClicked}) => {
  let { appUrl } = (window as any).appConfig;

  const { t } = useTranslation();

  let { currentAuth0Token, activeCustomer }
    = useSelector((state: IAppState) => state.generalState.General);

  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [filters, setFilters] = useState<((ITextFilter | IDateFilter | ISelectFilter))[]>([]);
  useEffect(() => {
    setIsLoadingFilters(true);
    const url = appUrl + ReportAPIs.GetReportFilters(report.ObjNo, activeCustomer)
    axios.get<FilterDTO[]>(
      url, { headers: { token: currentAuth0Token } }
    ).then(
      res => {
        setIsLoadingFilters(false);
        setFilters(res.data.map( (dto) => {

          if (dto.Type === 'D') {
            return {
              name: dto.Name,
              field: dto.Field,
              type: 'D',
              firstDate: undefined,
              secondDate: undefined,
              operator: DateOperatorEnum.Between,
            } as IDateFilter
          }
          else {
            if (dto.Values.length) {
              return {
                name: dto.Name,
                field: dto.Field,
                type: dto.Type,
                value: dto.Values.length ? undefined : '',
                values: dto.Values.map( (v) => ({
                  value: v.Value,
                  text: v.Value + ' - ' + v.Description,
                })),
                operator: OperatorEnum.Equals,
              } as ISelectFilter
            }
            else {
              return {
                name: dto.Name,
                field: dto.Field,
                type: dto.Type,
                value: '',
                operator: OperatorEnum.StartsWith,
              } as ITextFilter
            }
          }
        }));
      }
    );
  }, [activeCustomer, appUrl, currentAuth0Token, report.ObjNo])


  const [gridLayout, setGridLayout] = useState<IGridLayout[]>();

  const [searchResult, setSearchResult] = useState<searchResultDTO>();
  const [searchRows, setSearchRows] = useState<any[]>([]);
  useEffect(() => {
    if (!searchResult)
      return;

    const newGridLayout: IGridLayout[] = [{
      fieldName: '__index',
      visible: false,
      editable: false,
    }];
    searchResult.Columns.forEach((column: string) => {
      newGridLayout.push({
        fieldName: column,
        visible: true,
        editable: false,
        colWidth: 210,
      });
    })
    setGridLayout(newGridLayout);

    const newSearchRows: any[] = [];
    searchResult.Rows.forEach((row: string, rowIndex: number) => {

      const newSearchRow: any = {__index: rowIndex};
      searchResult.Columns.forEach((column: string, columnIndex: number) => {
        newSearchRow[column] = row[columnIndex]
      })

      newSearchRows.push(newSearchRow)
    })
    setSearchRows(newSearchRows);
  }, [searchResult])

  const [password, setPassword] = useState("");
  const [searchErrors, setSearchErrors] = useState<{
    wrongPassword: boolean,
    generalError: boolean,
  }>({
    wrongPassword: false,
    generalError: false,
  })
  function resetSearchErrors() {
    setSearchErrors({
      wrongPassword: false,
      generalError: false,
    })
  }

  function onSearchError(res: AxiosError) {

    const errorMessage = (res.response?.data as any).DataContract?.Message;

    if (errorMessage && errorMessage === "Password of report does not match") {
      setSearchErrors({...searchErrors, wrongPassword: true})

    } else {
      setSearchErrors({...searchErrors, generalError: true})
    }
  }

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  return (
    <div>
      <div style={{marginTop: "15px", marginLeft: "15px"}}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <AsolviButton
            buttonType="button"
            widthShouldNotBe100Percent
            onClick={onBackButtonClicked}
          >
            {t("Reports.Report.Back")}
          </AsolviButton>
          <div style={{ fontSize: '32px', marginLeft: "20px" }}>
            {report.ReportName}
          </div>
        </div>
        <div style={{ width: '720px' }}>
          <AsolviFadeInOut show={isLoadingFilters} lazyChildren>
            <div className={styles.filterSpinner}>
              <Spinner animation="border" variant="dark" />
            </div>
          </AsolviFadeInOut>
          {filters.map((filter, index) =>
            <div key={filter.field}>
              {isDateFilter(filter)
                ? <DateFilter
                  filter={filter}
                  onFirstDateChange={(e) => {
                    const newFilters = [...filters];
                    newFilters[index] = { ...filter, firstDate: e.target.value };

                    setFilters(newFilters);
                  }}
                  onSecondDateChange={(e) => {
                    const newFilters = [...filters];
                    newFilters[index] = { ...filter, secondDate: e.target.value };

                    setFilters(newFilters);
                  }}
                  onOperatorChange={(e) => {
                    const operatorId = e.target.value.itemId;

                    const newFilters = [...filters];
                    newFilters[index] = { ...filter, operator: operatorId };

                    setFilters(newFilters);
                  }}
                />
                : <div>
                  {isSelectFilter(filter)
                    ? <SelectFilter
                      filter={filter}
                      onChange={(e) => {
                        const newFilters = [...filters];
                        newFilters[index] = { ...filter, value: e.target.value?.value }

                        setFilters(newFilters);
                      }}
                    />
                    : <TextFilter
                      filter={filter}
                      onOperatorChange={(e) => {
                        const newFilters = [...filters];
                        newFilters[index] = { ...newFilters[index], operator: e.target.value.itemId };

                        setFilters(newFilters);
                      }}
                      onInputChange={(e) => {
                        const newFilters = [...filters];
                        newFilters[index] = { ...filter, value: e.value }

                        setFilters(newFilters);
                      }}
                    />
                  }
                </div>
              }
            </div>,
          )
          }
        </div>

        <div style={{ display: 'flex' }}>
          <AsolviButton
            buttonType="button"
            className="k-primary"
            loading={isLoadingSearch}
            widthShouldNotBe100Percent
            onClick={() => {
              setIsLoadingSearch(true);
              const url = appUrl + ReportAPIs.Search(report.ObjNo, activeCustomer);
              axios.post(url, { filters, password }, { headers: { token: currentAuth0Token } })
                .then((res) => {
                  setIsLoadingSearch(false);
                  resetSearchErrors();
                  setSearchResult(res.data);

                }).catch((error: AxiosError) => {
                  setIsLoadingSearch(false);
                  onSearchError(error)
              })
            }}
          >
            {t("Reports.Report.Search")}
          </AsolviButton>
          {report.HasPassword &&
            <div style={{ display: "flex", alignItems: "center", marginLeft: "8px" }}>
              <AsolviInput
                type="password"
                labelText={t("Reports.Report.Password")}
                inputValue={password}
                onChange={(e) => setPassword(e.target.value as string)}
              />
              {searchErrors.wrongPassword
                && <span style={{ color: "red", marginLeft: "16px" }}>{t("Reports.Report.Errors.WrongPassword")}</span>
              }
            </div>
          }
        </div>
      </div>
      {searchErrors.generalError &&
        <div style={{ color: "red", marginLeft: "15px", marginTop: "15px", fontWeight: "600" }}>
          {t("Reports.Report.Errors.SearchError")}
        </div>
      }
      {searchResult &&
        <div>
          <AsolviGrid
            dataKeyField='__index'
            data={searchRows}
            gridLayout={gridLayout}
            gridStyle={{}}
            sortable={true}
            enableExcelExport={true}
            handleChange={() => {}}
          />
        </div>
      }
    </div>
  );
}

export default Report;
