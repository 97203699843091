import React, { FC, useState } from "react";
import ReportsGrid from "./ReportsGrid";
import Report from "./Report/Report";
import { Col, Row } from "react-bootstrap";

export interface ReportDTO {
  ObjNo: string;
  ReportOwner: string;
  ReportType: string;
  ReportName: string;
  ReportDescription: string;
  DocumentType: number;
  Index: number;
  Sql: string;
  GlobalReport: string;
  ReportOpenIn: string;
  ReportNode: string;
  ReportTable: string;
  KeyColumns: number;
  ShowLimitations: string;
  UseMerging: string;
  HasPassword: boolean
}

const Reports: FC = () => {

  const [currentReport, setCurrentReport] = useState<ReportDTO | null>(null);

  return (
    <div style={{width: '100%', marginBottom: '25px'}}>
      <Row>
        <Col>
          {currentReport
            ? <div>
              <Report
                report={currentReport}
                onBackButtonClicked={() => setCurrentReport(null)}
              />
            </div>

            : <ReportsGrid onSelectReport={setCurrentReport}/>
          }
        </Col>
      </Row>
    </div>
  )
}

export default Reports;
