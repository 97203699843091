import React, { FC, useCallback } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IOrderCurrentOrder, IOrderLine, IOrderVisibilitySettings } from "../../../slices/OrderSlice";
import AsolviAdvancedGrid, {
  IGridColumn,
  IGridGroup,
  IGridSort,
} from "../../Elements/AsolviAdvancedGrid/AsolviAdvancedGrid";

import styles from "./OrderSummaryGroup.module.css";
import { GridCellProps } from "@progress/kendo-react-grid";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import AsolviFadeInOut from "../../Elements/AsolviFadeInOut/AsolviFadeInOut";
import OrderSumGroup from "./OrderSumGroup";
import { useInternationalization } from "@progress/kendo-react-intl";
import InvoiceableCell from "./InvoiceableCell";
import OrderHeadingGroupSeedComponent
  from "../../seedComponents/Groups/Order/OrderSummaryGroup/OrderHeadingGroupSeedComponent";

export interface Props {
  currentOrder: IOrderCurrentOrder;
  isLoadingDiscounts: boolean;
  visibilitySettings: IOrderVisibilitySettings;
  removeOrderLine: (lineNo: number) => void;
  addOrderLine: (line: IOrderLine) => void;
  saveOrder: () => void;
  loadingCreateNewOrder: boolean;
}

const OrderSummaryGroup: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { currentOrder, isLoadingDiscounts, visibilitySettings, removeOrderLine, addOrderLine, saveOrder, loadingCreateNewOrder } = props;
  const intlService = useInternationalization();

  const totalQty = currentOrder.Lines.reduce(
    (total, currentValue) => total + (currentValue?.Qty ?? 0), 0
  );

  const removeOrderLineCallback = useCallback((item: IOrderLine) => {
    removeOrderLine(item.LineNo);
  }, [removeOrderLine]);

  const PriceLoadingDataCell: FC<GridCellProps> = (props) => {
    return (
      <td role="gridCell" aria-colindex={props.columnIndex} data-grid-col-index={props.columnIndex} aria-selected="false" className={styles.spinnerCell}>
        <AsolviFadeInOut show={isLoadingDiscounts} lazyChildren>
          <div className={styles.spinnerOverlay}>
            <Spinner animation="border" size="sm" />
          </div>
        </AsolviFadeInOut>
        <span className={isLoadingDiscounts ? styles.spinnerCellLoading : ""}>
          { props.field && intlService.formatNumber(props.dataItem[props.field], "n2") }
        </span>
      </td>
    );
  };

  const handleItemChanged = (item: IOrderLine, field?: Extract<keyof IOrderLine, string>, newValue?: any) => {
    if (!item || !field) return;
    if(field === "Qty" && newValue <0){
        return;
    }

    var newItem = { ...item, [field]: newValue };
    addOrderLine(newItem);
  };

  const gridLayout: IGridColumn<IOrderLine>[] = [{
    fieldName: "LineNo",
    fieldLanguageKey: t("Order.LineNo"),
    visible: false,
    editable: false,
  }, {
    fieldName: "MachineFriendlyName",
    fieldLanguageKey: t("Order.Machine"),
    visible: true,
    editable: false,
    colWidth: 250,
  },
  {
    fieldName: "ArticleNo",
    fieldLanguageKey: t("Order.ArticleNo"),
    visible: true,
    editable: false,
    colWidth: 150,
  }, {
    fieldName: "Description",
    fieldLanguageKey: t("Order.ArticleDescription"),
    colWidth: 250,
    visible: true,
    editable: false,
  }, {
    fieldName: "PriceWithDiscount",
    fieldLanguageKey: t("Order.SalesPrice"),
    visible: visibilitySettings.ShowAddOrderLine?.ShowSalesPrice,
    editable: false,
    cellStyle: PriceLoadingDataCell,
    colWidth: 150,
  },{ 
    fieldName: "Discount",
    fieldLanguageKey: t("Order.Discount"),
    colWidth: 120,
    editable: false,
    visible: visibilitySettings.ShowAddOrderLine?.ShowSalesPrice && visibilitySettings.ShowAddOrderLine?.ShowDiscount
  },
  {
    fieldName: "Invoiceable",
    fieldLanguageKey: t("Order.Invoiceable"),
    visible: true,
    editable: false,
    cellStyle: InvoiceableCell,
    colWidth: 120
  }, {
    fieldName: "Qty",
    fieldLanguageKey: t("Order.Qty"),
    visible: true,
    editable: true,
    editor: "numeric",
    colWidth: 120,
  }, {
    fieldName: "SumPriceWithDiscount",
    fieldLanguageKey: t("Order.Sum"),
    visible: visibilitySettings.ShowAddOrderLine?.ShowSalesPrice,
    editable: false,
    cellStyle: PriceLoadingDataCell,
    colWidth: 150
  }
  ];

  const groups: IGridGroup<IOrderLine>[] = [{
    field: "MachineFriendlyName",
  },];

  const sort: IGridSort<IOrderLine>[] = [{
    field: "LineNo",
    dir: "asc"
  }];

  return (
    <Container fluid>
      <Row className={styles.headingRow}>
        <Col>
          <OrderHeadingGroupSeedComponent />
        </Col>
      </Row>
      <Row>
        <Col>
          <AsolviAdvancedGrid
            dataKeyField="LineNo"
            data={currentOrder.Lines}
            gridLayout={gridLayout}
            groupable
            defaultGroupBy={groups}
            lockedGroups
            sortable
            defaultSort={sort}
            enableDelete
            handleDelete={(item) => removeOrderLineCallback(item)}
            editType="always"
            handleItemChange={handleItemChanged}
          />
        </Col>
      </Row>
      <OrderSumGroup
        lines={currentOrder.Lines}
        visibilitySettings={visibilitySettings}
        style={{ marginLeft: "0" }}
        />
      <Row>
        <Col className={styles.footerRow}>
          <AsolviButton className="k-primary" buttonText={t("Order.Confirm")} buttonType="submit"
                        onClick={() => saveOrder()} buttonDisabled={totalQty <= 0}
                        loading={loadingCreateNewOrder}
          />
        </Col>
      </Row>
    </Container>
  )
};

export default OrderSummaryGroup;
