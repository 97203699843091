import React, { FC, useState } from "react";
import AsolviModal from "../../Auxiliary/AsolviModal/AsolviModal";
import { useTranslation } from "react-i18next";
import { ContactDTO, WebAccessRightsDTO } from "./Contacts";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import { ContactAPIs } from "../../../sources/apiEndPoints";
import axios from "axios";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/Store";
import "./ContactModal.css";
import ContactsProperties, { EditableContact } from "./ContacsProperties";
import AsolviNotification from "../../Elements/AsolviNotification/AsolviNotification";

interface Props {
  showModal: boolean;
  handleClose: () => void;
  contact?: ContactDTO;
  customer: string,
  loggedInRights?: WebAccessRightsDTO;
  onExited: () => void;
  onSaved: () => void;
}

const NewContacts: FC<Props> = ({showModal, loggedInRights, customer, handleClose, onExited, onSaved}) => {
  let { appUrl } = (window as any).appConfig;

  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  let { currentAuth0Token }
    = useSelector((state: IAppState) => state.generalState.General);

  const blankContact = {
    WebAccess:  false,
    WebAccessProject:  false,
    WebAccessOrder:  false,
    WebAccessMeterReporting:  false,
    WebAccessProjectLog:  false,
    WebAccessOrderLog:  false,
    WebAccessSupport: false,
    WebAccessSupportLog:  false,
    WebAccessMachine:  false,
    WebAccessContact:  false,
    WebAccessOnlyOwn:  false,
    FirstName:  "",
    LastName:  "",
    ContactName:  "",
    Phone:  "",
    Mobile:  "",
    Fax:  "",
    Email1:  "",
    Email2:  "",
    JobTitle:  "",
    Title: "",
  }
  const [contact, setContact] = useState<EditableContact>(blankContact);

  const [notificationState, setNotificationState] = useState<{
    message: string,
    showNotify: boolean,
    style: any,
  }>({
    message: '',
    showNotify: false,
    style: 'success'
  });

  return (
    <div>
      {contact &&
        <AsolviModal
          extraClassNames="ContactModal"
          modalSize={"lg"}
          showModal={showModal}
          header={t("Contacts.Contact.New.Title")}
          handleClose={handleClose}
          onExited={onExited}
        >
          <ContactsProperties
            contact={contact}
            customer={customer}
            onChange={(editableContact) => setContact({...contact, ...editableContact})}
            loggedInRights={loggedInRights}
          />
          <div style={{marginBottom: '16px'}}/>
          <AsolviButton
            buttonType={"submit"}
            className="k-primary"
            buttonText={t("Contacts.Contact.Save")}
            loading={isSaving}
            onClick={() => saveContact(contact)}
          />

        </AsolviModal>
      }

      <AsolviNotification
        message={notificationState.message}
        showNotify={notificationState.showNotify}
        showIcon={false}
        closeAction={()=>{setNotificationState({...notificationState, showNotify: false})}}
        style={notificationState.style}
      />
    </div>
  )

  function saveContact(contact: EditableContact) {

    if (!contact.FirstName || !contact.LastName || !contact.ContactName){
      setNotificationState({
        message: t("Contacts.Contact.MissingRequiredFields"),
        showNotify: true,
        style: 'error'
      });
      return;
    }
    setIsSaving(true);
    const url = appUrl + ContactAPIs.CreateContact(customer);

    axios.post(url, contact, { headers: { token: currentAuth0Token } })
      .then(() => {
        setIsSaving(false);
        setContact(blankContact);
        onSaved();

        setNotificationState({
          message: t("Contacts.Contact.New.NotificationSuccess") + ' (' + contact.ContactName + ')',
          showNotify: true,
          style: 'success'
        });
        setTimeout(() => {
          setNotificationState({...notificationState, showNotify: false});
        }, 4000);

      }).catch(() => {

        setIsSaving(false);
        setNotificationState({
          message: t("Contacts.Contact.New.NotificationError"),
          showNotify: true,
          style: 'error'
        });
        setTimeout(() => {
          setNotificationState({...notificationState, showNotify: false});
        }, 4000)
    })
  }
}


export default NewContacts;
