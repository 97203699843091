import * as React from "react";
import "./AsolviModal.css";
import Modal from "react-bootstrap/Modal";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import Spinner from "react-bootstrap/Spinner";
import * as I18Next from "i18next";
import * as ReactI18Next from 'react-i18next';
import { FC } from "react";

export interface Props extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  showModal: boolean;
  header: string | React.ReactNode;
  extraClassNames?: string | string[];
  message?: React.ReactNode;
  footer?: React.ReactNode;
  shouldDisplayFooter?: boolean;
  shouldDisplaySpinner?: boolean;
  handleClose: () => void;
  modalSize?: "lg" | "sm" | "xl" | undefined;
  buttonText?: string;
  id?: string;
  shouldCloseOnBackdrop?: boolean;
  scrollable?: boolean;
  animation?: boolean;
  onShow?: () => void;
  onExited?: () => void;
}

export const ModalContext = React.createContext<any>(null);

//Modals Shouldn't be wrapped in Col and Row
const AsolviModal: FC<Props> = (props) => {
  const { id, message, header, shouldDisplayFooter, shouldDisplaySpinner, showModal, modalSize, buttonText,
    shouldCloseOnBackdrop = false, scrollable = false, extraClassNames = [], handleClose, t, children, animation,
    onShow, onExited } = props;
  const modalRef = React.createRef<any>();

  let footer = undefined;
  if (
    (shouldDisplayFooter === undefined || shouldDisplayFooter)
    && props.footer
  ) {
    footer = props.footer;

  } else if (shouldDisplayFooter && !footer) {
    footer = (
      <Modal.Footer>
        <AsolviButton
          onClick={handleClose}
          buttonType="button"
          buttonText={buttonText ?? t("Standard.closeButton")}
          className="k-primary"
        />
      </Modal.Footer>
    );
  }
  let dialogClassNames = [];
  if (!Array.isArray(extraClassNames)) {
    dialogClassNames = extraClassNames.split(" ");
  } else {
    dialogClassNames = extraClassNames;
  }

  return (
    <Modal
      centered
      id={id ?? "modal_"}
      className="asolviModal"
      show={showModal}
      backdrop={shouldCloseOnBackdrop ? true : "static"}
      scrollable={scrollable}
      keyboard={false}
      onHide={handleClose}
      onShow={onShow}
      size={modalSize}
      animation={animation}
      dialogClassName={dialogClassNames.join(' ')}
      onExited={onExited}
    >
      <Modal.Header closeButton className="asolviModalHeader" >
        <Modal.Title> {header}
          {shouldDisplaySpinner && <Spinner animation="border" variant="light" />}
        </Modal.Title>
      </Modal.Header>
      <div ref={modalRef} style={{ display: 'contents' }}>
        <ModalContext.Provider value={modalRef}>
          <Modal.Body>{message ? message : children}</Modal.Body>
        </ModalContext.Provider>
      </div>
      {footer}
    </Modal>
  );
}

export default ReactI18Next.withTranslation()(AsolviModal);