import * as React from 'react';
import { FC, useCallback } from 'react';
import * as Auth0 from '@auth0/auth0-react';
import NavBarGroup from "../../Groups/NavBarGroup/NavBarGroup";
import * as Store from "../../../store/Store";
import * as GeneralActions from "../../../actions/GeneralActions";
import * as FrameActions from "../../../actions/FrameActions";
import { useDispatch, useSelector } from 'react-redux';
import AsolviModal from "../../Auxiliary/AsolviModal/AsolviModal";
import { useTranslation } from 'react-i18next';
import { removeTrailingSlash } from '../../../utils/trailingSlash';


const NavBarGroupSeedComponent: FC = () => {

  const general = useSelector((store: Store.IAppState) => store.generalState.General);

  const dispatch = useDispatch();
  const clearStore = useCallback(() => dispatch({ type: "LOGOUT" }), [dispatch]);
  const resetPassword = useCallback((email: string, issuer: string, clientId: string) => dispatch(GeneralActions.resetPassword(email, issuer, clientId)), [dispatch]);
  const SetLogoutUrl = useCallback((appUrl: string) => dispatch(FrameActions.SetLogoutUrl(appUrl)), [dispatch]);
  const handlePasswordReset = useCallback((currentPasswordResetState:boolean) => dispatch(GeneralActions.handlePasswordResetModal(currentPasswordResetState)), [dispatch]);


  const { t } = useTranslation();
  const logOutfromAuth0 = Auth0.useAuth0().logout;
  const email = general.currentUserEmail;
  const { appUrl, issuer, clientId } = (window as any)["appConfig"];

  const logOutbuttonFunction = () => {
    SetLogoutUrl(appUrl);
    let returnUrl = removeTrailingSlash(window.location.origin + window.location.pathname);
    logOutfromAuth0({ returnTo: returnUrl });
    clearStore();
  }
  const handlePasswordResetInner = () => {
    resetPassword(email, issuer, clientId);
    handlePasswordReset(true);
  }
  const handleClose = () => { 
    handlePasswordReset(false);
  };

  const activeCustomerInfo
    = general.activeCustomerInfo
    ? JSON.parse(general.activeCustomerInfo)
    : "";

  return (
    <>
      <NavBarGroup
        passwordFunction={handlePasswordResetInner}
        logOutFunction={logOutbuttonFunction}
        customerInfo={activeCustomerInfo.Name + " (" + activeCustomerInfo.CustomerNo + ")"}
        customerInfoOrgNo={activeCustomerInfo.OrgNo}
      />
      <AsolviModal header={t("Standard.password")} showModal={general.showPasswordReset} handleClose={handleClose}
                   message={t("Standard.passwordReset")} shouldDisplayFooter={true} shouldDisplaySpinner= {false}
      />
    </>
  )
}

export default NavBarGroupSeedComponent;
