import * as React from 'react';
import { Grid, GridCellProps, GridColumn as Column, GridFilterChangeEvent, GridSortChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import "./AsolviGrid.css";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import AsolviButton from '../AsolviButton/AsolviButton';
import { Col, Container, Row } from 'react-bootstrap';
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import { CSSProperties } from "react";

export interface IGridLayout{
  fieldName: string,
  fieldLanguageKey?: string,
  colWidth?: number,
  editable: boolean,
  visible: boolean,
  editor?: "boolean" | "text" | "numeric" | "date" | undefined,
  format?: string | undefined,
  filterType?: "date" | "numeric" | "text" | "boolean" | undefined,
  cellStyle?: any,
  initialSort?: "asc" | "desc" | undefined
}

export interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  data: any[],
  dataKeyField: string,
  gridLayout?: IGridLayout[] | undefined,
  handleChange: (e:any) => void,
  sortable?: boolean,
  filterable?: boolean,
  enableExcelExport?: boolean,
  handleRowClick?: (e:any) => void,
  handleItemChange?: (e:any) => void,
  enableAddNew?: boolean,
  handleAddNew?: (e:any) => void,
  enableDelete?: boolean,
  handleDelete?: (e:any) => void,
  gridStyle?: CSSProperties,
}

const AsolviGrid: React.FunctionComponent<IProps> = (props) => {
  const [filterState, setFilterState] = React.useState<CompositeFilterDescriptor>({ logic: "and", filters: [] });
  const _export = React.useRef<ExcelExport | null>(null);
  const { t, gridStyle } = props

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const addNew = () => {
    if (props.handleAddNew) {
      props.handleAddNew(undefined);
    }
  };

  const remove = (dataItem: any) => {
    if (props.handleDelete) {
      props.handleDelete(dataItem);
    }
  };

  const commandCell = (cell: GridCellProps) => {
    return ( <td className="k-command-cell">
        <AsolviButton buttonText={""} buttonType={"button"} buttonIconName={"delete"} onClick={() => remove(cell.dataItem)} />
      </td>
    );
  };

  const visibleColumns = props.gridLayout?.filter(column => column.visible)
  const columnElements = visibleColumns?.map((column, key) => (
    <ExcelExportColumn
      field={column.fieldName}
      title={column.fieldLanguageKey || column.fieldName}
      key={key}
    />
  ));

  const initialSort: Array<SortDescriptor> = [{
    field: props.gridLayout
      ? props.gridLayout[0].fieldName
      : "" ,
    dir: props.gridLayout
      ? (props.gridLayout[0].initialSort
        ? props.gridLayout[0].initialSort
        : "desc")
      : undefined
  }];

  const [sort, setSort] = React.useState(initialSort);

  return (
    <Container fluid className="AsolviGrid">
      <Row>
        <ExcelExport
          data={filterState
            ? orderBy(filterBy(props.data, filterState), sort)
            : orderBy(props.data, sort)} ref={_export}
        >
          {columnElements}
        </ExcelExport>
        <Col sm={12}>
          <Grid
            style={gridStyle}
            sortable={props.sortable ?? false}
            filterable={props.filterable ?? false}
            onFilterChange={(event: GridFilterChangeEvent) => setFilterState(event.filter)}
            filter={filterState}
            data={filterState ? orderBy(filterBy(props.data, filterState), sort): orderBy(props.data, sort)}
            dataItemKey={props.dataKeyField}
            onDataStateChange={props.handleChange}
            editField="inEdit"
            onRowClick={props.handleRowClick}
            onItemChange={props.handleItemChange}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
            }}
          >
            {(props.enableExcelExport || props.enableAddNew) &&
              <GridToolbar>
                <Row>
                  {props.enableAddNew && (<Col sm={4}>
                    <AsolviButton
                      buttonText={t("AsolviGrid.AddNewButton")}
                      buttonIconName={"plus"}
                      onClick={addNew}
                      buttonType={"button"}
                    />
                  </Col>)
                  }
                  {props.enableExcelExport && (<Col sm={2}>
                    <AsolviButton
                      buttonText={t("AsolviGrid.ExportExcelButton")}
                      className="k-button k-primary"
                      onClick={excelExport}
                      buttonType={"button"}
                    />
                  </Col>)
                  }
                </Row>

              </GridToolbar>}

            {
              props.gridLayout && (props.gridLayout.map((layout) => layout.visible && (
                <Column
                  field={layout.fieldName}
                  title={layout.fieldLanguageKey || layout.fieldName}
                  editable={layout.editable}
                  width={layout.colWidth + "px" ?? "auto"}
                  editor={layout.editor ?? undefined}
                  format={layout.format ?? undefined}
                  key={props.dataKeyField}
                  filter={layout.filterType}
                  cell={layout.cellStyle}
                />
              )))
            }
            { props.enableDelete && (
              <Column cell={commandCell} width="75px" />
            )
            }
          </Grid>
        </Col>
      </Row>
    </Container>
  );
}

export default ReactI18Next.withTranslation()(AsolviGrid);