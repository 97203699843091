import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import * as ApiEndPoints from '../sources/apiEndPoints';
import { IAppState } from "../store/Store";
const prefix = 'documents';

export interface IDocumentsState {
    selectedMachineGuid?: string;
    isLoadingDocuments: boolean;
    documents: IDocument[];
    errorMsg?: string;
}

const initialState: IDocumentsState = {
    selectedMachineGuid: undefined,
    isLoadingDocuments: false,
    documents: [],
    errorMsg: undefined,
};

export interface IDocument {
    DocumentGuid: string;
    ProjectNo: string;
    MachineNo: string;
    Title: string;
    Size: number;
    DateCreated: string;
}


export const setSelectedMachineGuidAction = createAsyncThunk(`${prefix}/setSelectedMachineGuid`, async (selectedMachineGuid: string | undefined, thunkApi) => {
    thunkApi.dispatch(setSelectedMachineGuidCoreAction(selectedMachineGuid));
    if(!selectedMachineGuid)
        return [];

    let { appUrl } = (window as any).appConfig;
    if(!appUrl)
        return thunkApi.rejectWithValue(new Error("No appUrl set"));

    let token = (thunkApi.getState() as IAppState).generalState.General.currentAuth0Token;
    if(!token)
        return thunkApi.rejectWithValue(new Error("Not logged in"));

    let response = await Axios.get<IDocument[]>(appUrl + ApiEndPoints.DocumentAPIs.GetDocumentHeaders(selectedMachineGuid), {
        headers: {
            token
        }
    });
    return response.data;
});
const setSelectedMachineGuidCoreAction = createAction<string | undefined>(`${prefix}/setSelectedMachineGuidCore`);
export const setIsLoadingDocumentsAction = createAction<boolean>(`${prefix}/setIsLoadingDocuments`);
export const clearDocumentsAction = createAction(`${prefix}/clearDocuments`);

const documentsSlice = createSlice({
    name: prefix,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setIsLoadingDocumentsAction, (state, action) => {
            state.isLoadingDocuments = action.payload;
        });

        builder.addCase(clearDocumentsAction, (state) => {
            state.documents = [];
        });

        builder.addCase(setSelectedMachineGuidCoreAction, (state, action) => {
            state.selectedMachineGuid = action.payload;
        });
        builder.addCase(setSelectedMachineGuidAction.pending, (state) => {
            state.isLoadingDocuments = true;
            state.errorMsg = undefined;
        });
        builder.addCase(setSelectedMachineGuidAction.fulfilled, (state, action) => {
            state.isLoadingDocuments = false;
            state.documents = action.payload;
            state.errorMsg = undefined;
        });
        builder.addCase(setSelectedMachineGuidAction.rejected, (state, action) => {
            state.isLoadingDocuments = false;
            state.documents = [];
            state.errorMsg = action.error.message;
        });
    }
});

export default documentsSlice.reducer;
