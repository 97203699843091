import { useState, useEffect } from "react";

export const useUserMedia = (constraints: MediaStreamConstraints) => {
  const [stream, setStream] = useState<MediaStream>();
  const [error, setError] = useState(null);
  useEffect(() => {

    //if (stream) return;
    let didCancel = false;

    const getUserMedia = async () => {
      if (!didCancel && !stream) {
        try {
          setStream(await navigator.mediaDevices.getUserMedia(constraints));
        } catch (e) {
          setError(e);
        }
      }
    };
    const cancel = () => {
      didCancel = true;

      if (!stream) return;

      stream.getVideoTracks().forEach(track => track.stop());

      stream.getAudioTracks().forEach(track => track.stop());
    };

    getUserMedia();

    return cancel;
  }, [constraints, stream]);

  return { stream, error };
};
