import React, { FC } from "react";
import { ITextFilter } from "../Report";
import { InputChangeEvent } from "@progress/kendo-react-inputs/dist/npm/input/interfaces/InputChangeEvent";
import TextFilterOperator, { OperatorEnum } from "./TextFilterOperator";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";

interface Props {
  filter: ITextFilter
  onOperatorChange: (event: DropDownListChangeEvent) => void
  onInputChange?: (event: InputChangeEvent) => void
}

const TextFilter: FC<Props> = ({filter, onInputChange, onOperatorChange}) => {

  return (
    <div style={{display: 'flex'}}>
      <div style={{display: 'flex'}}>
        <label style={{ width: '180px' }}>
          {filter.name}
        </label>
        <TextFilterOperator
          operatorId={filter.operator}
          onChange={onOperatorChange}
        />
      </div>
      <Input
        style={{marginLeft: '8px'}}
        disabled={filter.operator === OperatorEnum.NoValue}
        value={filter.value || undefined}
        onChange={onInputChange}
      />
    </div>
  )
}

export default TextFilter;
