import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { WebAccessRightsDTO } from "./Contacts";
import AsolviInput from "../../Elements/AsolviInput/AsolviInput";
import AsolviComboBox from "../../Elements/AsolviComboBox/AsolviComboBox";
import AsolviCheckbox from "../../Elements/AsolviCheckbox/AsolviCheckbox";
import "./ContactModal.css";
import { ContactAPIs } from "../../../sources/apiEndPoints";
import axios from "axios";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/Store";

export interface EditableContact {
  WebAccess: boolean;
  WebAccessProject: boolean;
  WebAccessOrder: boolean;
  WebAccessMeterReporting: boolean;
  WebAccessProjectLog: boolean;
  WebAccessOrderLog: boolean;
  WebAccessSupport: boolean
  WebAccessSupportLog: boolean;
  WebAccessMachine: boolean;
  WebAccessContact: boolean;
  WebAccessOnlyOwn: boolean;
  FirstName: string;
  LastName: string;
  ContactName: string;
  Phone: string;
  Mobile: string;
  Fax: string;
  Email1: string;
  Email2: string;
  JobTitle: string;
  Title: string;
}

interface Props {
  contact: EditableContact;
  loggedInRights?: WebAccessRightsDTO;
  customer: string,
  enableDeleteButton?: boolean,
  onChange: (contact: EditableContact) => void;
}

const ContactsProperties: FC<Props> = ({contact, loggedInRights, customer, onChange}) => {
  let { appUrl } = (window as any).appConfig;

  const { t } = useTranslation();

  let { currentAuth0Token }
    = useSelector((state: IAppState) => state.generalState.General);

  const [jobTitles, setJobTitles] = useState<any[]>([]);
  useEffect(() => {
    const url = appUrl + ContactAPIs.GetJobTitles(customer)
    axios.get<any[]>(
      url, { headers: { token: currentAuth0Token } }
    ).then(
      res => {
        setJobTitles(res.data);
      }
    );
  }, [appUrl, currentAuth0Token, customer])

  return (
    <div style={{display: 'flex'}}>
      <div style={{width: '410px'}}>
        <AsolviInput
          labelText={t("Contacts.Properties.FirstName") + " *"}
          inputValue={contact.FirstName}
          onChange={(e) => onChange({ ...contact, FirstName: e.value })}
          onBlur={() => {
            onChange({ ...contact, ContactName: generateFullName(contact.FirstName, contact.LastName) })
          }}
        />
        <AsolviInput
          labelText={t("Contacts.Properties.LastName") + " *"}
          inputValue={contact.LastName}
          onChange={(e) => onChange({ ...contact, LastName: e.value })}
          onBlur={() => {
            onChange({ ...contact, ContactName: generateFullName(contact.FirstName, contact.LastName) })
          }}
        />
        <AsolviInput labelText={t("Contacts.Properties.ContactName") + " *"} inputValue={contact.ContactName}
                     onChange={(e) => onChange({ ...contact, ContactName: e.value })} />
        <AsolviInput labelText={t("Contacts.Properties.Email1")} inputValue={contact.Email1}
                     onChange={(e) => onChange({ ...contact, Email1: e.value })} />
        <AsolviInput labelText={t("Contacts.Properties.Email2")} inputValue={contact.Email2}
                     onChange={(e) => onChange({ ...contact, Email2: e.value })} />
        <AsolviComboBox
          label={t("Contacts.Properties.JobTitle")}
          value={contact.JobTitle}
          onChange={(e) => {
            onChange({
            ...contact, JobTitle: e.target.value?.value
          })}}
          data={jobTitles.map(j => ({text: j.Description, value: j.Description}))}
        />
        <AsolviInput labelText={t("Contacts.Properties.Phone")} inputValue={contact.Phone}
                     onChange={(e) => onChange({ ...contact, Phone: e.value })} />
        <AsolviInput labelText={t("Contacts.Properties.Mobile")} inputValue={contact.Mobile}
                     onChange={(e) => onChange({ ...contact, Mobile: e.value })} />
        <AsolviInput labelText={t("Contacts.Properties.Fax")} inputValue={contact.Fax}
                     onChange={(e) => onChange({ ...contact, Fax: e.value })} />
        <AsolviInput labelText={t("Contacts.Properties.Title")} inputValue={contact.Title}
                     onChange={(e) => onChange({ ...contact, Title: e.value })} />
      </div>
      <div
        style={{
          marginLeft: '22px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '320px'
        }}
      >
        <AsolviCheckbox
          value={contact.WebAccess} label={t("Contacts.Properties.WebAccess")}
          onChange={(e) => onChange({ ...contact, WebAccess: e.value })}
          disabled={!loggedInRights?.WebAccess || !contact.Email1}
        />
        <AsolviCheckbox
          value={contact.WebAccessSupport} label={t("Contacts.Properties.Access.Helpdesk")}
          onChange={(e) => onChange({ ...contact, WebAccessSupport: e.value })}
          disabled={!loggedInRights?.WebAccessSupport || !loggedInRights?.WebAccess || !contact.WebAccess || !contact.Email1}
        />
        <AsolviCheckbox
          value={contact.WebAccessSupportLog} label={t("Contacts.Properties.Access.HelpdeskLog")}
          onChange={(e) => onChange({ ...contact, WebAccessSupportLog: e.value })}
          disabled={!loggedInRights?.WebAccessSupportLog || !loggedInRights?.WebAccess || !contact.WebAccess || !contact.Email1}
        />
        <AsolviCheckbox
          value={contact.WebAccessProject} label={t("Contacts.Properties.Access.Project")}
          onChange={(e) => onChange({ ...contact, WebAccessProject: e.value })}
          disabled={!loggedInRights?.WebAccessProject || !loggedInRights?.WebAccess || !contact.WebAccess || !contact.Email1}
        />
        <AsolviCheckbox
          value={contact.WebAccessProjectLog} label={t("Contacts.Properties.Access.ProjectLog")}
          onChange={(e) => onChange({ ...contact, WebAccessProjectLog: e.value })}
          disabled={!loggedInRights?.WebAccessProjectLog || !loggedInRights?.WebAccess || !contact.WebAccess || !contact.Email1}
        />
        <AsolviCheckbox
          value={contact.WebAccessOrder} label={t("Contacts.Properties.Access.Order")}
          onChange={(e) => onChange({ ...contact, WebAccessOrder: e.value })}
          disabled={!loggedInRights?.WebAccessOrder || !loggedInRights?.WebAccess || !contact.WebAccess || !contact.Email1}
        />
        <AsolviCheckbox
          value={contact.WebAccessOrderLog} label={t("Contacts.Properties.Access.OrderLog")}
          onChange={(e) => onChange({ ...contact, WebAccessOrderLog: e.value })}
          disabled={!loggedInRights?.WebAccessOrderLog || !loggedInRights?.WebAccess || !contact.WebAccess || !contact.Email1}
        />
        <AsolviCheckbox
          value={contact.WebAccessMeterReporting} label={t("Contacts.Properties.Access.MeterReporting")}
          onChange={(e) => onChange({ ...contact, WebAccessMeterReporting: e.value })}
          disabled={!loggedInRights?.WebAccessMeterReporting || !loggedInRights?.WebAccess || !contact.WebAccess || !contact.Email1}
        />
        <AsolviCheckbox
          value={contact.WebAccessMachine} label={t("Contacts.Properties.Access.Machine")}
          onChange={(e) => onChange({ ...contact, WebAccessMachine: e.value })}
          disabled={!loggedInRights?.WebAccessMachine || !loggedInRights?.WebAccess || !contact.WebAccess || !contact.Email1}
        />
        <AsolviCheckbox
          value={contact.WebAccessContact} label={t("Contacts.Properties.Access.Contact")}
          onChange={(e) => onChange({ ...contact, WebAccessContact: e.value })}
          disabled={!loggedInRights?.WebAccessContact || !loggedInRights?.WebAccess || !contact.WebAccess || !contact.Email1}
        />
      </div>
    </div>
  )

  function generateFullName(firstName: string, lastName: string): string {
    if (firstName && lastName)
      return firstName + ' ' + lastName;

    else return '';
  }
}


export default ContactsProperties;


