import { GridCellProps } from "@progress/kendo-react-grid";
import { useInternationalization } from "@progress/kendo-react-intl";
import React, { FC, useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AsolviAdvancedGrid, { IGridColumn, IGridGroup } from "../../Elements/AsolviAdvancedGrid/AsolviAdvancedGrid";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import AsolviDatepicker from "../../Elements/AsolviDatepicker/AsolviDatepicker";
import { IMachineInfo, IMeterInfo } from "../../seedComponents/Pages/MeterReadingPageSeedComponent";

declare interface Props {
  machines: IMachineInfo[];
  meterReadingDateReadOnly: boolean;
  showMachineId: boolean;
  onAccept: (readings: IMachineInfo[]) => void;
}

declare type IMeterInfoWithMachineName = IMeterInfo & { id: string, machineName: string, machineGuid: string };

const OrderMeterReadingGroup: FC<Props> = ({ machines, meterReadingDateReadOnly, showMachineId, onAccept }) => {
  const { t } = useTranslation();
  const intl = useInternationalization();

  const [localMachines, setLocalMachines] = useState<IMachineInfo[]>(machines);
  const [readingsValid, setReadingsValid] = useState<boolean>(false);

  useEffect(() => {
    setLocalMachines(machines);
  }, [machines, setLocalMachines]);

  useEffect(() => {
    function isValid(machine: IMachineInfo) {
      for(let meter of machine.meters) {
        let oldCounter = meter.oldCounter ?? Number.MIN_SAFE_INTEGER;
        let oldReadingDate = meter.oldReadingDate ?? new Date(0);

        if(!meter.newReadingDate || meter.newReadingDate < oldReadingDate)
          return false;
        if(!meter.newCounter || meter.newCounter < oldCounter)
          return false;
      }

      return true;
    }
    let areCountersBigger = localMachines.every(x => isValid(x));
    setReadingsValid(areCountersBigger);
  }, [localMachines, setReadingsValid])

  const onItemChange = useCallback((item: IMeterInfoWithMachineName, field?: Extract<keyof IMeterInfoWithMachineName, string>, newValue?: any) => {
    if(!field) return;

    let newMachines = localMachines.map(x => {
      if(x.machineGuid !== item.machineGuid)
        return x;

      let newMeters: IMeterInfo[] = x.meters.map(y => {
        if(y.meterGuid !== item.meterGuid)
          return y;

        return {...y, [field]: newValue} as IMeterInfo;
      });

      return {...x, meters: newMeters};
    });
    setLocalMachines(newMachines);
  }, [localMachines, setLocalMachines]);

  const dateCell = (props: GridCellProps) => {
    if(!props.field) return undefined;

    if(meterReadingDateReadOnly ||!props.dataItem.inEdit) {
      return (
        <td>
          {intl.formatDate(props.dataItem[props.field], "d")}
        </td>
      );
    }

    return (
      <td>
        <AsolviDatepicker
          noLabel
          inputValue={props.dataItem[props.field] as Date}
        />
      </td>
    )
  };

  const columns: IGridColumn<IMeterInfoWithMachineName>[] = [
    { fieldName: "id", fieldLanguageKey: "id", editable: false, colWidth: 400, visible: false },
    { fieldName: "machineName", fieldLanguageKey: t("Standard.machine"), editable: false, colWidth: 400, visible: false },
    { fieldName: "meterGuid", fieldLanguageKey: "meterGuid", editable: false, colWidth: 0, visible: false },
    { fieldName: "meterType", fieldLanguageKey: t("MeterReporting.meterType"), editable: false, colWidth: 120, visible: true },
    { fieldName: "meterDescription", fieldLanguageKey: t("MeterReporting.description"), editable: false, visible: true },
    { fieldName: "oldReadingDateFormatted", fieldLanguageKey: t("MeterReporting.oldReadingDate"), editable: false, visible: true },
    { fieldName: "oldCounter", fieldLanguageKey: t("MeterReporting.oldCounter"), editable: false, format: "{0:n}", visible: true },
    { fieldName: "newReadingDateFormatted", fieldLanguageKey: t("MeterReporting.newReadingDate"), editable: !meterReadingDateReadOnly, cellStyle: dateCell, visible: true },
    { fieldName: "newCounter", fieldLanguageKey: t("MeterReporting.newCounter"), editor: "numeric", editable: true, format: "{0:n}", visible: true },
  ];

  const group: IGridGroup<IMeterInfoWithMachineName>[] = [{
    field: "machineName"
  }]

  let meters = localMachines.reduce(
    (result, machine) => [
      ...result,
      ...machine.meters.map(
        x => ({
          ...x,
          id: `${machine.machineGuid}-${x.meterGuid}`,
          machineGuid: machine.machineGuid,
          machineName: `${showMachineId
            ? machine.idNo
              ? machine.idNo
              : machine.machineNo
            : machine.machineNo} - ${machine.modelType}`
        })
      )],

    [] as IMeterInfoWithMachineName[]
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <AsolviAdvancedGrid
            dataKeyField="id"
            data={meters}
            gridLayout={columns}
            handleItemChange={onItemChange}
            editField="inEdit"
            editType="always"
            groupable
            defaultGroupBy={group}
            lockedGroups
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "2rem" }}>
        <Col sm={8}>

        </Col>
        <Col sm={4}>
          <AsolviButton className="k-primary" buttonText={t("MeterReporting.acceptButtonText")} buttonType="submit"
                        onClick={() => onAccept(localMachines)} buttonDisabled={!readingsValid}
          />
        </Col>
      </Row>
    </Container>
  )
};

export default OrderMeterReadingGroup