import { DataConnection, MediaConnection, Peer, PeerConnectOption } from 'peerjs';
import EventEmitter from 'eventemitter3';
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';

declare type CallClientMap = {
  [id: string]: Promise<CallClient> | undefined;
}
let callClients: CallClientMap = {
};
// let callClient: Promise<CallClient> | null = null;

const fatalPeerErrors = new Set<string>([
  'browser-incompatible',
  'invalid-id',
  'invalid-key',
  'ssl-unavailable',
  'server-error',
  'socket-error',
  'socket-closed',
]);

export interface CallClientOptions {
  autoAnswerCall?: boolean;
  userMediaConfig?: MediaStreamConstraints;
}

const defaultOptions: CallClientOptions = {
  autoAnswerCall: false,
  userMediaConfig: {
    audio: true,
    video: false,
  },
};

export class CallClient extends EventEmitter<CallClientEvents> {
  id: string;
  options: CallClientOptions;
  peer: Peer | undefined = undefined;
  currentCall: MediaConnection | undefined = undefined;
  currentDataConnection: DataConnection | undefined = undefined;
  otherPeerId: string | undefined = undefined;

  constructor(id: string, options?: CallClientOptions) {
    super();
    this.id = id;
    this.options = Object.assign({}, defaultOptions, options);
    const { autoAnswerCall } = this.options;
    this.peer = new Peer({
      debug: 3,
      logFunction(logLevel, ...rest) {
        console.log(logLevel, rest);
      },
    });
    this.peer.on('open', () => {
      console.log('open');
      this.emit('peerReady', this.peer!, this);
    });

    this.peer.on('error', (err: any) => {
      console.error(`${err.type}: ${err}`);
      if (fatalPeerErrors.has(err.type)) {
        this.endPeer();
      }
    });

    this.peer.on('connection', (dataConnection: DataConnection) => {
      if (this.currentDataConnection !== dataConnection && this.currentDataConnection?.open)
        this.currentDataConnection?.close?.();
      this.currentDataConnection = dataConnection;
      this._setupDataEvents(dataConnection);
    });

    this.peer.on('call', (call: MediaConnection) => {
      if (!autoAnswerCall) {
        this._notifyCall(call);
      } else {
        this._autoAnswerCallCore(call);
      }
    });
  }

  _notifyCall(call: MediaConnection) {
    if (this.listenerCount('callIncoming') > 0) {
      call.on('stream', (remoteStream: MediaStream) => {
        console.log('received remote stream');
        console.dir(remoteStream);
        this.emit('callStreamReceived', remoteStream, call, this);
      });

      call.on('close', () => {
        console.log('closing call');
        this.emit('callEnded', call, this);
      });

      call.on('error', (error: Error) => {
        console.log('call errored');
        console.error(error);
        this.emit('callEnded', call, this);
        this.endCall();
      });
      this.emit('callIncoming', call, this);
    } else {
      call.close();
    }
  }

  _autoAnswerCallCore(call: MediaConnection) {
    if (this.currentCall?.open) {
      this.currentCall?.close?.();
    }
    console.log('on call');
    this.currentCall = call;
    navigator.mediaDevices.getUserMedia(this.options.userMediaConfig)
      .then(stream => {
        this.emit('callStarted', call, this);
        console.log('answering call with stream');
        call.answer(stream);

        this._setupCallEvents(call);
      }).catch(error => console.error(error));
  }

  connect(peerId: string, options?: PeerConnectOption) {
    if (this.peer) {
      let dataConnection = this.peer.connect(peerId, options);
      if (this.currentDataConnection !== dataConnection && this.currentDataConnection?.open)
        this.currentDataConnection?.close?.();
      this.currentDataConnection = dataConnection;
      this._setupDataEvents(dataConnection);
    }
  }

  disconnectData() {
    if (this.currentDataConnection) {
      this.currentDataConnection.close();
      this.emit('dataEnded', this.currentDataConnection, this);
    }
  }

  startCall(peerId: string, stream: MediaStream, options?: PeerConnectOption) {
    if (this.peer) {
      let mediaConnection = this.peer.call(peerId, stream, options);
      this.currentCall = mediaConnection;
      this.emit('callStarted', mediaConnection, this);
    } else {

    }
  }

  async endCall() {

    if (this.currentDataConnection?.open) {
      this.currentDataConnection.send(JSON.stringify({ type: 'info', data: 'endCall' }));
      await delay(500);
    }

    let tracks = this.currentCall?.localStream?.getTracks?.() ?? [];
    for (let track of tracks) {
      track.stop();
    }
    this.currentCall?.close?.();
    this.currentCall = undefined;

    this.currentDataConnection?.close?.();
    this.currentDataConnection = undefined;
  }

  async endPeer() {
    await this.endCall();
    if (this.peer) {
      this.peer.destroy();
      this.emit('peerDestroyed', this.peer, this);
    }
    callClients[this.id] = undefined;
  }

  _setupCallEvents(call: MediaConnection) {
    call.on('stream', (remoteStream: MediaStream) => {
      console.log('received remote stream');
      console.dir(remoteStream);
      this.emit('callStreamReceived', remoteStream, call, this);
    });

    call.on('close', () => {
      console.log('closing call');
      this.emit('callEnded', call, this);
    });

    call.on('error', (error: Error) => {
      console.log('call errored');
      console.error(error);
      this.emit('callEnded', call, this);
      this.endCall();
    });
  }

  _setupDataEvents(dataConnection: DataConnection) {
    dataConnection.on('open', () => {
      this.emit('dataStarted', dataConnection, this);
    });
    dataConnection.on('close', () => {
      this.emit('dataEnded', this.currentDataConnection!, this);
      this.currentDataConnection = undefined;
    });
    dataConnection.on('data', data => {
      this.emit('dataReceived', data, this);
    });
    dataConnection.on('error', error => {
      this.emit('dataError', error, this);
    });
  }
}

export async function getCallClient(id: string, options?: CallClientOptions): Promise<CallClient> {
  if (callClients[id] !== undefined) {
    let client = await callClients[id]!;
    if (options)
      client.options = options;
    return client;
  }

  console.log('creating new call client');
  let callClientPromise = Promise.resolve(new CallClient(id, options));
  callClients[id] = callClientPromise;
  return callClientPromise;
}

export function getExistingCallClient(id: string): Promise<CallClient> | undefined {
  return callClients[id];
}

export type CallClientEvents = {
  'peerReady': (peer: Peer, client: CallClient) => void,
  'peerDestroyed': (peer: Peer, client: CallClient) => void,
  'callIncoming': (call: MediaConnection, client: CallClient) => void,
  'callStarted': (call: MediaConnection, client: CallClient) => void,
  'callStreamReceived': (stream: MediaStream, call: MediaConnection, client: CallClient) => void,
  'callEnded': (call: MediaConnection, client: CallClient) => void,
  'dataStarted': (dataConnection: DataConnection, client: CallClient) => void,
  'dataReceived': (data: unknown, client: CallClient) => void,
  'dataError': (error: Error, client: CallClient) => void,
  'dataEnded': (dataConnection: DataConnection, client: CallClient) => void,
};

export interface UseCallClientArgs {
  clientOptions?: CallClientOptions;
  debug?: boolean | string;

  peerId?: string;
  setPeerId?: Dispatch<SetStateAction<string>>;
  callActive?: boolean;
  setCallActive?: Dispatch<SetStateAction<boolean>>;
  dataConnection?: DataConnection;
  setDataConnection?: Dispatch<SetStateAction<DataConnection | undefined>>;
  call?: MediaConnection;
  setCall?: Dispatch<SetStateAction<MediaConnection | undefined>>;

  onPeerReady?: CallClientEvents['peerReady'];
  onPeerDestroyed?: CallClientEvents['peerDestroyed'];
  onCallIncoming?: CallClientEvents['callIncoming'];
  onCallStarted?: CallClientEvents['callStarted'];
  onCallStreamReceived?: CallClientEvents['callStreamReceived'];
  onCallEnded?: CallClientEvents['callEnded'];
  onDataStarted?: CallClientEvents['dataStarted'];
  onDataReceived?: CallClientEvents['dataReceived'];
  onDataError?: CallClientEvents['dataError'];
  onDataEnded?: CallClientEvents['dataEnded'];
}

export function useCallClient(id: string, {
  clientOptions,
  debug,

  setPeerId,
  setCallActive,
  setDataConnection,
  setCall,

  onPeerReady,
  onPeerDestroyed,
  onCallIncoming,
  onCallStarted,
  onCallStreamReceived,
  onCallEnded,
  onDataStarted,
  onDataReceived,
  onDataError,
  onDataEnded,
}: UseCallClientArgs): void {
  const onDataConnectionStartedState = useCallback((dataConnection: DataConnection) => {
    setDataConnection?.(dataConnection);
  }, [setDataConnection]);
  const onDataConnectionEndedState = useCallback((_: DataConnection) => {
    setDataConnection?.(undefined);
  }, [setDataConnection]);
  const onBeforeUnload = useCallback(() => {
    getExistingCallClient(id)?.then?.(client => {
      client?.endPeer();
    });
    window.removeEventListener('beforeunload', onBeforeUnload);
  }, [id]);

  useEffect(() => {
    let debugTag = `${id}: `;
    if (typeof debug === 'string')
      debugTag = `${debug}: `;
    if (debug)
      console.log(`${debugTag}client effect run`);

    window.addEventListener('beforeunload', onBeforeUnload);
    getCallClient(id, clientOptions).then(client => {
      if (client.peer?.id)
        setPeerId?.(client.peer.id);
      setCallActive?.(Boolean(client.currentCall));
      setDataConnection?.(client.currentDataConnection);
      setCall?.(client.currentCall);

      client.on('dataStarted', onDataConnectionStartedState);
      client.on('dataEnded', onDataConnectionEndedState);
      if (onPeerReady)
        client.on('peerReady', onPeerReady);
      if (onPeerDestroyed)
        client.on('peerDestroyed', onPeerDestroyed);
      if (onCallIncoming)
        client.on('callIncoming', onCallIncoming);
      if (onCallStarted)
        client.on('callStarted', onCallStarted);
      if (onCallEnded)
        client.on('callEnded', onCallEnded);
      if (onCallStreamReceived)
        client.on('callStreamReceived', onCallStreamReceived);
      if (onDataStarted)
        client.on('dataStarted', onDataStarted);
      if (onDataReceived)
        client.on('dataReceived', onDataReceived);
      if (onDataError)
        client.on('dataError', onDataError);
      if (onDataEnded)
        client.on('dataEnded', onDataEnded);
    });
    return () => {
      if (debug)
        console.log(`${debugTag}client effect cleanup`);
      getExistingCallClient(id)?.then?.(client => {
        if (debug)
          console.log(`${debugTag}hasClient`);
        window.removeEventListener('beforeunload', onBeforeUnload);
        client.off('dataStarted', onDataConnectionStartedState);
        client.off('dataEnded', onDataConnectionEndedState);
        if (onPeerReady)
          client.off('peerReady', onPeerReady);
        if (onPeerDestroyed)
          client.off('peerDestroyed', onPeerDestroyed);
        if (onCallIncoming)
          client.off('callIncoming', onCallIncoming);
        if (onCallStarted)
          client.off('callStarted', onCallStarted);
        if (onCallEnded)
          client.off('callEnded', onCallEnded);
        if (onCallStreamReceived)
          client.off('callStreamReceived', onCallStreamReceived);
        if (onDataStarted)
          client.off('dataStarted', onDataStarted);
        if (onDataReceived)
          client.off('dataReceived', onDataReceived);
        if (onDataError)
          client.off('dataError', onDataError);
        if (onDataEnded)
          client.off('dataEnded', onDataEnded);
      });
    }
  }, [
    setPeerId, setCall, setCallActive, setDataConnection,
    id, debug, clientOptions,
    onPeerReady, onPeerDestroyed,
    onCallIncoming, onCallStarted, onCallStreamReceived, onCallEnded,
    onDataStarted, onDataReceived, onDataError, onDataEnded,
    onDataConnectionStartedState, onDataConnectionEndedState,
    onBeforeUnload
  ]);
}

function delay(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms)
  })
}
