import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserMedia } from './useUserMedia';
import AsolviButton from '../../Elements/AsolviButton/AsolviButton';
import { getExistingCallClient, useCallClient } from './callClient';
import { NotificationGroup, Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';

const mediaConstraints = {
  audio: false,
  video: {
    // Prefer environment (back camera on phones) if available, otherwise will use "user"
    facingMode: "environment",

    width: 1920,
    height: 1080,
  }
}

interface PeerData {
  type: string,
  data: PointerData | string
}

interface PointerData {
  x: number,
  y: number,
}

const CallClientId = 'remoteAccess';

export default function RemoteAccessCall({ peerId, email, onStopSharing }: {
  peerId: string,
  email: string,
  onStopSharing: () => void,
}) {
  const { t } = useTranslation();

  const [pointer, setPointer] = useState<{ x: number, y: number }>();
  const divRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { stream: myStream, error: cameraError } = useUserMedia(mediaConstraints);

  useEffect(() => {
    if (cameraError)
      console.log(cameraError);
  }, [cameraError])

  useEffect(() => {
    if (videoRef?.current && myStream) {
      videoRef.current.srcObject = myStream;
    }
  }, [myStream, videoRef]);

  useEffect(() => {
    console.log('onPeerReady', myStream);
    if (!myStream)
      return;

    console.log('onPeerReady with stream');
    getExistingCallClient(CallClientId)?.then?.(client => {
      if (client.currentCall)
        return;
      console.log('onPeerReady has client');
      client.connect(peerId, { metadata: { username: email } });
      client.startCall(peerId, myStream);
    });
  }, [email, myStream, peerId]);

  const onStopSharingCore = useCallback(() => {
    getExistingCallClient(CallClientId)?.then?.(client => {
      client.endPeer();
    });
    onStopSharing();
  }, [onStopSharing]);

  const [showScreenCaptureInfo, setShowScreenCaptureInfo] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onDataReceived = useCallback((data: unknown) => {

    console.log(data);

    const dataObj: PeerData = JSON.parse(data as string);

    if (dataObj.type === 'pointer') {
      if (!videoRef.current)
        return;

      const {x: xp, y: yp } = dataObj.data as PointerData;

      let width = videoRef.current.clientWidth;
      let height = videoRef.current.clientHeight;

      setPointer({
        x: xp * width,
        y: yp * height,
      });

    } else if (dataObj.type === 'info') {

      const commandData = dataObj.data as string;

      if (commandData === 'endCall') {
        onStopSharingCore();

        onStopSharing();

      } else if (commandData === 'screenCapture') {
        setShowScreenCaptureInfo(true);

        if (timeoutRef.current)
          clearTimeout(timeoutRef.current);

        timeoutRef.current = setTimeout(() => setShowScreenCaptureInfo(false), 3000);

        return () => (timeoutRef.current && clearTimeout(timeoutRef.current));
      }
    }
  }, [onStopSharing, onStopSharingCore]);

  useCallClient(CallClientId, {
    clientOptions: { autoAnswerCall: false },
    debug: 'RemoteAccessCall',
    onDataReceived,
    onCallEnded: onStopSharingCore,
    onPeerDestroyed: onStopSharingCore,
  })

  if (cameraError) {
    return (
      <div>{t("RemoteAccess.errors.cameraError")}</div>
    )
  }
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'black',
        justifyContent: 'space-between',
      }}
    >

      <NotificationGroup style={{ top: 10, left: "50%", transform: "translateX(-50%)", zIndex: 1 }}>
        <Fade enter={false} exit={true}>
          {showScreenCaptureInfo &&
            <Notification type={{ style: "info" }} style={{width: 210}}>
              Evatic user captured screen
            </Notification>
          }
        </Fade>
      </NotificationGroup>

      <div style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
        alignItems: 'center',
      }}>
        <div style={{
          position: 'relative',
          lineHeight: 0,
        }}>
          {pointer?.x && pointer?.y &&
            <div
              ref={divRef}
              style={{
                position: 'absolute',
                transform: `translate(${pointer?.x ?? -50}px, ${pointer?.y ?? -50}px) translate(-50%, -50%)`,
                transition: 'transform 200ms ease-out',
                width: '40px',
                height: '40px',
                background: 'radial-gradient(circle at center, rgba(255,0,0,0.5) 20%, rgba(255,0,0,0) 80%',
              }}
            />
          }

          {showScreenCaptureInfo &&
            <div>{t('RemoteAccess.screenCaptureInfo')}</div>
          }

          <video
            style={{
              display: 'block',
              maxWidth: '100%',
              objectFit: 'contain',
            }}
            autoPlay
            muted={true}
            playsInline
            ref={videoRef}
          />
        </div>
      </div>
      <AsolviButton
        buttonType={'button'}
        rowStyle={{ margin: 0 }}
        colStyle={{ padding: 0 }}
        onClick={onStopSharingCore}
      >
        {t("RemoteAccess.stopSharing")}
      </AsolviButton>
    </div>
  )
}
