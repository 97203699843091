import * as React from 'react';
import * as ReactI18Next from 'react-i18next';
import * as ReactRedux from 'react-redux';
import * as I18Next from 'i18next';
import * as GeneralReducer from '../../../reducers/GeneralReducer';
import * as Store from '../../../store/Store';
import { getMachines, clearSearchFilter, clearMachines, IMachine, clearMachine } from '../../../slices/MachineSlice';
import * as apiEndPoints from '../../../sources/apiEndPoints';
import MachinePage from '../../Pages/MachinePage/MachinePage';
import axios from 'axios';

export interface IMachineInfo {
  MachineGuid?: string;
  MachineNo?: string;
  MachineId?: number;
  Dept?: string;

  ModelGuid?: string;
  ModelType?: string;
  ModelDescription?: string;

  Location?: string;
  InstallationDate?: Date;

  CustomerNo?: string;
  CustomerName?: string;
  CustomerGuid?: string;

  AddressName?: string;
  AddressRowNo?: number;
  Address1?: string;
  Address2?: string;
  Address3?: string;
  PostalCode?: string;
  PostDescription?: string;
  AddressCountry?: string;
  AddressPhone?: string;

  ContactNo?: number;
  Active?: boolean;
  OpenForInvoice?: boolean;
  Information?: string;


  SortGroup1?: string;
  SortGroup2?: string;
  SortGroup3?: string;
  SortGroup4?: string;
  SortGroup5?: string;

  Properties?: IMachinePropertyItem[];
}

export const EmptyMachine: Required<IMachineInfo> = {
  MachineGuid: "",
  MachineNo: "",
  MachineId: 0,
  Dept: "",

  ModelGuid: "",
  ModelType: "",
  ModelDescription: "",

  Location: "",
  InstallationDate: new Date(0),
  CustomerNo: "",
  CustomerName: "",
  CustomerGuid: "",

  AddressName: "",
  AddressRowNo: 0,
  Address1: "",
  Address2: "",
  Address3: "",
  PostalCode: "",
  PostDescription: "",
  AddressCountry: "",
  AddressPhone: "",

  ContactNo: 0,
  Active: false,
  OpenForInvoice: false,
  Information: "",

  SortGroup1: "",
  SortGroup2: "",
  SortGroup3: "",
  SortGroup4: "",
  SortGroup5: "",

  Properties: [],
};

export interface IMachineSearchDataContract {
  CustomerGuid?: string;
  SearchText?: string; //search address -> name, address 1, 2, postal code, postal desc.
  CustomerSortGroup1?: string;
  CustomerSortGroup2?: string;
  CustomerSortGroup3?: string;
  CustomerSortGroup4?: string;
  CustomerSortGroup5?: string;
  ModelGuid?: string;
  InstallationDateFrom?: Date;
  InstallationDateTo?: Date;
  PropertyFilterCode1?: string;
  PropertyFilterValue1?: string;
  PropertyFilterCode2?: string;
  PropertyFilterValue2?: string;
}

export interface IMachineCustomerItem {
  CustomerGuid: string;
  CustomerNo: string;
  CustomerName: string;
  Address1: string;
  Address2: string;
  PostalCode: string;
  PostDescription: string;
  AddressPhone: string;
  AddressFax: string;
  InvoiceCustomerGuid: string;
  InvoiceCustomerAddressRowNo: number;
  CustomerNoAndName: string;
}

export interface IMachineModelItem {
  ModelGuid: string;
  ModelType: string;
  Description: string;
}

export interface ICustomerSortGroupItem {
  LabelText: string;
  sortGroupNo: number;
  SortGroupValues: ICodeDescription[];
}

export interface ICodeDescription {
  Code: string;
  Description: string;
  CodeAndDescription: string;
}

export interface IMachinePropertyItem {
  PropertyGuid: string
  Id: string,
  Description: string,
  Value: string,
  DataType: string,
  UseValues: boolean,
  DropdownValues: string,
  Min: number,
  Max: number
}

export interface ICreateMachine {
  Result?: boolean | undefined;
  IsMachineExist?: boolean | undefined;
  MachineGuid?: string | undefined;
  MachineNo?: string | undefined;
  error?: string | undefined;
}

export interface ICustomerVisitAddress {
  CustomerGuid: string;
  RowNo: number;
  CustomerNo: string;
  CustomerName: string;
  AddressName: string;
  Address1: string;
  Address2: string;
  Address3: string;
  Country: string;
  PostalCode: string;
  PostalLocation: string;
  Phone: string;
  FaxNo: string;
  AreaCode: string;
}

export interface IMachineVisibilitySettings {
  ShowFilter: boolean;
  ShowProperties: boolean;
  ShowMachineId: boolean;
  AutoMachineId: boolean;
  AutoMachineNo: boolean;
  AllowAddressEdit: boolean;
  ShowMachineInformation: boolean;
  AllowPropertyEdit: boolean;
  ShowSortGroup1: boolean;
  ShowSortGroup2: boolean;
  ShowSortGroup3: boolean;
  ShowSortGroup4: boolean;
  ShowSortGroup5: boolean;
  MachineWriteAccess: boolean;
}

interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  General: GeneralReducer.IGeneral;
  Machine: IMachine;
  getMachines: (token: string, appUrl: string, customerGuid: string, email: string, searchData: IMachineSearchDataContract) => void;
  clearMachinesAndSearchFilter: () => void;
  clearMachine: () => void;
}

const MachinePageSeedComponent: React.FunctionComponent<IProps> = (Props) => {
  const { appUrl } = (window as any)["appConfig"];
  const { General, Machine, getMachines, clearMachinesAndSearchFilter, clearMachine } = Props;
  const { currentAuth0Token, activeCustomer, currentUserEmail } = General;
  const { searchData, machines } = Machine;
  const [visibilitySettings, setVisibilitySettings] = React.useState<IMachineVisibilitySettings>( {
    AllowAddressEdit: false,
    AllowPropertyEdit: false,
    AutoMachineId: false,
    AutoMachineNo: false,
    MachineWriteAccess: false,
    ShowFilter: false,
    ShowMachineId: false,
    ShowMachineInformation: false,
    ShowProperties: false,
    ShowSortGroup1: false,
    ShowSortGroup2: false,
    ShowSortGroup3: false,
    ShowSortGroup4: false,
    ShowSortGroup5: false
  });

  React.useEffect(()=>{
    if(currentAuth0Token){
      getMachineVisibilitySettings(currentAuth0Token,appUrl, activeCustomer, currentUserEmail).then(x => setVisibilitySettings(x));
    }
  }, [currentAuth0Token, currentUserEmail, appUrl, activeCustomer]);


  return (
    <MachinePage
      machines={machines}
      loadingMachines={Machine.loadingMachines}
      clearMachinesAndSearchFilter={() => clearMachinesAndSearchFilter()}
      getMachines={() => getMachines(currentAuth0Token, appUrl, activeCustomer, currentUserEmail, searchData)}
      clearMachine={clearMachine}
      visibilitySettings={visibilitySettings}
    />
  );
}

async function getMachineVisibilitySettings(token: string, appUrl: string, customerGuid: string, email: string): Promise<IMachineVisibilitySettings> {
  const url = appUrl + apiEndPoints.MachineAPIs.GetMachineVisibilitySettings(customerGuid, email);
  const response = await axios.get(url, { headers: { token }});
  return response.data as IMachineVisibilitySettings;
}

const mapStateToProps = (store: Store.IAppState) => {
  return {
    General: store.generalState.General,
    Machine: store.machineState.Machine
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMachines: (token: string, appUrl: string, customerGuid: string, email: string, searchData: IMachineSearchDataContract) => dispatch(getMachines({ token, appUrl, loggedInCustomerGuid: customerGuid, email, searchData })),
    clearMachinesAndSearchFilter: () => {
      dispatch(clearMachines());
      dispatch(clearSearchFilter());
    },
    clearMachine: () => dispatch(clearMachine())
  };
};

export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(MachinePageSeedComponent);

