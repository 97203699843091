import React, { FC, useRef, useState } from "react";
import AsolviModal from "../../Auxiliary/AsolviModal/AsolviModal";
import { useTranslation } from "react-i18next";
import { ContactDTO, WebAccessRightsDTO } from "./Contacts";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import { ContactAPIs } from "../../../sources/apiEndPoints";
import axios from "axios";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/Store";
import "./ContactModal.css";
import ContactsProperties, { EditableContact } from "./ContacsProperties";
import { Popup } from "@progress/kendo-react-popup";
import AsolviNotification from "../../Elements/AsolviNotification/AsolviNotification";

interface Props {
  showModal: boolean;
  handleClose: () => void;
  contact?: ContactDTO;
  loggedInRights?: WebAccessRightsDTO;
  customer: string,
  onExited: () => void;
  onChange: (contact: ContactDTO) => void;
  onSaved: () => void;
  onDeleted: () => void;
}

const EditContact: FC<Props> = ({contact, showModal, loggedInRights, customer, handleClose, onExited, onChange, onSaved, onDeleted}) => {
  let { appUrl } = (window as any).appConfig;

  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  let { currentAuth0Token }
    = useSelector((state: IAppState) => state.generalState.General);

  const anchor = useRef(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const [notificationState, setNotificationState] = useState<{
    message: string,
    showNotify: boolean,
    style: any,
  }>({
    message: "",
    showNotify: false,
    style: "success"
  });

  return (
    <div onClick={() => setShowDeletePopup(false)}>
      {contact &&
        <AsolviModal
          extraClassNames="ContactModal"
          showModal={showModal}
          header={t("Contacts.Contact.Edit.Title")}
          handleClose={handleClose}
          onExited={onExited}
        >
          <ContactsProperties
            contact={contact as EditableContact}
            customer={customer}
            onChange={(editableContact) => onChange({...contact, ...editableContact})}
            loggedInRights={loggedInRights}
          />

          <div style={{display: "flex", marginTop: "16px"}}>
            <AsolviButton
              buttonType={"submit"}
              className="k-primary"
              buttonText={t("Contacts.Contact.Save")}
              loading={isSaving}
              onClick={() => saveContact(contact)}
            />
            <div style={{width: "16px"}} />
            <div
              onClick={(e) => {
                setShowDeletePopup(true);
                e.stopPropagation();
              }}
              ref={anchor}
            >
              <AsolviButton
                buttonType={"submit"}
                buttonText={t("Contacts.Contact.Edit.Delete.Button")}
              />
              <Popup anchor={anchor.current} show={showDeletePopup} popupClass={"popup-content"}>

                <div style={{padding: "16px"}}>
                  <div style={{marginBottom: "10px"}}>
                    {t("Contacts.Contact.Edit.Delete.Title")}
                  </div>
                  <div style={{display: "flex"}}>
                    <AsolviButton
                      buttonType="submit"
                      className="k-danger"
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteContact();
                        setShowDeletePopup(false);
                      }}
                    >
                      {t("Contacts.Contact.Edit.Delete.Yes")}
                    </AsolviButton>
                    <div style={{width: "16px"}} />
                    <AsolviButton
                      buttonType="submit"
                      onClick={(e) => {
                        setShowDeletePopup(false);
                        e.stopPropagation();
                      }}
                    >
                      {t("Contacts.Contact.Edit.Delete.No")}
                    </AsolviButton>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
        </AsolviModal>
      }

      <AsolviNotification
        message={notificationState.message}
        showNotify={notificationState.showNotify}
        showIcon={false}
        closeAction={()=>{setNotificationState({...notificationState, showNotify: false})}}
        style={notificationState.style}
      />
    </div>
  );

  function saveContact(contact: ContactDTO) {

    if (!contact.FirstName || !contact.LastName || !contact.ContactName){
      setNotificationState({
        message: t("Contacts.Contact.MissingRequiredFields"),
        showNotify: true,
        style: "error"
      });
      return;
    }
    setIsSaving(true);
    const url = appUrl + ContactAPIs.UpdateContact(customer);

    axios.post(url, contact, { headers: { token: currentAuth0Token } })
      .then(() => {
        setIsSaving(false);
        onSaved()

        setNotificationState({
          message: t("Contacts.Contact.Edit.NotificationSuccess") + " (" + contact.ContactName + ")",
          showNotify: true,
          style: "success"
        });
        setTimeout(() => {
          setNotificationState({...notificationState, showNotify: false});
        }, 4000);

      }).catch(() => {

        setIsSaving(false);
        setNotificationState({
          message: t("Contacts.Contact.Edit.NotificationError"),
          showNotify: true,
          style: "error"
        })
        setTimeout(() => {
          setNotificationState({...notificationState, showNotify: false});
        }, 4000)
    })
  }

  function deleteContact() {
    if (!contact)
      return;

    const url = appUrl + ContactAPIs.DeleteContact(customer, contact.ContactNo);

    axios.delete(url, { headers: { token: currentAuth0Token } })
      .then(() => {

        setNotificationState({
          message: t("Contacts.Contact.Edit.Delete.NotificationSuccess") + " (" + contact.ContactName + ")",
          showNotify: true,
          style: "success"
        })
        setTimeout(() => {
          setNotificationState({...notificationState, showNotify: false});
        }, 4000)

        onDeleted();

      }).catch(() => {

        setNotificationState({
          message: t("Contacts.Contact.Edit.Delete.NotificationError"),
          showNotify: true,
          style: "error"
        })
        setTimeout(() => {
          setNotificationState({...notificationState, showNotify: false});
        }, 4000)
    })
  }
}


export default EditContact;
