import { AsolviDropDownItem } from "../components/Elements/AsolviDropDownList/AsolviDropDownList";

export default function (contactInformation: string, action: any) {
  const items = [] as Array<AsolviDropDownItem>;
  if (contactInformation) {

    for (let contact of JSON.parse(contactInformation)) {
        contact.itemAction = action;
        items.push(contact);
    }
  }
  return items;
}
