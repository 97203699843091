import * as React from "react";
import HomePage from "../../Pages/HomePage/HomePage";
import * as Auth0 from "@auth0/auth0-react";
import * as ReactRedux from 'react-redux';
import * as Store from "../../../store/Store";
import * as GeneralReducer from "../../../reducers/GeneralReducer";
import * as GeneralActions from "../../../actions/GeneralActions";
import * as SettingsReducer from "../../../reducers/SettingsReducer";
import * as SettingsActions from "../../../actions/SettingsActions";
import FooterGroupSeedComponent from "../Groups/FooterGroupSeedComponent";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import AsolviModal from "../../Auxiliary/AsolviModal/AsolviModal";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from "i18next";

import styles from "./HomePageSeedComponent.module.css";
import { removeTrailingSlash } from "../../../utils/trailingSlash";

interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  General: GeneralReducer.IGeneral;
  Settings: SettingsReducer.ISettings;
  getBuildInformation: (token: string, email: string, appUrl: string) => void;
  getMenuItems(token: string, email:string, customer:string, appUrl:string): (token: string, email:string, customer:string) => void; 
  handleAccessDenied(currentAccessDeniedState:boolean): (currentAccessDeniedState:boolean) => void;
  GetActiveCustomerInfo: (token: string, appUrl: string, email: string, customerGuid: string) => void;
  renderHomePageSeedComponent: boolean;
}

const HomePageSeedComponent: React.FunctionComponent<IProps> = (Props) => {
  const { t, GetActiveCustomerInfo, getMenuItems, getBuildInformation, handleAccessDenied } = Props;
  const contactDropdownList = Props.Settings.contactDropdownList;
  const token = Props.General.currentAuth0Token;
  const { appUrl} = (window as any)["appConfig"];
  const email = Props.General.currentUserEmail;
  const customer = Props.General.activeCustomer;
  
  const auth0Logout = Auth0.useAuth0().logout;
  const handleClose = () => {
    handleAccessDenied(false);    
    let returnUrl = removeTrailingSlash(window.location.origin + window.location.pathname);    
    auth0Logout({ returnTo: returnUrl });
  };

  React.useEffect(() => {    
    if(Props.renderHomePageSeedComponent){
      GetActiveCustomerInfo(token, appUrl, email, customer);
      getMenuItems(token, email, customer, appUrl);
      getBuildInformation(token, email , appUrl);
    } 
    const updateAccessDeniedModal = () => {
      try {
        if (contactDropdownList) {
          handleAccessDenied(JSON.parse(contactDropdownList).length < 1);
        }      
      } catch (error) {
        console.log(error)
      }
    };
    updateAccessDeniedModal();   
  }, [Props.renderHomePageSeedComponent, GetActiveCustomerInfo, getMenuItems,getBuildInformation, handleAccessDenied, token, appUrl, email, customer, contactDropdownList]);

  if(Props.renderHomePageSeedComponent)
  {
    return (
      <>
        <AsolviModal header={t("Standard.accessDenied")} showModal={Props.General.showAccessDenied} handleClose={handleClose}
                     message={t("Standard.accessDeniedMessage")} shouldDisplayFooter={true} shouldDisplaySpinner= {false}/>
        <Container fluid className={styles.fullHeightContainer}>
          <Row className={styles.flexRow}>
            <HomePage />
          </Row>
          {(Props.Settings.buildInfo) && <Row className={styles.staticRow}>
            <FooterGroupSeedComponent />
          </Row>}
        </Container>
      </>
    )
  }
  else
  {
    return(
      <></>
    )
  }
}

const mapStateToProps = (store: Store.IAppState) => {
  return {
    
    General: store.generalState.General,
    Settings: store.settingsState.Settings
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    
    getBuildInformation: (token: string, email: string, appUrl: string) => dispatch(SettingsActions.GetBuildInformation(token, email, appUrl)),
    getMenuItems: (token: string, email:string, customer:string, appUrl: string) => dispatch(SettingsActions.GetMenuItems(token, email, customer, appUrl)),
    handleAccessDenied: (currentAccessDeniedState:boolean) => dispatch(GeneralActions.handleAccessDeniedModal(currentAccessDeniedState)),
    GetActiveCustomerInfo: (token: string, appUrl: string, email: string, customerGuid: string) => dispatch(GeneralActions.GetActiveCustomerInfo(token, appUrl, email, customerGuid)),
  }
}
export default ReactRedux.connect(mapStateToProps, mapDispatchToProps)(ReactI18Next.withTranslation()(HomePageSeedComponent));

