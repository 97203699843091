import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Grid, GridCell, GridColumn, GridRowClickEvent, GridItemChangeEvent, GridToolbar, GridCellProps } from "@progress/kendo-react-grid";
import React, { CSSProperties, FC, useCallback, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AsolviButton from "../AsolviButton/AsolviButton";
import { IGridColumn, IGridCompositeFilter, IGridGroup, IGridSort } from "./interfaces";
import useGridDataProcessing from "./useGridDataProcessing";

export * from "./interfaces";

export interface Props<T> {
  data: T[];
  dataKeyField: Extract<keyof T, string>;
  gridLayout: IGridColumn<T>[];
  
  defaultSort?: IGridSort<T>[];
  sortable?: boolean;

  defaultFilter?: IGridCompositeFilter<T>;
  filterable?: boolean;
  enableExcelExport?: boolean;
  enableAddNew?: boolean;
  enableDelete?: boolean;

  groupable?: boolean;
  defaultGroupBy?: IGridGroup<T>[];
  lockedGroups?: boolean;

  editType?: "always" | "click" | "cell" | "manual";
  editField?: string;
  gridStyle?: CSSProperties,

  handleItemChange?: (item: T, field?: Extract<keyof T, string>, newValue?: any) => void;
  handleItemClick?: (item: T) => void;
  handleItemDoubleClick?: (item: T) => void;
  handleAddNew?: () => void;
  handleDelete?: (item: T) => void;
}

function AsolviAdvancedGrid<T>(props: Props<T>) {
  const { t } = useTranslation();
  const {
    data, dataKeyField, enableExcelExport, 
    enableAddNew, enableDelete, gridLayout = [],
    defaultGroupBy, groupable, lockedGroups = false,
    defaultSort, sortable, defaultFilter,
    editType = "manual", editField = "inEdit",
    gridStyle,
    
    filterable, handleItemChange, handleItemClick,
    handleItemDoubleClick, handleAddNew, handleDelete,
  } = props;

  const _export = useRef<ExcelExport | null>(null);
  const _grid = useRef<Grid | null>(null);

  const { 
    state, 
    editRow, finishEditRow,
    groupState, onGroupChange,
    sortState, onSortChange,
    filterState, onFilterChange,
    onExpandChange, toggleGroup,
  } = useGridDataProcessing<T>({ data, dataKeyField, editType, editField, defaultGroupBy, groupable, lockedGroups, defaultSort, sortable, defaultFilter, filterable });

  const addNew = useCallback(() => {
    if (handleAddNew)
      handleAddNew();
  }, [handleAddNew]);

  const remove = useCallback((item: T) => {
    if (handleDelete)
      handleDelete(item);
  }, [handleDelete]);

  
  const CommandCell = (cell: GridCellProps) => {
    if(cell.dataItem.groupId)
      return GridCell(cell);

    let editButton = undefined;
    if(editType === "cell") {
      if(cell.dataItem[editField]) {
        editButton = (
          <Button onClick={() => finishEditRow(cell.dataItem as T)} type="button" icon="checkmark" />
        );
      } else {
        editButton = (
          <Button onClick={() => editRow(cell.dataItem as T)} type="button" icon="edit" />
        );
      }
    }

    let deleteButton = undefined;
    if(enableDelete) {
      deleteButton = (
        <Button onClick={() => remove(cell.dataItem as T)} type="button" icon="delete" />
      )
    }
    return (
      <td className="k-command-cell" style={{ padding: "6px" }}>
        {editButton}
        {deleteButton}
      </td>
    );
  }

  const excelExport = useCallback(() => {
    if(_export.current !== null && _grid.current !== null) {
      _export.current.save();
    }
  }, [_export, _grid]);

  const onRowClick = useCallback((e: GridRowClickEvent) => {
    if(e.dataItem.groupId) {
      if(groupable)
        toggleGroup(e.dataItem.groupId);
    } else if (handleItemClick) {
      handleItemClick(e.dataItem as T);
    } else if (editType === "click") {
      editRow(e.dataItem);
    }
  }, [handleItemClick, editType, groupable, toggleGroup, editRow]);

  const onRowDoubleClick = useCallback((e: GridRowClickEvent) => {
    if (!e.dataItem.groupId && handleItemDoubleClick)
      handleItemDoubleClick(e.dataItem as T);
  }, [handleItemDoubleClick]);

  const onItemChanged = useCallback((e: GridItemChangeEvent) => {
    if(!handleItemChange) return;
    if(groupable && e.dataItem.groupId) return;

    handleItemChange(e.dataItem, e.field as Extract<keyof T, string>, e.value);
  }, [groupable, handleItemChange]);
  

  const visibleColumns = gridLayout.filter(column => column.visible);
  const columns = gridLayout.map(column => (
    <GridColumn
      field={column.fieldName}
      title={column.fieldLanguageKey}
      width={column.visible ? (
        column.colWidth ? `${column.colWidth}px` : "auto" 
      ) : "0px"}
      editable={column.editable}
      editor={column.editor}
      format={column.format}
      key={dataKeyField}
      filter={column.filterType}
      cell={column.cellComponent !== undefined || column.cellStyle !== undefined ? (props) => {
        if(props.dataItem.groupId)
          return GridCell(props);
        if(column.cellComponent) {
          return (
            <td className="k-command-cell" style={{ padding: "6px" }}>
              { column.cellComponent(props.dataItem) }
            </td>
          );
        }    
        if(column.cellStyle)
          return column.cellStyle(props);
      } : undefined}
    />
  ));

  

  return (
    <Container fluid className="AsolviGrid">
      <Row>
        { enableExcelExport && (<ExcelExport 
          ref={_export} 
          data={state} 
          group={groupable ? groupState : undefined} 
        >
          {visibleColumns.map((column, key) => (
            <ExcelExportColumn
              field={column.fieldName}
              title={column.fieldLanguageKey}
              width={column.colWidth}
              key={key} />
          ))}
        </ExcelExport>)}
        <Col sm={12}>
          <Grid
            style={gridStyle}
            ref={_grid}

            data={state}
            dataItemKey={dataKeyField}
            editField={editField}
            
            groupable={groupable}
            group={groupState}
            onGroupChange={onGroupChange}

            expandField="expanded"
            onExpandChange={onExpandChange}

            sortable={sortable}
            sort={sortState}
            onSortChange={onSortChange}

            filterable={filterable}
            filter={filterState}
            onFilterChange={onFilterChange}

            onRowClick={(groupable || handleItemClick) ? onRowClick : undefined}
            onRowDoubleClick={(groupable || handleItemDoubleClick) ? onRowDoubleClick : undefined}
            
            onItemChange={handleItemChange ? onItemChanged : undefined}
          >
            {(enableAddNew || enableExcelExport) &&
              <GridToolbar>
                <Row>
                  <Conditional predicate={enableAddNew}>
                    <Col sm={4}>
                      <AsolviButton
                        buttonText={t("AsolviGrid.AddNewButton")}
                        buttonIconName="plus"
                        onClick={addNew}
                        buttonType="button" />
                    </Col>
                  </Conditional>
                  <Conditional predicate={enableExcelExport}>
                    <Col sm={2}>
                      <AsolviButton
                        buttonText={t("AsolviGrid.ExportExcelButton")}
                        className="k-button k-primary"
                        onClick={excelExport}
                        buttonType="button" />
                    </Col>
                  </Conditional>
                </Row>
              </GridToolbar>
            }
            {columns}
            {(editType === "cell" || enableDelete) && <GridColumn cell={CommandCell} width="100px" />}
          </Grid>
        </Col>
      </Row>
    </Container>
  )
}

declare interface ConditionalProps {
  predicate: boolean | (() => boolean) | undefined;
}
const Conditional: FC<ConditionalProps> = ({ predicate, children }) => {
  let shouldRender = false;
  if (typeof predicate === 'function')
    shouldRender = predicate();
  else
    shouldRender = Boolean(predicate);

  return (
    <>{shouldRender ? children : null}</>
  );
}

export default AsolviAdvancedGrid;