import React, { FC } from "react";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

interface Props {
  operatorId: DateOperatorEnum
  onChange?: (event: DropDownListChangeEvent) => void
}

export enum DateOperatorEnum {
  Between = "Between",
  GreaterThanOrEqual = "GreaterThanOrEqual",
  Equals = "Equals",
  LessThanOrEqual = "LessThanOrEqual",
  NotEqual = "NotEqual",
  NoValue = "NoValue",
}

export const operatorEnumToSymbolMap: {[key in DateOperatorEnum]: string} = {
  [DateOperatorEnum.Between]: "Between",
  [DateOperatorEnum.GreaterThanOrEqual]: ">=",
  [DateOperatorEnum.Equals]: "=",
  [DateOperatorEnum.LessThanOrEqual]: "<=",
  [DateOperatorEnum.NotEqual]: "!=",
  [DateOperatorEnum.NoValue]: "IS NULL",
}

export const values: any[] = Object.keys(operatorEnumToSymbolMap).map((operator) => ({

  itemText: operatorEnumToSymbolMap[operator as DateOperatorEnum],
  itemId: operator,
}))

const DateFilterOperator: FC<Props> = ({onChange, operatorId}) => {

  const operator = {
    itemText: operatorEnumToSymbolMap[operatorId],
    itemId: operatorId,
  }
  const valueRender = (element: any, value: any) => {
    if (!value) {
      return element;
    }

    const children = [
      <span
        key={1}
        style={{
          display: "inline-block",
          color: "#212529",
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        {operatorEnumToSymbolMap[value as DateOperatorEnum]}
      </span>,
      <span key={2}>&nbsp; {element.props.children}</span>,
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };

  return (
    <DropDownList
      style={{width: '140px'}}
      data={values}
      value={operator.itemId}
      textField="itemText"
      onChange={onChange}
      valueRender={valueRender}
      dataItemKey="itemId"
    />
  )
}

export default DateFilterOperator;
