import * as React from "react";
import { DatePicker, DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import "@progress/kendo-theme-material/dist/all.scss";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import styles from './AsolviDateRangePicker.module.css';
import { ModalContext } from "../../Auxiliary/AsolviModal/AsolviModal";
import { DateInputsPopupSettings } from "@progress/kendo-react-dateinputs/dist/npm/PopupSettings";

export declare interface AsolviDateRangePickerChangeEvent extends Omit<DatePickerChangeEvent, "value"> {
  value: {fromDate?: Date, toDate?: Date}
}

export interface IProps{
  labelText: string,
  fromDate?: Date,
  toDate?: Date,
  onSelect?: (e: AsolviDateRangePickerChangeEvent) => void,
}
const AsolviDateRangePicker: React.FunctionComponent<IProps> = (Props) => {
  const context = React.useContext(ModalContext);
  let popupSettings = {} as DateInputsPopupSettings;
  if (context && context.current) {
    popupSettings.appendTo = context.current
  }
  const onFromSelect = (e:any) => {
    const e2 = {...e, value: { fromDate: e.value, toDate: Props.toDate } };
    Props.onSelect && Props.onSelect(e2);
  };
  const onToSelect = (e:any) => {
    const e2 = {...e, value: { fromDate: Props.fromDate, toDate: e.value } };
    Props.onSelect && Props.onSelect(e2);
  };

  return(
    <Row>
      <Col sm={4}>
        <label>
          {Props.labelText}
        </label>
      </Col>
      <Col sm={8} className={styles.dates}>
        <DatePicker
          popupSettings={popupSettings}
          className={styles.fromDate}
          value={Props.fromDate ?? null}
          defaultShow={false}
          onChange={onFromSelect}
        />
        <span className={styles.separator}>&mdash;</span>
        <DatePicker
          popupSettings={popupSettings}
          className={styles.toDate}
          value={Props.toDate ?? null}
          defaultShow={false}
          onChange={onToSelect}
        />
      </Col>
    </Row>


  );
}

export default AsolviDateRangePicker;