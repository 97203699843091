import React, { FC } from "react";
import { DropDownList, DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

interface Props {
  operatorId: OperatorEnum
  onChange?: (event: DropDownListChangeEvent) => void
}

export enum OperatorEnum {
  StartsWith = "StartsWith",
  Equals = "Equals",
  NoValue = "NoValue",
  GreaterThan = "GreaterThan",
  GreaterThanOrEqual = "GreaterThanOrEqual",
  LessThan = "LessThan",
  LessThanOrEqual = "LessThanOrEqual",
  NotEqual = "NotEqual",
}

export const operatorEnumToSymbolMap: {[key in OperatorEnum]: string} = {
  [OperatorEnum.StartsWith]: "Starts with",
  [OperatorEnum.Equals]: "=",
  [OperatorEnum.NoValue]: "IS NULL",
  [OperatorEnum.GreaterThan]: ">",
  [OperatorEnum.GreaterThanOrEqual]: ">=",
  [OperatorEnum.LessThan]: "<",
  [OperatorEnum.LessThanOrEqual]: "<=",
  [OperatorEnum.NotEqual]: "!=",
}

export const values: any[] = Object.keys(operatorEnumToSymbolMap).map((operator) => ({

  itemText: operatorEnumToSymbolMap[operator as OperatorEnum],
  itemId: operator,
}))

const TextFilterOperator: FC<Props> = ({onChange, operatorId}) => {

  const operator = {
    itemText: operatorEnumToSymbolMap[operatorId],
    itemId: operatorId,
  }
  const valueRender = (element: any, value: any) => {
    if (!value) {
      return element;
    }

    const children = [
      <span
        key={1}
        style={{
          display: "inline-block",
          color: "#212529",
          textAlign: "center",
          overflow: "hidden",
        }}
      >
        {operatorEnumToSymbolMap[value as OperatorEnum]}
      </span>,
      <span key={2}>&nbsp; {element.props.children}</span>,
    ];
    return React.cloneElement(element, { ...element.props }, children);
  };

  return (
    <DropDownList
      style={{width: '140px'}}
      data={values}
      value={operator.itemId}
      textField="itemText"
      onChange={onChange}
      valueRender={valueRender}
      dataItemKey="itemId"
    />
  )
}

export default TextFilterOperator;
