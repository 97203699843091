import * as React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import AsolviModal from '../../../Auxiliary/AsolviModal/AsolviModal';
import AsolviGrid, { IGridLayout } from '../../../Elements/AsolviGrid/AsolviGrid';
import TaskLogDetailsGroup from '../TaskLogDetailsGroup/TaskLogDetailsGroup';
import * as TaskReducer from '../../../../reducers/TaskReducer'
import styles from "./TaskLogGridGroup.module.css";
import * as ReactI18Next from 'react-i18next';
import { GridCellProps } from '@progress/kendo-react-grid';
import AsolviFadeInOut from '../../../Elements/AsolviFadeInOut/AsolviFadeInOut';

export interface IProps extends ReactI18Next.WithTranslation {
  loadingData: boolean;
  data: TaskReducer.ITaskLogItem[];
  showHelpdeskLog: boolean;
  showMachineId: boolean;
}

const TaskLogGridGroup: React.FunctionComponent<IProps> = (props) => {
  const { t } = ReactI18Next.useTranslation();
  const { loadingData } = props;
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [header, setHeader] = React.useState<string>("");
  const [selectedRow, setSelectedRow] = React.useState<TaskReducer.ITaskLogItem>();


  const showDetails = (e: any) => {
    if (e !== null) {
      setHeader(t("TaskLog.projectNo") + " " + e.dataItem.ProjectNo + "/" + e.dataItem.TaskNo);
      setSelectedRow(e.dataItem);
      setOpenModal(true);
    }
  }

  const detailsModal =
    <TaskLogDetailsGroup
      taskDetails={selectedRow ?? {} as TaskReducer.ITaskLogItem}
      showMachineId={props.showMachineId}
    />

  const asolviModal =
    <AsolviModal
      id="taskLogModal"
      header={header}
      message={detailsModal}
      shouldDisplaySpinner={false}
      showModal={openModal}
      extraClassNames={[styles.taskModal]}
      shouldDisplayFooter={true}
      handleClose={() => setOpenModal(false)}
    />;

  const cellStyleTaskType = (props: GridCellProps) => {
    const taskType = props.dataItem.TaskType;

    const icon = taskType === 1 ? (
      <span className="k-icon k-i-wrench k-icon-size" />
    ) : (
      <span className="k-icon k-i-inbox k-icon-size" />
    );

    const text = taskType === 1 ? t("TaskLog.Service") : t("TaskLog.Helpdesk");
    return (
      <td>
        {icon} {text}
      </td>
    );
  };

  const cellStyleStatus = (props: GridCellProps) => {
    const open = props.dataItem.Status === "0";

    const icon = open ? (
      <span className="k-icon k-i-clock k-icon-size" />
    ) : (
      <span className="k-icon k-i-check-outline k-icon-size" />
    );

    const text = open ? t("TaskLog.Open") : t("TaskLog.Closed");
    return (
      <td>
        {icon} {text}
      </td>
    );
  };

  let gridLayout = [] as IGridLayout[];

  gridLayout.push({ fieldName: "FilterDate", fieldLanguageKey: t("TaskLog.receivedDate"), colWidth: 210, editable: false, visible: true, filterType: "date", format: "{0:d}" });
  gridLayout.push({ cellStyle: cellStyleStatus, fieldName: "Status", fieldLanguageKey: t("TaskLog.status"), colWidth: 150, editable: false, visible: true });
  gridLayout.push({ fieldName: "ProjectNo", fieldLanguageKey: t("TaskLog.projectNo"), colWidth: 170, editable: false, visible: true });
  gridLayout.push({ fieldName: "TaskNo", fieldLanguageKey: t("TaskLog.taskNo"), colWidth: 150, editable: false, visible: true });
  gridLayout.push({ cellStyle: cellStyleTaskType, fieldName: "TaskType", fieldLanguageKey: t("TaskLog.taskType"), colWidth: 150, editable: false, visible: true });
  gridLayout.push({ fieldName: "TaskTitle", fieldLanguageKey: t("TaskLog.taskTitle"), colWidth: 250, editable: false, visible: true });
  gridLayout.push({ fieldName: "CustomerReferenceInfo", fieldLanguageKey: t("TaskLog.customerReferenceInfo"), colWidth: 200, editable: false, visible: true });
  gridLayout.push({ fieldName: "MachineNo", fieldLanguageKey: t("TaskLog.machineNo"), colWidth: 200, editable: false, visible: true });
  if (props.showMachineId) {
    gridLayout.push({ fieldName: "MachineIdNo", fieldLanguageKey: t("TaskLog.machineIdNo"), colWidth: 200, editable: false, visible: true });
  }
  gridLayout.push({ fieldName: "ModelType", fieldLanguageKey: t("TaskLog.modelType"), colWidth: 200, editable: false, visible: true });
  gridLayout.push({ fieldName: "SymptomText", fieldLanguageKey: t("TaskLog.symptomText"), colWidth: 200, editable: false, visible: true });
  gridLayout.push({ fieldName: "Symptom1", fieldLanguageKey: t("TaskLog.symptom1"), colWidth: 170, editable: false, visible: true });
  gridLayout.push({ fieldName: "Symptom2", fieldLanguageKey: t("TaskLog.symptom2"), colWidth: 170, editable: false, visible: true });
  gridLayout.push({ fieldName: "Symptom3", fieldLanguageKey: t("TaskLog.symptom3"), colWidth: 170, editable: false, visible: true });
  if (props.showHelpdeskLog) {
    gridLayout.push({ fieldName: "Symptom4", fieldLanguageKey: t("TaskLog.symptom4"), colWidth: 170, editable: false, visible: true });
  }
  gridLayout.push({ fieldName: "ActionText", fieldLanguageKey: t("TaskLog.actionText"), colWidth: 250, editable: false, visible: true });
  gridLayout.push({ fieldName: "ActionCode1", fieldLanguageKey: t("TaskLog.action1"), colWidth: 170, editable: false, visible: true });
  gridLayout.push({ fieldName: "ActionCode2", fieldLanguageKey: t("TaskLog.action2"), colWidth: 170, editable: false, visible: true });
  gridLayout.push({ fieldName: "ActionCode3", fieldLanguageKey: t("TaskLog.action3"), colWidth: 170, editable: false, visible: true });
  if (props.showHelpdeskLog) {
    gridLayout.push({ fieldName: "ActionCode4", fieldLanguageKey: t("TaskLog.action4"), colWidth: 170, editable: false, visible: true });
  }
  gridLayout.push({ fieldName: "CustomerName", fieldLanguageKey: t("TaskLog.customerName"), colWidth: 250, editable: false, visible: true });

  return (
    <Row>
      <Col sm={12} className={styles.TaskLogGrid}>
        <AsolviFadeInOut show={loadingData} lazyChildren>
          <div className={styles.gridOverlay}>
            <Spinner
              animation="border" variant="dark"
            />
          </div>
        </AsolviFadeInOut>
        <AsolviGrid
          sortable={true}
          filterable={true}
          data={props.data}
          gridLayout={gridLayout}
          dataKeyField={"TaskGuid"}
          handleChange={() => { }}
          enableExcelExport={true}
          handleRowClick={showDetails}
        />

        {openModal && asolviModal}
      </Col>
    </Row>
  )
}

export default ReactI18Next.withTranslation()(TaskLogGridGroup);