import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrderLine as addOrderLineAction,
  removeOrderLine as removeOrderLineAction,
  saveOrder as saveOrderAction,
} from "../../../../slices/OrderSlice";
import { IAppState } from "../../../../store/Store";
import OrderSummaryGroup from "../../../Groups/Order/OrderSummaryGroup";
import i18n from '../../../../i18n';
import { useTranslation } from "react-i18next";

export interface IProps {
}

export interface IOrderCreateSmallDataContract {
  Email: string,
  YourReference: string,
  CustomerReferenceInfo: string,
  Information: string,
  InvoiceAddresRowNo: number,
  InvoiceCountry: string,
  InvoicePostlocation: string,
  InvoicePostalcode: string,
  InvoiceAddress3: string,
  InvoiceAddress2: string,
  InvoiceAddress1: string,
  InvoiceName: string,
  DeliveryAddresRowNo: number,
  DeliveryCountry: string,
  DeliveryPostlocation: string,
  DeliveryPostalCode: string,
  DeliveryAddress3: string,
  DeliveryAddress2: string,
  DeliveryAddress1: string,
  DeliveryName: string,
  CustomerGuid: string,
  MachineAddressAsDeliveryAddress: boolean,
  OrderLines: IOrderLineCreateSmallDataContract[],
}

export interface IOrderCreateSmallRequestDataContract extends IOrderCreateSmallDataContract{
  ReceiptLabels:IOrderCreateEmailReceiptLabels;
}

export interface IOrderLineCreateSmallDataContract {
  LineNo: number;
  ArticleGuid: string;
  Qty: number;
  MachineGuid: string;
}

export interface IOrderCreateEmailReceiptLabels {
  ArticleNoLabel: string;
  ArticleDescriptionLabel: string;
  MachineNoLabel: string;
  ModelTypeLabel: string;
  QtyLabel: string;
}

export interface ICustomerContact {
  CustomerGuid: string;
  ContactNo: number;
  FirstName: string;
  LastName: string;
  FullName: string;
  Phone: string;
  FaxNo: string;
  Email: string;
}

let previousRequest: any = undefined;
const OrderSummaryGroupSeedComponent: FC<IProps> = () => {
  const { appUrl } = (window as any)["appConfig"];
  const { t } = useTranslation();

  const { currentAuth0Token: token, currentUserEmail: email } = useSelector((state: IAppState) => state.generalState.General);
  const currentOrder = useSelector((state: IAppState) => state.orderState.Order.currentOrder);
  const isLoadingDiscounts = useSelector((state: IAppState) => state.orderState.Order.isLoadingDiscounts);
  const visibilitySettings = useSelector((state: IAppState) => state.orderState.Order.visibilitySettings);
  const loadingCreateOrder = useSelector((state: IAppState) => state.orderState.Order.loadingCreateOrder);  

  const dispatch = useDispatch();
  const removeOrderLine = useCallback((lineNo) => dispatch(removeOrderLineAction(lineNo)), [ dispatch ]);
  const addOrderLine = useCallback((line) => {
    if(previousRequest?.abort) {      
      previousRequest.abort();
      previousRequest = undefined;
    }
    let currentRequest: any = dispatch(addOrderLineAction({
      appUrl,
      token,
      email,
      line,
    }));

    currentRequest.then(() => {
      if(currentRequest === previousRequest)
        previousRequest = undefined;
    });
    previousRequest = currentRequest;
  }, [appUrl, dispatch, email, token]);

  const saveOrder = useCallback(
    (token, appUrl, email, culture, body) => dispatch(saveOrderAction({ token, appUrl, email, culture, body })),
    [dispatch]
  );
  const languageCodes: { [key: string]: string} = {
    'nb': 'nb-NO',
    'nn': 'nb-NO',
  }

  let culture = i18n.language;
  culture = languageCodes[culture] ?? culture;


  let receiptLabels:IOrderCreateEmailReceiptLabels =
    {
      ArticleNoLabel: t("Order.ArticleNo"),
      ArticleDescriptionLabel: t("Order.ArticleDescription"),
      MachineNoLabel: t("Machine.MachineNo"),
      ModelTypeLabel: t("Machine.ModelType"),
      QtyLabel: t("Order.Qty")
    }

  return (
    <OrderSummaryGroup
      currentOrder={currentOrder}
      removeOrderLine={removeOrderLine}
      saveOrder={() => saveOrder(token, appUrl, email, culture, {...currentOrder.Heading, OrderLines: currentOrder.Lines, receiptLabels})}
      addOrderLine={addOrderLine}
      isLoadingDiscounts={isLoadingDiscounts}
      visibilitySettings={visibilitySettings}
      loadingCreateNewOrder={loadingCreateOrder}
    />
  )
}

export default OrderSummaryGroupSeedComponent;