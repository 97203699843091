import * as React from "react";
import * as ReactI18Next from "react-i18next";
import * as I18Next from "i18next";
import MachineGrid from "../../Groups/Machine/MachineGrid/MachineGrid";

import { Container, Row, Col } from "react-bootstrap";
import {
  default as AsolviNotification,
  notifyStyleType
} from "../../Elements/AsolviNotification/AsolviNotification";
import MachineFilterSeedComponent from "../../seedComponents/Groups/Machine/MachineFilterSeedComponent";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import styles from './MachinePage.module.css';
import { IMachineInfo, IMachineVisibilitySettings } from "../../seedComponents/Pages/MachinePageSeedComponent";
import MachineDetailsSeedComponent from "../../seedComponents/Groups/Machine/MachineDetailsSeedComponent";
import AsolviModal from '../../Auxiliary/AsolviModal/AsolviModal';



export interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  machines: IMachineInfo[];
  loadingMachines: boolean;
  getMachines: () => void;
  clearMachinesAndSearchFilter: () => void;
  clearMachine: () => void;
  visibilitySettings: IMachineVisibilitySettings;
}

const MachinePage: React.FunctionComponent<IProps> = (Props) => {
  let { t, machines, loadingMachines, getMachines, clearMachinesAndSearchFilter, clearMachine, visibilitySettings } = Props;
  let [messageNotify, setMessageNotify] = React.useState<string>("");
  let [showNotify, setShowNotify] = React.useState<boolean>(false);
  let [showIconNotify, setShowIconNotify] = React.useState<boolean>(false);
  let [styleNotify, setStyleNotify] = React.useState<notifyStyleType>("none");
  let [openModal, setOpenModal] = React.useState<boolean>(false);
  let [machineData, setMachineData] = React.useState<IMachineInfo>();

  const today = new Date();

  const handleCloseNotification = () => {
    setMessageNotify("");
    setShowNotify(false);
    setShowIconNotify(false);
    setStyleNotify("none");
    clearMachine();
  };

  const newMachineButtonAction = () => {
    let initialMachineData = {} as IMachineInfo;
    initialMachineData.Active = true;
    initialMachineData.OpenForInvoice = true;
    initialMachineData.InstallationDate = today;
    setMachineData(initialMachineData);
    setOpenModal(true);
  }

  const handleUpdateMachineAction = (e:any) => {
    let updateMachineData = {...e} as IMachineInfo;
    updateMachineData.Active = true;
    setMachineData(updateMachineData);
    setOpenModal(true);
  }



  const closeMachineButtonAction = () => {
    handleCloseNotification();
    setOpenModal(false);
  }

  const machineDetailsModal =
    <MachineDetailsSeedComponent
      closeButtonAction={closeMachineButtonAction}
      machineData={machineData}
      setMachineData={setMachineData}
      updateListAction={getMachines}
      visibilitySettings={visibilitySettings}
    />

  const asolviModal =
    <AsolviModal id="machineDetailsModal"
                 header={machineData?.MachineGuid?t("Machine.UpdateMachine"):t("Machine.NewMachine")}
                 message={machineDetailsModal}
                 shouldDisplaySpinner={false}
                 showModal={openModal}
                 modalSize={"xl"}
                 shouldDisplayFooter={false}
                 handleClose={closeMachineButtonAction}
    />

  return (
    <Container fluid className={styles.machinePageContainer}>
      <AsolviNotification
        message={messageNotify}
        showNotify={showNotify}
        showIcon={showIconNotify}
        closeAction={handleCloseNotification}
        style={styleNotify} />
      <Row className={styles.filterRow}>
        <Col sm={12}>
          <MachineFilterSeedComponent visibilitySettings={visibilitySettings} />
        </Col>
      </Row>
      <Container fluid>
        <Row className={styles.buttonRow}>
          <Col sm={8} className={styles.leftSideButtons}>
              <AsolviButton
                buttonText={t("Machine.SearchButton")}
                buttonType="button"
                buttonDisabled={loadingMachines}
                onClick={getMachines}
                className={`${styles.searchButton} k-primary`}
              />
              <AsolviButton
                buttonText={t("Machine.ClearButton")}
                buttonType="button"
                onClick={clearMachinesAndSearchFilter}
                className={styles.clearButton}
              />
          </Col>
          { (visibilitySettings.MachineWriteAccess) &&
            <Col sm={4} className={styles.rightSideButtons}>
              <AsolviButton className={`${styles.newMachineButton} k-primary`} buttonText={t("Machine.NewMachineButton")} buttonType="submit"
                            onClick={newMachineButtonAction} buttonDisabled={false}
              />
            </Col>
          }
        </Row>
      </Container>
      <Row>
        <Col sm={12}>
          <MachineGrid
            gridData={machines}
            loadingMachines={loadingMachines}
            showMachineId={visibilitySettings.ShowMachineId}
            onRowClick={(e:any) => {
              handleUpdateMachineAction(e);
            }}
            modalOpen={openModal}
            enableExcelExport            
          />
        </Col>
      </Row>
      {openModal && asolviModal}
    </Container>
  )
};

export default ReactI18Next.withTranslation()(MachinePage);