import * as Redux from 'redux';
import * as ReduxThunk from 'redux-thunk';
import * as SettingsReducer from "../reducers/SettingsReducer";
import * as Axios from 'axios';
import * as ApiEndPoints from "../sources/apiEndPoints";
import * as GeneralActions from "../actions/GeneralActions";


export enum SettingsActionTypes {
    GET_AVAILABLE_DBS = "GET_AVAILABLE_DBS",
    GET_DEFAULT_SETTINGS = "GET_DEFAULT_SETTINGS",
    GET_CONTACT_LIST = "GET_CONTACT_LIST",
    GET_CONTACT_DROPDOWN_LIST ="GET_CONTACT_DROPDOWN_LIST",
    GET_BUILD_INFORMATION = "GET_BUILD_INFORMATION",
    GET_MENU_ITEMS = "GET_MENU_ITEMS",
    GET_DEPARTMENTS = "GET_DEPARTMENTS"
}

export interface ISettingsGetAvailableDbs {
    type: SettingsActionTypes.GET_AVAILABLE_DBS;
    Settings: SettingsReducer.ISettings;
}

export interface ISettingsGetDefaultSettings {
    type: SettingsActionTypes.GET_DEFAULT_SETTINGS;
    Settings: SettingsReducer.ISettings;
}

export interface ISettingsGetContactList {
    type: SettingsActionTypes.GET_CONTACT_LIST;
    Settings: SettingsReducer.ISettings;
}

export interface ISettingsGetContactDropdownList {
    type: SettingsActionTypes.GET_CONTACT_DROPDOWN_LIST;
    Settings: SettingsReducer.ISettings;
}

export interface ISettingsGetBuildInformation {
    type: SettingsActionTypes.GET_BUILD_INFORMATION;
    Settings: SettingsReducer.ISettings;
}

export interface ISettingsGetMenuItems {
    type: SettingsActionTypes.GET_MENU_ITEMS;
    Settings: SettingsReducer.ISettings;
}

export interface ISettingsGetDepartments {
    type: SettingsActionTypes.GET_DEPARTMENTS;
    Settings: SettingsReducer.ISettings;
}

export type SettingsActions = ISettingsGetAvailableDbs | ISettingsGetDefaultSettings | ISettingsGetContactList | ISettingsGetContactDropdownList | ISettingsGetBuildInformation | ISettingsGetMenuItems | ISettingsGetDepartments;


export const GetAvailableDbs: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetAvailableDbs>
    > = (appUrl: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {

                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetAvailableDbs());
                dispatch({
                    Settings: { dbInfo: JSON.stringify(response.data) },
                    type: SettingsActionTypes.GET_AVAILABLE_DBS
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { dbInfo: null },
                    type: SettingsActionTypes.GET_AVAILABLE_DBS
                });
            }

        }
    };

export const GetAvailableSettings: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetDefaultSettings>
    > = (token: string, appUrl: string, email: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetCustomerWebSettings(email), { headers: { token: token } });
                dispatch({
                    Settings: { defaultSettings: JSON.stringify(response.data) },
                    type: SettingsActionTypes.GET_DEFAULT_SETTINGS
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { defaultSettings: null },
                    type: SettingsActionTypes.GET_DEFAULT_SETTINGS
                });
            }
        }
    };

export const GetContactList: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetContactList>
    > = (token: string, email: string, appUrl: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetContactList(email), { headers: { token: token } });
                dispatch({
                    Settings: { contactList: JSON.stringify(response.data) },
                    type: SettingsActionTypes.GET_CONTACT_LIST
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { contactList: null },
                    type: SettingsActionTypes.GET_CONTACT_LIST
                });
            }
        }
    };

export const GetContactDropDownList: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetContactDropdownList>
    > = (token: string, email: string, appUrl: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(
                  appUrl + ApiEndPoints.CustomerWebAPIs.GetCustomersOfECWContact(email),
                  { headers: { token: token } });
                if (response.data.length === 1) {
                    dispatch({
                        General: { activeCustomer: response.data[0].itemId },
                        type: GeneralActions.GeneralActionTypes.HANDLESELECTEDCUSTOMER
                      });                    
                }

                dispatch({
                    Settings: { contactDropdownList: JSON.stringify(response.data) },
                    type: SettingsActionTypes.GET_CONTACT_DROPDOWN_LIST
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { contactDropdownList: null },
                    type: SettingsActionTypes.GET_CONTACT_DROPDOWN_LIST
                });
            }
        }
    };

export const GetBuildInformation: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetBuildInformation>
    > = (token: string, email : string, appUrl: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetBuildInformation(email), { headers: { token: token } });
                dispatch({
                    Settings: { buildInfo: JSON.stringify(response.data) },
                    type: SettingsActionTypes.GET_BUILD_INFORMATION
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { buildInfo: null },
                    type: SettingsActionTypes.GET_BUILD_INFORMATION
                });
            }
        }
    };

export const GetMenuItems: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetMenuItems>
    > = (token: string, email: string, customer:string, appUrl: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetMenuItems(email, customer), { headers: { token: token } });
                dispatch({
                    Settings: { menuItems: JSON.stringify(response.data) },
                    type: SettingsActionTypes.GET_MENU_ITEMS
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { menuItems: null },
                    type: SettingsActionTypes.GET_MENU_ITEMS
                });
            }
        }
    }

export const GetDepartments: Redux.ActionCreator<
    ReduxThunk.ThunkAction<Promise<any>, SettingsReducer.ISettingsState, null, ISettingsGetDepartments>
    > = (token: string, email: string, appUrl: string) => {
        return async (dispatch: Redux.Dispatch) => {
            try {
                const response = await Axios.default.get(appUrl + ApiEndPoints.CustomerWebAPIs.GetDepartments(email), { headers: { token: token } });
                dispatch({
                    Settings: { departmentCodes: response.data},
                    type: SettingsActionTypes.GET_DEPARTMENTS
                });
            } catch (err) {
                console.log(err);
                dispatch({
                    Settings: { departmentCodes: null },
                    type: SettingsActionTypes.GET_DEPARTMENTS
                });
            }
        }
    }
