import * as React from "react";
import styles from "./MachineGrid.module.css";
import * as I18Next from "i18next";
import * as ReactI18Next from 'react-i18next';
import { Col, Row, Spinner } from "react-bootstrap";
import AsolviFadeInOut from "../../../Elements/AsolviFadeInOut/AsolviFadeInOut";
import AsolviGrid, { IGridLayout } from "../../../Elements/AsolviGrid/AsolviGrid";
import { parseDate } from '@telerik/kendo-intl';
import { CSSProperties } from "react";

export interface Props extends ReactI18Next.WithTranslation{
  t: I18Next.TFunction;
  gridData: any[];
  loadingMachines: boolean;
  showMachineId: boolean;
  onRowClick: (event:any) => void;
  modalOpen: boolean;
  enableExcelExport?: boolean;
  gridStyle?: CSSProperties;
}

const MachineGrid: React.FunctionComponent<Props> = (Props) => {
  let { t, gridData, loadingMachines, onRowClick, modalOpen, enableExcelExport, gridStyle } = Props;

  let gridLayout = [] as IGridLayout[];

  gridLayout.push({ fieldName: "MachineNo", fieldLanguageKey: t("Machine.MachineNo"), colWidth: 210, editable: false, visible: true });
  if (Props.showMachineId) {
    gridLayout.push({ fieldName: "MachineId", fieldLanguageKey: t("Machine.IdNo"), colWidth: 210, editable: false, visible: true });
  }
  gridLayout.push({ fieldName: "ModelType", fieldLanguageKey: t("Machine.ModelType"), colWidth: 210, editable: false, visible: true });
  gridLayout.push({ fieldName: "ModelDescription", fieldLanguageKey: t("Machine.ModelDescription"), colWidth: 210, editable: false, visible: true });
  gridLayout.push({ fieldName: "Location", fieldLanguageKey: t("Machine.Location"), colWidth: 210, editable: false, visible: true });
  gridLayout.push({ fieldName: "FilterDate", fieldLanguageKey: t("Machine.InstallationDate"), colWidth: 210, editable: false, visible: true, filterType: "date", format: "{0:d}" });
  gridLayout.push({ fieldName: "Dept", fieldLanguageKey: t("Machine.Dept"), colWidth: 150, editable: false, visible: true });
  gridLayout.push({ fieldName: "CustomerNo", fieldLanguageKey: t("Machine.CustomerNo"), colWidth: 150, editable: false, visible: true });
  gridLayout.push({ fieldName: "CustomerName", fieldLanguageKey: t("Machine.CustomerName"), colWidth: 210, editable: false, visible: true });
  gridLayout.push({ fieldName: "AddressName", fieldLanguageKey: t("Machine.AddressName"), colWidth: 210, editable: false, visible: true });
  gridLayout.push({ fieldName: "Address1", fieldLanguageKey: t("Machine.Address1"), colWidth: 210, editable: false, visible: true });
  gridLayout.push({ fieldName: "Address2", fieldLanguageKey: t("Machine.Address2"), colWidth: 210, editable: false, visible: true });
  gridLayout.push({ fieldName: "Address3", fieldLanguageKey: t("Machine.Address3"), colWidth: 210, editable: false, visible: true });
  gridLayout.push({ fieldName: "PostalCode", fieldLanguageKey: t("Machine.PostalCode"), colWidth: 210, editable: false, visible: true });
  gridLayout.push({ fieldName: "PostDescription", fieldLanguageKey: t("Machine.Description"), colWidth: 210, editable: false, visible: true });

  const listWithFormattedDates = (listData: any[]) => {
    let result = [] as any[];
    result = listData.map(list => {
      if (list.InstallationDate !== null) {
        const formattedDate = parseDate(list.InstallationDate.toString());
        const filterableDate = new Date(formattedDate.getFullYear(), formattedDate.getMonth(), formattedDate.getDate());
        return { ...list, InstallationDate: formattedDate, FilterDate: filterableDate };
      } else {
        return { ...list, FilterDate: null };
      }

    });
    return result;
  }

  return (
    <Row>
      <Col sm={12} className={styles.gridContainer}>
        <AsolviFadeInOut show={loadingMachines && !modalOpen} lazyChildren>
          <div className={styles.gridOverlay}>
            <Spinner
              animation="border" variant="dark"
            />
          </div>
        </AsolviFadeInOut>
        <AsolviGrid
          gridStyle={gridStyle}
          sortable={true}
          filterable={true}
          data={listWithFormattedDates(gridData)}
          gridLayout={gridLayout}
          dataKeyField={"MachineGuid"}
          handleChange={() => { }}
          enableExcelExport={enableExcelExport}
          handleRowClick={(e) => onRowClick(e.dataItem)}
        />
      </Col>
    </Row>
  )
}

export default ReactI18Next.withTranslation()(MachineGrid);

