export const convertLanguage = (lang: string) => {
    switch(lang) { 
        case "enGB": {
           return "en-GB"; 
        } 
        case "en": { 
            return "en-US"; 
        }
        case "de": { 
            return "de-DE"; 
        }  
        case "es": { 
            return "es-ES"; 
        } 
        case "fi": { 
            return "fi-FI"; 
        } 
        case "fr": { 
            return "fr-FR"; 
        } 
        case "nb": { 
            return "nb-NO"; 
        } 
        case "nl": { 
            return "nl-NL"; 
        } 
        case "sv": { 
            return "sv-SE"; 
        } 
        case "tr": { 
            return "tr-TR"; 
        }  
        case "da": { 
            return "da-DK"; 
        }  
        default: { 
            return "en-GB";
        } 
     } 
};

export const converti18nToKendoLanguage = (lang: string) => {
    switch(lang) {         
        case "de": { 
            return "de"; 
        }
        case "enGB": { 
            return "en-GB"; 
        }
        case "es": { 
            return "es"; 
        }
        case "fi": { 
            return "fi"; 
        }
        case "fr": { 
            return "fr"; 
        }
        case "no": { 
            return "nb"; 
        }  
        case "nb": { 
            return "nb"; 
        }  
        case "nl": { 
            return "nl"; 
        }
        case "sv":
        case "sv-SE": { 
            return "sv"; 
        }
        case "tr": { 
            return "tr"; 
        }
        case "da": { 
            return "da"; 
        }
        //need to return english as default. if we don't do this and for instance select danish in the browser we will get messages like "NoLocale: Missing locale info for 'da'""
        default: { 
            return "en";
        } 
     } 
};