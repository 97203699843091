import * as React from 'react';
import "./TaskCreatePage.css";
import AsolviPanelBar, { IPanelItem } from '../../Elements/AsolviPanelBar/AsolviPanelBar';
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { ITaskCustomerGroupData } from "../../Groups/Task/TaskCustomerGroup/TaskCustomerGroup";
import TaskCustomerGroupSeedComponent from '../../seedComponents/Groups/Task/TaskCustomerGroupSeedComponent';
import TaskMachineGroupSeedComponent from "../../seedComponents/Groups/Task/TaskMachineGroupSeedComponent";
import TaskPropertiesGroupSeedComponent from "../../seedComponents/Groups/Task/TaskPropertiesGroupSeedComponent";
import TaskContactGroupSeedComponent from "../../seedComponents/Groups/Task/TaskContactGroupSeedComponent";
import TaskSymptomGroupSeedComponent from "../../seedComponents/Groups/Task/TaskSymptomGroupSeedComponent";
import TaskInfoGroupSeedComponent from "../../seedComponents/Groups/Task/TaskInfoGroupSeedComponent";
import TaskUploadGroupSeedComponent from "../../seedComponents/Groups/Task/TaskUploadGroupSeedComponent";
import { ITaskInfoGroupData } from "../../Groups/Task/TaskInfoGroup/TaskInfoGroup";
import { ITaskContactGroupData } from "../../../reducers/TaskReducer";
import { ISymptomData } from '../../Groups/Task/TaskSymptomGroup/TaskSymptomGroup';
import { ITaskUploadGroupData } from "../../Groups/Task/TaskUploadGroup/TaskUploadGroup";
import { ITaskMachineGroupData } from '../../Groups/Task/TaskMachineGroup/TaskMachineGroup';
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import AsolviRadioGroup from '../../Elements/AsolviRadioGroup/AsolviRadioGroup';
import { RadioButtonProps } from '@progress/kendo-react-inputs';
import { TaskTypeEnums } from '../../seedComponents/Pages/TaskCreatePageSeedComponent';


export interface ITaskGroupData {
  CustomerGroupData: ITaskCustomerGroupData | undefined;
  MachineGroupData: ITaskMachineGroupData | undefined;
  SymptomGroupData: ISymptomData | undefined;
  ContactGroupData: ITaskContactGroupData | undefined;
  InfoGroupData: ITaskInfoGroupData | undefined;
  UploadGroupData: ITaskUploadGroupData | undefined;
  TaskType: any;
}

export interface IProps  extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  radioGroupData: RadioButtonProps[];
  submitButtonAction: () => void;
  clearButtonAction: () => void;
  showCustomerGroup: boolean;
  showMachineGroup: boolean;
  showPropertiesGroup: boolean;
  showContactGroup: boolean;
  showSymptomCodeGroup: boolean;
  showInfoGroup: boolean;
  showUploadGroup: boolean;
  extractData?: (data: ITaskGroupData) => void;
  clearData?: boolean;
  createLoading: boolean;
}


const TaskCreatePage : React.FunctionComponent<IProps> = (Props) =>
{
  const { t, extractData, radioGroupData } = Props;
  const [customerGroupData, setCustomerGroupData] = React.useState<ITaskCustomerGroupData>();
  const [machineGroupData, setMachineGroupData] = React.useState<ITaskMachineGroupData>();
  const [symptomGroupData, setSymptomGroupData] = React.useState<ISymptomData>();
  const [contactGroupData, setContactGroupData] = React.useState<ITaskContactGroupData>();
  const [infoGroupData, setInfoGroupData] = React.useState<ITaskInfoGroupData>();
  const [UploadGroupData, setUploadGroupData] = React.useState<ITaskUploadGroupData>();

  const [taskType, setTaskType] = React.useState<any>(radioGroupData.length > 0 ? radioGroupData[0].value : TaskTypeEnums[1]);

  let children: Array<IPanelItem> = [];

  Props.showCustomerGroup && (children.push({itemTitle: t("TaskCreate.customerGroupLabel"), expanded: true, key:1,
    ItemBody: <TaskCustomerGroupSeedComponent extractData={setCustomerGroupData}/>}));

  Props.showMachineGroup && (children.push({ itemTitle: t("TaskCreate.machineGroupLabel"), expanded: true, key: 2,
    ItemBody: <TaskMachineGroupSeedComponent extractData={setMachineGroupData} clearData={Props.clearData} />}));

  Props.showPropertiesGroup && (children.push({itemTitle: t("TaskCreate.propertiesGroupLabel"), expanded: true, key:3,
    ItemBody: <TaskPropertiesGroupSeedComponent />}));

  Props.showContactGroup && (children.push({ itemTitle: t("TaskCreate.contactGroupLabel"), expanded: true, key: 4,
    ItemBody: <TaskContactGroupSeedComponent extractData={setContactGroupData} clearData={Props.clearData} taskType={TaskTypeEnums[taskType]} />}));

  Props.showSymptomCodeGroup && (children.push({ itemTitle: t("TaskCreate.symptomGroupLabel"), expanded: true, key: 5,
    ItemBody: <TaskSymptomGroupSeedComponent extractData={setSymptomGroupData} clearData={Props.clearData} taskType={TaskTypeEnums[taskType]} /> }));

  Props.showInfoGroup && (children.push({itemTitle: t("TaskCreate.infoGroupLabel"), expanded: true, key: 6,
    ItemBody: <TaskInfoGroupSeedComponent extractData={setInfoGroupData} clearData={Props.clearData} />}));

  Props.showUploadGroup && (children.push({itemTitle: t("TaskCreate.uploadGroupLabel"), expanded: true, key: 7,
    ItemBody: <TaskUploadGroupSeedComponent extractData={setUploadGroupData} clearData={Props.clearData}/>}))

  const handleRadioGroupChange = (value:any) => {
    setTaskType(value);
  };

  React.useEffect(()=>{
    if (radioGroupData.length > 0)
      setTaskType(radioGroupData[0].value);
  }, [radioGroupData]);

  React.useEffect(()=>{
    const sendData = () =>{
      if(extractData)
      {
        let data = {} as ITaskGroupData;
        data.InfoGroupData = infoGroupData;
        data.ContactGroupData = contactGroupData;
        data.CustomerGroupData = customerGroupData;
        data.MachineGroupData = machineGroupData;
        data.SymptomGroupData = symptomGroupData;
        data.UploadGroupData = UploadGroupData;
        data.TaskType = taskType;
        extractData(data);
      }
    }

    sendData();
  }, [taskType, customerGroupData, machineGroupData, symptomGroupData, contactGroupData, infoGroupData, UploadGroupData, extractData]);

  return(
    <Container fluid className="TaskCreatePageContainer">
      <Row className="AsolviRadioGroup">
        <Col sm={12}>
          <AsolviRadioGroup
            value = {taskType}
            layout = {"horizontal"}
            data={Props.radioGroupData}
            disabled={false}
            onChange = {handleRadioGroupChange}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <AsolviPanelBar childs={children} />
        </Col>
      </Row>
      <Row className="TaskFooter">
        <Col sm={8}/>

        <Col sm={2}>
          <AsolviButton onClick={Props.submitButtonAction} buttonText={t("TaskCreate.SubmitButtonText")}
                        buttonType={'button'} className="k-primary"
                        loading={Props.createLoading} />
        </Col>
        <Col sm={2}>
          <AsolviButton onClick={Props.clearButtonAction} buttonText={t("TaskCreate.ClearButtonText")} buttonType={'button'}/>
        </Col>
      </Row>
    </Container>
  );
}

export default ReactI18Next.withTranslation()(TaskCreatePage);