import * as Redux from 'redux';
import * as ReduxThunk from 'redux-thunk';
import * as GeneralReducer from "../reducers/GeneralReducer";
import * as SettingsReducer from './../reducers/SettingsReducer';
import * as FrameReducer from "../reducers/FrameReducer";
import * as MeterLoginReducer from "../reducers/MeterLoginReducer";
import * as MeterReadingReducer from "../reducers/MeterReadingReducer";
import machineReducer, { IMachineState } from '../slices/MachineSlice';
import orderReducer, { IOrderState } from '../slices/OrderSlice';
import documentsReducer, { IDocumentsState } from '../slices/DocumentsSlice';
import * as TaskReducer from '../reducers/TaskReducer';

export interface IAppState{
  generalState: GeneralReducer.IGeneralState;
  settingsState: SettingsReducer.ISettingsState;
  frameState: FrameReducer.IFrameState;
  machineState: IMachineState;
  meterLoginState: MeterLoginReducer.IMeterLoginState;
  meterReadingState: MeterReadingReducer.IMeterReadingState;
  taskState: TaskReducer.ITaskState;
  orderState: IOrderState;
  documentsState: IDocumentsState;
}


const appReducer = Redux.combineReducers<IAppState>({
  generalState: GeneralReducer.GeneralReducer,
  settingsState: SettingsReducer.settingsReducer,
  frameState: FrameReducer.frameReducer,
  machineState: machineReducer,
  meterLoginState: MeterLoginReducer.MeterLoginReducer,
  meterReadingState: MeterReadingReducer.MeterReadingReducer,
  taskState: TaskReducer.TaskReducer,
  orderState: orderReducer,
  documentsState: documentsReducer,
});

export default () => {
  const composeEnhancers = (
    (process.env["NODE_ENV"] !== 'production'
      && ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
    ) || Redux.compose);

  const store = Redux.createStore(
    appReducer,
    undefined,
    composeEnhancers(
      Redux.applyMiddleware(
        ReduxThunk.default,
      )
    )
  );
  
  return { store };
}