import TaskPageSeedComponent from "./Pages/TaskPageSeedComponent";
import MeterReadingPageSeedComponent from "./Pages/MeterReadingPageSeedComponent";
import MachinePageSeedComponent from "./Pages/MachinePageSeedComponent";
import OrderPageSeedComponent from "./Pages/OrderPageSeedComponent";
import Documents from "../modular/Documents/Documents";
import Reports from "../modular/Reports/Reports";
import Contacts from "../modular/Contacts/Contacts";


// Because server sends the routes as strings, we need a way to map these strings to components
// Only routes that are re-written from wisej to react are in this map

export default new Map<string, any>([
  ['TaskPageSeedComponent', TaskPageSeedComponent],
  ['MeterReadingPageSeedComponent', MeterReadingPageSeedComponent],
  ['MachinePageSeedComponent', MachinePageSeedComponent],
  ['OrderPageSeedComponent', OrderPageSeedComponent],
  ['Documents', Documents],
  ['Reports', Reports],
  ['Contacts', Contacts],
]);
