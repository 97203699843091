import * as React from "react";
import { FC } from "react";
import "./DrawerGroup.css";
import AsolviDrawer from "../../Auxiliary/AsolviDrawer/AsolviDrawer";
import { AsolviDrawerItem } from "../../seedComponents/Groups/DrawerGroupSeedComponent";
import { DrawerSelectEvent } from "@progress/kendo-react-layout/dist/npm/drawer/interfaces/DrawerSelectEvent";
import { Route, Switch } from "react-router-dom";
import AsolviIframe from "../../Auxiliary/AsolviIframe/AsolviIframe";
import RouteableComponents from "../../seedComponents/RouteableComponents";
import ConsentsPageSeedComponent from "../../seedComponents/Pages/ConsentsPageSeedComponent";

export interface Props {
  frameUrl: string,
  menuItems: AsolviDrawerItem[],
  expanded: boolean,
  onSelect: (ev: DrawerSelectEvent) => void;
}

const DrawerGroup  : FC<Props> = (props) =>{
  const { frameUrl, menuItems, expanded, onSelect} = props;

  let startFrameUrl = frameUrl;
  return (
    <>
      <AsolviDrawer
        expanded={expanded}
        mode={"push"}
        items={menuItems}
        position="start"
        mini={true}
        frameUrl={frameUrl}
        onSelect={onSelect}
      >
        <Switch>
          {
            [...menuItems.map( item => {
              if(item.component){
                let Tag = RouteableComponents.get(item.component);
                return (
                  <Route
                    key={item.key}
                    exact={true}
                    path={"/"+item.route}
                    component={Tag}
                  />
                )
              } else
                return (
                  <Route
                    key={item.key}
                    exact={true}
                    path={"/"+item.route}
                    render={(props) => <AsolviIframe {...props} frameUrl={startFrameUrl}/>}
                  />
                )
            })
            ]
          }
          <Route key="consent" path="/consents" component={ConsentsPageSeedComponent}/>
          <Route key={99} exact={true} render={(props) => <AsolviIframe {...props} frameUrl={startFrameUrl} />} />
          <Route
            key={'not-found'}
            render={() => <h1>404 - Not found</h1>}
          />
        </Switch>
      </AsolviDrawer>
    </>
  );
}

export default DrawerGroup;
