import React, { CSSProperties, FC, useEffect } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { clearDocumentsAction, IDocument } from "../../../slices/DocumentsSlice";
import { IAppState } from "../../../store/Store";
import * as ApiEndPoints from "../../../sources/apiEndPoints";

import AsolviAdvancedGrid, { IGridColumn } from "../../Elements/AsolviAdvancedGrid/AsolviAdvancedGrid";
import AsolviFadeInOut from "../../Elements/AsolviFadeInOut/AsolviFadeInOut";

import styles from "./DocumentGrid.module.css";
import { useTranslation } from "react-i18next";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import { useInternationalization } from "@progress/kendo-react-intl";

interface Props {
  gridStyle: CSSProperties;
}

const DocumentGrid: FC<Props> = (props) => {
  const { documents, isLoadingDocuments } = useSelector((state: IAppState) => state.documentsState);
  const { gridStyle } = props;

    const { t } = useTranslation();
    const intl = useInternationalization();

  const onDocumentClicked = (document: IDocument) => {
    let { appUrl } = (window as any).appConfig;
    window.open(appUrl + ApiEndPoints.DocumentAPIs.GetDocument(document.DocumentGuid), "_blank");
  };

    const gridLayout: IGridColumn<IDocument>[] = [
        { fieldName: "DocumentGuid", colWidth: 0, fieldLanguageKey: t("Documents.Grid.DocumentGuid"), editable: false, visible: false },
        { colWidth: 50, fieldLanguageKey: "", editable: false, visible: true, cellComponent: item => <AsolviButton onClick={() => onDocumentClicked(item)} buttonType="button" buttonIconName="download" /> },
        { fieldName: "ProjectNo", colWidth: 120, fieldLanguageKey: t("Documents.Grid.ProjectNo"), editable: false, visible: true, },
        { fieldName: "MachineNo", colWidth: 150, fieldLanguageKey: t("Documents.Grid.MachineNo"), editable: false, visible: true, },
        { fieldName: "DateCreated", colWidth: 220, fieldLanguageKey: t("Documents.Grid.DateCreated"), editable: false, visible: true, filterType: "date", cellComponent: item => <span>{ intl.formatDate(new Date(item.DateCreated), "g") }</span> },
        { fieldName: "Title", colWidth: 700, fieldLanguageKey: t("Documents.Grid.Title"), editable: false, visible: true, },
        { fieldName: "Size", colWidth: 120, fieldLanguageKey: t("Documents.Grid.Size"), editable: false, visible: true, },
    ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearDocumentsAction())
  }, [dispatch]);

  return (
    <Row>
      <Col sm={12} className={styles.gridContainer}>
        <AsolviFadeInOut show={isLoadingDocuments} lazyChildren>
          <div className={styles.gridOverlay}>
            <Spinner animation="border" variant="dark" />
          </div>
        </AsolviFadeInOut>
        <AsolviAdvancedGrid
          gridStyle={gridStyle}
          sortable={true}
          filterable={false}
          data={documents}
          gridLayout={gridLayout}
          dataKeyField="DocumentGuid"
        />
      </Col>

    </Row>
  );
};

export default DocumentGrid;
