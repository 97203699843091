import * as React from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import { useUserMedia } from './useUserMedia';
import Peer, { DataConnection, MediaConnection } from 'peerjs';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store/Store';


const mediaConstraints = {
  audio: true,
  video: {
    // Prefer environment (back camera on phones) if available, otherwise will use 'user'
    facingMode: 'environment'
  }
}

const RemoteAccessPOC: FC<{}> = () => {

  let { currentUserEmail }
    = useSelector((state: IAppState) => state.generalState.General);

  const { stream: myStream, error } = useUserMedia(mediaConstraints);

  useEffect(() => {
    if (error)
      console.log(error);
  }, [error])

  const myStreamRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (myStreamRef?.current && myStream) {
      myStreamRef.current.srcObject = myStream;
    }
  }, [myStream]);


  const [myId, setMyId] = useState<string>();
  const [connection, setConnection] = useState<DataConnection>();

  useEffect(() => {
    if (!connection)
      return;

    connection.on('data', (data) => {
      console.log(data);
    })
  }, [connection])

  const [peerId, setPeerId] = useState<string>();

  const [peerIdInput, setPeerIdInput] = useState<string>('');

  const [call, setCall] = useState<MediaConnection>();

  useEffect(() => {
    if (!call)
      return;

    call.on('stream', (stream) => {

      setPeerStream(stream);
    });
  }, [call])

  const [peerStream, setPeerStream] = useState<MediaStream>();

  const peerStreamRef = useRef<HTMLVideoElement>(null);

  const [messageInput, setMessageInput] = useState('');

  useEffect(() => {

    if (peerStreamRef?.current && peerStream) {
      peerStreamRef.current.srcObject = peerStream;
    }
  }, [peerStream])

  const [peer, setPeer] = useState<Peer>();


  useEffect(() => {
    const peer = new Peer();
    setPeer(peer);

    peer.on('open', (id) => {
      setMyId(id);
    })

    peer.on('connection', (dataConnection: DataConnection) => {
      setConnection(dataConnection);
      setPeerId(dataConnection.peer);

      dataConnection.on('data', (data) => {
        console.log(data);
      });

      dataConnection.close();
    });


    peer.on('error', (err) => {
      console.error(err);
    });



    peer.on('call', (mediaConnection) => {

      // TODO acceptCall logic
      const acceptsCall = true

      setCall(mediaConnection);

      if (acceptsCall) {

        // Answer the call with your own video/audio stream
        mediaConnection.answer(myStream);

        // Receive data
        mediaConnection.on('stream', (stream) => {
          setPeerStream(stream);
        });

        // Handle when the call finishes
        mediaConnection.on('close', () => {
          // TODO
          alert("The video call has finished");
        });

      } else {
        console.log("Call denied !");
      }
    });

  }, [myStream])

  const [isWaitingForCall, setIsWaitingForCall] = useState(false);


  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-6">

          {/* Display video of the current user */}
          <div className="text-center">
            <video
              id="my-camera"
              width="300"
              height="300"
              autoPlay
              muted
              playsInline
              className="center-block"
              ref={myStreamRef}
            />
            <span className="label label-info">You</span>
          </div>
        </div>

        <div className="col-md-6 col-lg-6">
          {/* Display video of the connected peer */}
          <div className="text-center">
            <video
              id="peer-camera"
              width="300"
              height="300"
              autoPlay
              playsInline
              className="center-block"
              ref={peerStreamRef}
            ></video>
            <span className="label label-info" id="connected_peer"></span>
          </div>
        </div>
      </div>

      <div className="row">
        <h1 className="text-center">
          <small> Share the following ID with the pal that wants to talk with you</small>
        </h1>
        {/* The ID of your current session */}
        <h4 className="text-center">
          <span
            onClick={() => {
              if (myId)
                navigator.clipboard.writeText('https://remote-access.herokuapp.com/#/' + myId)}
            }
          >
            {myId} (click to copy)</span>
        </h4>
        <div className="col-md-12 col-lg-12">
          <div className="form-horizontal" id="connection-form">
            <fieldset>
              <legend>Connection Form</legend>
              <div className="form-group">
                <label htmlFor="name" className="col-lg-2 control-label">Username</label>
                <div className="col-lg-10">
                  <input type="text" className="form-control" name="name" id="name" placeholder="Your random username" />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="peer_id" className="col-lg-2 control-label">Peer ID (id of your pal)</label>
                <div className="col-lg-10">
                  <input
                    type="text"
                    className="form-control"
                    name="peer_id" id="peer_id"
                    placeholder="Peer ID"
                    value={peerIdInput}
                    onChange={(e) => {
                      setPeerIdInput(e.currentTarget.value)
                    }}
                  />

                </div>
              </div>
              <div className="form-group">
                <div className="col-lg-10 col-lg-offset-2">
                  <button
                    id="connect-to-peer-btn"
                    className="btn btn-primary"
                    onClick={() => {

                      if (peer && peerIdInput) {
                        const conn = peer.connect(peerIdInput, {
                          metadata: {
                            'username': currentUserEmail,
                          }
                        });
                        setConnection(conn);
                        setPeerId(conn.peer)
                      }
                    }}
                  >
                    Connect to Peer
                  </button>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="col-md-12 col-lg-12">
          <div id="chat" className="hidden">
            <div id="messages-container">
              <div className="list-group" id="messages"></div>
            </div>
            <div id="message-container">
              <div className="form-group">
                <label className="control-label">Live chat</label>
                <div className="input-group">
                  <span className="input-group-btn">

                    <button
                      id="call"
                      className="btn btn-info"
                      onClick={() => {
                        if (!peer || !peerId || !myStream)
                          return;

                        const call = peer.call(peerId, myStream)
                        setPeerId(call.peer);

                        setIsWaitingForCall(true);

                        call.on('stream', (stream) => {

                          setPeerStream(stream);

                          setIsWaitingForCall(false);
                        });

                        call.on('close', () => {
                        })

                        setCall(call);
                      }}

                      disabled={!peer || !peerId || !myStream || isWaitingForCall}
                    >
                      {isWaitingForCall ? 'Waiting...' : 'Call'}
                    </button>

                  </span>

                  <input
                    type="text" className="form-control" name="message" id="message"
                    onInput={(e) => setMessageInput(e.currentTarget.value)}
                    placeholder="Your message here ..." />
                  <span className="input-group-btn">

                    <button
                      id="send-message"
                      className="btn btn-success"
                      disabled={!connection || !messageInput}
                      onClick={() => {
                        if (!connection)
                          return;

                        connection.send(messageInput);
                      }}
                    >
                      Send Message
                    </button>

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default RemoteAccessPOC;
