import React, { FC, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ContactsGrid from "./ContactsGrid";
import EditContact from "./EditContact";
import NewContact from "./NewContact";
import { ContactAPIs, CustomerWebAPIs } from "../../../sources/apiEndPoints";
import axios from "axios";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/Store";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import { useTranslation } from "react-i18next";
import { ITaskCustomerItem } from "../../../reducers/TaskReducer";
import AsolviAdvancedDropdownList from "../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";

export interface ContactDTO {
  WebAccess: boolean;
  WebPassword?: any;
  WebAccessProject: boolean;
  WebAccessOrder: boolean;
  WebAccessMeterReporting: boolean;
  WebAccessProjectLog: boolean;
  WebAccessOrderLog: boolean;
  WebAccessSupport: boolean
  WebAccessSupportLog: boolean;
  WebAccessMachine: boolean;
  WebAccessContact: boolean;
  WebAccessOnlyOwn: boolean;
  ContactGuid: string;
  ContactNo: number;
  ContactCode?: any;
  ContactName: string;
  FirstName: string;
  LastName: string;
  JobTitle?: any;
  Phone?: any;
  Mobile?: any;
  Fax?: any;
  Email1: string;
  Email2?: any;
  MainContact?: any;
  Username?: any;
  Title?: any;
  ContactInfo?: any;
  Birthday?: any;
  SirTitle?: any;
  LetterTitle?: any;
  ContactNotes?: any;
  ContactId?: any;
  ContactStatus: boolean;
  ExternalSystemKey?: any;
  AddressRow?: any;
}

export interface WebAccessRightsDTO {
  WebAccess: boolean;
  WebAccessSupport: boolean;
  WebAccessSupportLog: boolean;
  WebAccessProject: boolean;
  WebAccessProjectLog: boolean;
  WebAccessOrder: boolean;
  WebAccessOrderLog: boolean;
  WebAccessMeterReporting: boolean;
  WebAccessMachine: boolean;
  WebAccessContact: boolean;
}

const Contacts: FC = () => {
  let { appUrl } = (window as any).appConfig;
  let { currentAuth0Token, activeCustomer, currentUserEmail }
    = useSelector((state: IAppState) => state.generalState.General);
  let { t } = useTranslation();

  const [showNewContactModal, setShowNewContactModal] = useState<boolean>(false);
  const [showEditContactModal, setShowEditContactModal] = useState<boolean>(false);

  const [currentContact, setCurrentContact] = useState<ContactDTO | undefined>(undefined);
  const [loggedInRights, setLoggedInRights]
    = useState<WebAccessRightsDTO | undefined>(undefined);

  useEffect(() => {
    const url = appUrl + ContactAPIs.GetLoggedInContactWebAccessRights(activeCustomer)
    axios.get<WebAccessRightsDTO>(
      url, { headers: { token: currentAuth0Token } }
    ).then(
      res => {
        setLoggedInRights(res.data);
      }
    );
  }, [activeCustomer, appUrl, currentAuth0Token])


  const [contacts, setContacts] = useState<ContactDTO[]>([]);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(activeCustomer);

  useEffect(() => {
    const url = appUrl + ContactAPIs.GetContacts(currentCustomer);
    setIsLoadingContacts(true);
    axios.get<ContactDTO[]>(
      url, { headers: { token: currentAuth0Token } }
    ).then(
      res => {
        setContacts(res.data);
        setIsLoadingContacts(false);
      }
    );
  }, [activeCustomer, appUrl, currentAuth0Token, currentCustomer])

  const [customers, setCustomers] = useState<ITaskCustomerItem[]>([]);
  
  useEffect(() => {
    const url = appUrl + CustomerWebAPIs.GetCustomersLinkedToMain(activeCustomer, currentUserEmail)
    axios.get<ITaskCustomerItem[]>(
      url, { headers: { token: currentAuth0Token } }
    ).then(
      res => {
        setCustomers(res.data);
      }
    );
  }, [activeCustomer, appUrl, currentAuth0Token, currentUserEmail])

  const customerColumns = [
    {
      field: "CustomerNo",
      header: t("Machine.CustomerNumber"),
      width: 150,
      uniqueKey: "CustomerNo"
    },
    {
      field: "CustomerName",
      header: t("Machine.CustomerName"),
      width: 400,
      uniqueKey: "CustomerName"
    }
  ];

  return (
    <div style={{width: '100%', marginBottom: '25px'}}>
      <Row>
        <Col>
          <div>
            <div style={{margin: '15px', display: 'flex'}}>
              <div style={{marginRight: '30px'}}>
                <AsolviButton
                  buttonType="submit"
                  className="k-primary"
                  onClick={() => {
                    setShowNewContactModal(true)
                  }}
                  widthShouldNotBe100Percent
                >
                  {t("Contacts.NewContact")}
                </AsolviButton>
              </div>
              <AsolviAdvancedDropdownList
                labelText={t("Machine.Customer")}
                shownText="CustomerName"
                columns={customerColumns}
                data={customers}
                disabled={customers.length < 2}
                clearButton={false}
                onSelect={(e) => {setCurrentCustomer(e.value.CustomerGuid)}}
                selectedItem={customers?.find(ex => ex.CustomerGuid === currentCustomer)}
              />
              
            </div>

            <ContactsGrid
              contacts={contacts}
              isLoadingContacts={isLoadingContacts}
              onSelectContact={(contact) => {
                setCurrentContact(contact)
                setShowEditContactModal(true)
              }}
            />
          </div>

        </Col>
      </Row>

      <EditContact
        showModal={showEditContactModal}
        contact={currentContact}
        loggedInRights={loggedInRights}
        customer={currentCustomer}
        onExited={() => setCurrentContact(undefined)}
        handleClose={() => setShowEditContactModal(false)}
        onChange={(contact) => setCurrentContact(contact)}
        onSaved={onSavedOrDeleted}
        onDeleted={onSavedOrDeleted}
      />

      <NewContact
        showModal={showNewContactModal}
        contact={currentContact}
        loggedInRights={loggedInRights}
        customer={currentCustomer}
        onExited={() => setCurrentContact(undefined)}
        handleClose={() => setShowNewContactModal(false)}
        onSaved={onSavedOrDeleted}
      />
    </div>
  )

  function onSavedOrDeleted() {
    const url = appUrl + ContactAPIs.GetContacts(currentCustomer)
    setIsLoadingContacts(true);
    axios.get<ContactDTO[]>(
      url, { headers: { token: currentAuth0Token } }
    ).then(
      res => {
        setContacts(res.data);
        setIsLoadingContacts(false);
        setShowEditContactModal(false);
        setShowNewContactModal(false);
      }
    );
  }
}

export default Contacts;
