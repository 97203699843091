export const removeTrailingSlash = (url: string): string => {
  if(moreThanThreeSlashes(url) && url.charAt(url.length - 1) === '/')
    return url.substring(0, url.length - 1);
  return url;
}

export const moreThanThreeSlashes = (url: string): boolean => {
  let count = 0;
  for(let c of url) {
    if(c === '/')
      count += 1;
    if(count > 3)
      return true;
  }
  return false;
}