import * as React from "react";
import { FC, ReactNode, useEffect, useState } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { ConsentTemplateDto } from "../../seedComponents/Pages/ConsentsPageSeedComponent";
import { ConsentAPIs } from "../../../sources/apiEndPoints";
import AsolviButton from "../../Elements/AsolviButton/AsolviButton";
import RemoteAccessCall from "./RemoteAccessCall";

const RemoteAccess: FC = () => {

  const { path } = useRouteMatch();

  return (
    <div style={{ height: '100%' }}>
      <Switch>
        <Route
          path={path + "/:base64"}
        >
          <RemoteAccessCore />
        </Route>
        <Route
          render={() => (
            <div>
              <RemoteAccessHeader />
              404
            </div>
          )}
        />
      </Switch>
    </div>
  )
};

export default RemoteAccess;


const RemoteAccessCore: FC = () => {
  const { base64 } = useParams<{ base64: string }>();
  const { t } = useTranslation();

  let base64IsValid = true;
  let decoded = "";

  try {
    decoded = atob(base64)
  } catch {
    base64IsValid = false;
  }

  const lastIndexOfSlash = decoded.lastIndexOf("/");

  const email = decoded.substring(0, lastIndexOfSlash);

  const peerId = decoded.substring(lastIndexOfSlash + 1);

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const emailIsValid = emailRegex.test(email)

  const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  const peerIdIsValid = guidRegex.test(peerId);


  const [isSharingCamera, setIsSharingCamera] = useState(false)
  const [isSessionEnded, setIsSessionEnded] = useState(false)


  if (!base64IsValid)
    return (
      <div>
        <RemoteAccessHeader />
        {t("RemoteAccess.errors.base64IsInvalid")}
      </div>
    )
  if (!emailIsValid)
    return (
      <div>
        <RemoteAccessHeader />
        {t("RemoteAccess.errors.emailIsInvalid")}: {email}
      </div>
    )
  if (!peerIdIsValid)
    return (
      <div>
        <RemoteAccessHeader />
        {t("RemoteAccess.errors.peerIdIsInvalid")}
      </div>
    )
  return (
    <div style={{ height: '100%' }}>
      <ConsentGuard
        email={email}
        onAccept={() => setIsSharingCamera(true)}
      >
        {isSessionEnded
          ? <div>
            <RemoteAccessHeader />
            <div style={{
              padding: '16px 20px 0 20px',
              boxSizing: 'border-box',
            }}>
              <h1 style={{
                fontWeight: 600,
                fontSize: '18px',
                marginBottom: '16px'
              }}>
                {t("RemoteAccess.sessionEndedHeader")}
              </h1>
              {t("RemoteAccess.sessionEnded")}
            </div>
          </div>

          : isSharingCamera

            ? <RemoteAccessCall
              peerId={peerId}
              email={email}
              onStopSharing={() => setIsSessionEnded(true)}
            />

            : <div style={{ height: '100%' }}>
              <RemoteAccessHeader />
              <div style={{
                padding: '16px 20px',
                height: '100%',
              }}>
                <h1 style={{
                  fontWeight: 600,
                  fontSize: '18px',
                  marginBottom: '16px'
                }}>
                  {t("RemoteAccess.pageTitle")}
                </h1>
                <div style={{ marginBottom: '16px' }}>
                  {t("RemoteAccess.startSharingText")}
                  <br />
                  <a href={'TODO'} target={'_blank'} rel="noopener noreferrer">asolvi.com/...</a>
                </div>
                <AsolviButton
                  buttonType={'button'}
                  className={'k-primary'}
                  onClick={() => setIsSharingCamera(true)}
                >
                  {t("RemoteAccess.startSharing")}
                </AsolviButton>
              </div>
            </div>
        }

      </ConsentGuard>
    </div>
  )
}

const RemoteAccessHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <div style={{
      width: '100%',
      backgroundColor: '#647C6B',
      color: 'white',
      padding: '8px 20px',
      fontWeight: 600,
      fontSize: '18px',
    }}>
      {t("RemoteAccess.evaticCustomerWeb")}
    </div>
  )
}

const ConsentGuard: FC<{
  children: ReactNode,
  email: string,
  onAccept: () => void,
}> = ({ children, email, onAccept }) => {
  const { appUrl } = (window as any)["appConfig"];

  const [isLoading, setIsLoading] = useState(true);
  const [hasAcceptedConsent, setHasAcceptedConsent] = useState(false);
  const [errorLoadingConsent, setErrorLoadingConsent] = useState();
  const [consent, setConsent] = useState<ConsentTemplateDto>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!email)
      return;

    let req = Axios.get<ConsentTemplateDto[]>(
      appUrl + ConsentAPIs.GetRemoteAccessConsent(email),
    )

    req.then((response) => {
      const hasAccepted = response.data.length === 0;
      setHasAcceptedConsent(hasAccepted);

      if (!hasAccepted) {
        setConsent(response.data[0]);
      }

      setIsLoading(false);

    })

    req.catch((error) => {

      setIsLoading(false);
      console.log(error);

      setErrorLoadingConsent(error);
    })
  }, [appUrl, email])


  const [isAccepting, setIsAccepting] = useState(false);
  const [errorAccepting, setErrorAccepting] = useState();

  const onClickAccept = () => {
    setIsAccepting(true);
    setErrorAccepting(undefined);

    let req = Axios.post<boolean>(
      appUrl + ConsentAPIs.AcceptRemoteAccessConsent(email),
    );

    req.then(() => {
      setIsAccepting(false);
      setHasAcceptedConsent(true);
      onAccept();
    })

    req.catch((error) => {
      setIsAccepting(false);
      setErrorAccepting(error);

      console.log(error);
    })
  }

  if (isLoading)
    return (
      <div>
        <RemoteAccessHeader />
        <div style={{ padding: '16px 20px' }}>
          {t("RemoteAccess.loadingConsent")}
        </div>
      </div>
    )

  if (errorLoadingConsent) {
    return (
      <div>
        <RemoteAccessHeader />
        <div style={{ padding: '16px 20px' }}>
          {t("RemoteAccess.errors.loadingConsent")}
        </div>
      </div>
    )
  }

  if (!hasAcceptedConsent)
    return (
      <div>
        <RemoteAccessHeader />
        <div style={{
          padding: '16px 20px 0 20px',
          boxSizing: 'border-box',
        }}>

          <div>
            <h1 style={{
              fontWeight: 600,
              fontSize: '18px',
              marginBottom: '16px',
            }}>
              {consent?.Name}
            </h1>

            {consent?.Text && <div dangerouslySetInnerHTML={{ __html: consent?.Text }} />}
          </div>


          <div style={{
            position: 'sticky',
            bottom: '0%',
            padding: '8px 0',
            backgroundColor: 'white',
          }}>

            {errorAccepting &&
              <div>{t("RemoteAccess.errors.acceptingConsent")}</div>
            }

            <AsolviButton
              buttonType={"submit"}
              className={"k-primary"}
              onClick={onClickAccept}
              loading={isAccepting}
            >
              {t("RemoteAccess.acceptConsent")}
            </AsolviButton>

          </div>
        </div>
      </div>
    )

  return (<>{children}</>);
}
