import * as React from "react";
import { FC, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ComboBox } from '@progress/kendo-react-dropdowns';
import "@progress/kendo-theme-material/dist/all.scss";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns/dist/npm/ComboBox/ComboBoxProps";
import { filterBy } from "@progress/kendo-data-query";
import { FilterDescriptor } from "@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface";

export interface Props {
  label: string,
  data: {text: string, value: any}[],
  value: any,
  labelSizes?: number | ILabelSizes,
  onChange: (event: ComboBoxChangeEvent) => any,
}

declare interface ILabelSizes {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}


const AsolviComboBox: FC<Props> = ({labelSizes, value, data, label, onChange}) => {

  const [valueToDisplayDataMap, setValueToDisplayDataMap] = useState<any>({});
  useEffect(() => {
    // This code assumes that data does not have two instances with the same values
    let map: any = {};

    data.forEach((d) => {
      map[d.value] = d;
    })
    setValueToDisplayDataMap(map);
  }, [data])

  let labelSize = labelSizes ?? 4;
  if (typeof labelSize !== "object") {
    labelSize = {
      xs: labelSize,
    };
  }
  let inputSize = {
    xs: labelSize.xs ? 12 - labelSize.xs : undefined,
    sm: labelSize.sm ? 12 - labelSize.sm : undefined,
    md: labelSize.md ? 12 - labelSize.md : undefined,
    lg: labelSize.lg ? 12 - labelSize.lg : undefined,
    xl: labelSize.xl ? 12 - labelSize.xl : undefined
  }

  // Using this pattern https://www.telerik.com/kendo-react-ui/components/dropdowns/combobox/filtering/
  const [filteredData, setFilteredData] = useState(data.slice());
  function filterData(filter: FilterDescriptor){
    const newData = data.slice();
    return filterBy(newData, filter);
  }
  function onFilterChange(event: ComboBoxFilterChangeEvent) {
    setFilteredData(filterData(event.filter))
  }
  
  useEffect(() => {
    setFilteredData(data);
  }, [data])

  return (
    <Row style={{alignItems: 'baseline'}}>
      <Col xs={labelSize.xs} sm={labelSize.sm} md={labelSize.md} lg={labelSize.lg} xl={labelSize.xl}>
        <label>
          {label}
        </label>
      </Col>
      <Col xs={inputSize.xs} sm={inputSize.sm} md={inputSize.md} lg={inputSize.lg} xl={inputSize.xl}>
        <ComboBox
          data={filteredData}
          filterable
          onFilterChange={onFilterChange}
          value={
            value in valueToDisplayDataMap
              ? valueToDisplayDataMap[value]
              : null // kendo ComboBox must have value null to reset, does not work with undefined
          }
          textField="text"
          dataItemKey="value"
          onChange={onChange}
        />
      </Col>
    </Row>
  );
}

export default AsolviComboBox;
