import React, { FC } from "react";
import { ISelectFilter } from "../Report";
import AsolviComboBox from "../../../../Elements/AsolviComboBox/AsolviComboBox";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";

interface Props {
  filter: ISelectFilter
  onChange: (event: ComboBoxChangeEvent) => any
}

const SelectFilter: FC<Props> = ({filter, onChange}) => {

  return (
    <AsolviComboBox
      label={filter.name}
      data={filter.values}
      value={filter.value}
      onChange={onChange}
    />
  )
}

export default SelectFilter;
