import * as React from 'react';
import { useEffect, useState } from 'react';
import './TaskLogDetailsGroup.css';
import { Col, Container, Row } from 'react-bootstrap';
import AsolviLabel from '../../../Elements/AsolviLabel/AsolviLabel';
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import AsolviTextArea from '../../../Elements/AsolviTextArea/AsolviTextArea';
import * as TaskReducer from '../../../../reducers/TaskReducer'
import { useInternationalization } from "@progress/kendo-react-intl";
import axios from 'axios';
import { TaskAPIs, TaskAPITypes } from '../../../../sources/apiEndPoints';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store/Store';

export interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  taskDetails: TaskReducer.ITaskLogItem;
  showMachineId: boolean;
}

const TaskLogDetailsGroup: React.FunctionComponent<IProps> = (props) => {
  const { appUrl } = (window as any)["appConfig"];
  const { t } = props;
  const intl = useInternationalization();
  const email = useSelector((state: IAppState) => state.generalState.General.currentUserEmail);
  const token = useSelector((state: IAppState) => state.generalState.General.currentAuth0Token);

  const [logTimeline, setLogTimeline] = useState<TaskAPITypes.GetLogTimeline>([]);
  const task = props.taskDetails;


  useEffect(() => {
    axios.get<TaskAPITypes.GetLogTimeline>(
      appUrl + TaskAPIs.GetLogTimeline(email, task.TaskGuid),
      { headers: { token } }
    ).then((response) => {
      setLogTimeline(response.data);

    }).catch((error) => {
      console.log(error);
    })
  }, [appUrl, email, task.TaskGuid, token]);

  const relevantEvents = logTimeline.filter(
    (item) => {
      if (item.Type === "START_STOP") {
        if (item.Value === "Travel" || "Work") {
          return true;
        }
      }
      if (item.Type === "ASSIGNMENT_EVENT") {
        if (item.Value === "Assigned" || "Downloaded" || "Done") {
          return true
        }
      }
      return null;
    }
  )

  return (
    <Container fluid>
      <div className="task-flex-container">
        <div>
          <div className="taskLogDetailsGroup">
            <Row>
              <Col sm={3}>
                <AsolviLabel labelText={t("TaskLog.status")} value={""} labelColSize={11} valueColSize={1} />
              </Col>
              {
                (task.Status?.toString() === "0") &&
                <Col sm={9}>
                  <span className="k-icon k-i-clock k-icon-size" /> {t("TaskLog.Open")}
                </Col>
              }
              {
                (task.Status?.toString() === "1") &&
                <Col sm={9}>
                  <span className="k-icon k-i-check-outline k-icon-size" /> {t("TaskLog.Closed")}
                </Col>
              }
            </Row>
            <Row>
              <Col sm={12}>
                <AsolviLabel labelText={t("TaskLog.receivedDate")} value={intl.formatDate(task.ReceivedDate, "g")} labelColSize={3} valueColSize={9} />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <AsolviLabel labelText={t("TaskLog.taskType")} value={""} labelColSize={11} valueColSize={1} />
              </Col>
              {
                (task.TaskType?.toString() === "1") &&
                <Col sm={9}>
                  <span className="k-icon k-i-wrench k-icon-size" /> {t("TaskLog.Service")}
                </Col>
              }
              {
                (task.TaskType?.toString() === "2") &&
                <Col sm={9}>
                  <span className="k-icon k-i-inbox k-icon-size" /> {t("TaskLog.Helpdesk")}
                </Col>
              }
            </Row>
            <Row>
              <Col sm={12}>
                <AsolviLabel labelText={t("TaskLog.customerName")} value={task.CustomerName} labelColSize={3} valueColSize={9} />
              </Col>
            </Row><Row>
              <Col sm={12}>
                <AsolviLabel labelText={t("TaskLog.contact")} value={task.ContactName} labelColSize={3} valueColSize={9} />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <AsolviLabel labelText={t("TaskLog.machineNo")} value={task.MachineNo} labelColSize={6} valueColSize={6} />
              </Col>
              {
                (props.showMachineId &&
                  <Col sm={6}>
                    <AsolviLabel labelText={t("TaskLog.machineIdNo")} value={task.MachineIdNo} labelColSize={6} valueColSize={6} />
                  </Col>
                )
              }
            </Row>
            <Row>
              <Col sm={12}>
                <AsolviLabel labelText={t("TaskLog.modelType")} value={task.ModelType} labelColSize={3} valueColSize={9} />
              </Col>
            </Row>
          </div>
          <div className="taskLogDetailsGroup">
            <Row>
              <Col sm={12}>
                <AsolviLabel labelText={t("TaskLog.taskTitle")} value={task.TaskTitle} labelColSize={3} valueColSize={9} />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <AsolviLabel labelText={t("TaskLog.symptom1")} value={task.Symptom1} labelColSize={7} valueColSize={5} />
              </Col>
              <Col sm={3}>
                <AsolviLabel labelText={t("TaskLog.symptom2")} value={task.Symptom2} labelColSize={7} valueColSize={5} />
              </Col>
              <Col sm={3}>
                <AsolviLabel labelText={t("TaskLog.symptom3")} value={task.Symptom3} labelColSize={7} valueColSize={5} />
              </Col>
              {
                (task.TaskType?.toString() === "2") &&
                <Col sm={3}>
                  <AsolviLabel labelText={t("TaskLog.symptom4")} value={task.Symptom4} labelColSize={7} valueColSize={5} />
                </Col>
              }
            </Row>
            <Row>
              <Col sm={12}>
                <AsolviLabel labelText={t("TaskLog.symptomText")} value={""} labelColSize={11} valueColSize={1} />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <AsolviTextArea
                  labelText={""}
                  rows={3} onChange={() => { }}
                  isReadOnly={true}
                  inputText={task.SymptomText}
                  labelColSize={0}
                  valueColSize={12}
                />
              </Col>
            </Row>
          </div>
          <div className="taskLogDetailsGroup">
            <Row>
              <Col sm={12}>
                <AsolviLabel labelText={t("TaskLog.technician")} value={task.Technician} labelColSize={3} valueColSize={9} />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <AsolviLabel labelText={t("TaskLog.action1")} value={task.ActionCode1} labelColSize={7} valueColSize={5} />
              </Col>
              <Col sm={3}>
                <AsolviLabel labelText={t("TaskLog.action2")} value={task.ActionCode2} labelColSize={7} valueColSize={5} />
              </Col>
              <Col sm={3}>
                <AsolviLabel labelText={t("TaskLog.action3")} value={task.ActionCode3} labelColSize={7} valueColSize={5}
                />
              </Col>
              {
                (task.TaskType?.toString() === "2") &&
                <Col sm={3}>
                  <AsolviLabel labelText={t("TaskLog.action4")} value={task.ActionCode4} labelColSize={7} valueColSize={5} />
                </Col>
              }
            </Row>
            <Row>
              <Col sm={12}>
                <AsolviLabel labelText={t("TaskLog.actionText")} value={""} labelColSize={11} valueColSize={1} />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <AsolviTextArea
                  labelText={""}
                  rows={3} onChange={() => { }}
                  isReadOnly={true}
                  inputText={task.ActionText}
                  labelColSize={0}
                  valueColSize={12}
                />
              </Col>
            </Row>
          </div>
        </div>


        <div className="task-timeline">
          {relevantEvents.length > 0
            ? logTimeline.map((item, index) => {
              if (item.Value === "Assigned") {
                return (
                  <Row key={index}>
                    <Col>
                      <span className="k-icon k-i-user k-icon-md center" style={{marginRight: 8}}/>
                      {t("TaskLog.AssignedTo")}
                      <p className="column-layout">{item.Name}</p>
                    </Col>
                    <Col>
                      <p className="center2">{intl.formatDate(new Date(item.Timestamp), "g")}</p>
                    </Col>
                  </Row>
                )
              }
              if (item.Type === "START_STOP") {
                if (item.Value === "Travel") {
                  return (
                    <Row key={index} className="row-layout">
                      <Col>
                        <span className="k-icon k-i-arrow-60-right k-icon-md" style={{marginRight: 8}}/>
                        {t("TaskLog.TravelStarted")}
                      </Col>
                      <Col>
                        <p> {intl.formatDate(new Date(item.Timestamp), "g")}</p>
                      </Col>
                    </Row>
                  )
                }

                else if (item.Value === "Work") {
                  return (
                    <Row key={index} className="row-layout">
                      <Col>
                        <span className="k-icon k-i-wrench k-icon-md k-rotate-90" style={{marginRight: 8}}/>
                        {t("TaskLog.WorkStarted")}
                      </Col>
                      <Col>
                        <p> {intl.formatDate(new Date(item.Timestamp), "g")}</p>
                      </Col>
                    </Row>
                  )
                }
              }

              else if (item.Type === "ASSIGNMENT_EVENT") {
                if (item.Value === "Done") {
                  return (
                    <Row key={index}>
                      <Col className="icon-color-green">
                        <span className="k-icon k-i-information k-icon-md" style={{marginRight: 8}}/>
                        {t("TaskLog.TaskCompleted")}
                      </Col>
                      <Col className="icon-color-green">
                        <p> {intl.formatDate(new Date(item.Timestamp), "g")} </p>
                      </Col>
                    </Row>
                  )
                }
              }
              return null;

            }) :
            <Row>
              <Col>
                <span className="k-icon k-i-information k-icon-md" style={{marginRight: 8}}/>
                {t("TaskLog.NoEventsLoggedYet")}
              </Col>
            </Row>
          }
        </div>
      </div>
    </Container>
  );
}

export default ReactI18Next.withTranslation()(TaskLogDetailsGroup);
