import React, { FC } from "react";
import { IDateFilter } from "../Report";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs/dist/npm/datepicker/DatePicker";
import DateFilterOperator, { DateOperatorEnum } from "./DateFilterOperator";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";

interface Props {
  filter: IDateFilter
  onOperatorChange: (event: DropDownListChangeEvent) => void
  onFirstDateChange: (event: DatePickerChangeEvent) => void
  onSecondDateChange: (event: DatePickerChangeEvent) => void
}

const DateFilter: FC<Props> = ({filter, onFirstDateChange, onSecondDateChange, onOperatorChange}) => {

  return (
    <div style={{display: 'flex'}}>
      <div style={{display: 'flex'}}>
        <label style={{width: '180px'}}>
          {filter.name}
        </label>
        <DateFilterOperator
          operatorId={filter.operator}
          onChange={onOperatorChange}
        />
      </div>
      <div>
        <DatePicker
          onChange={onFirstDateChange}
          disabled={filter.operator === DateOperatorEnum.NoValue}
          value={filter.firstDate}
        />
        {filter.operator === DateOperatorEnum.Between &&
          <DatePicker
            onChange={onSecondDateChange}
            value={filter.secondDate}
          />
        }
      </div>
    </div>
  )
}

export default DateFilter;
