import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MachineGrid from "../../Groups/Machine/MachineGrid/MachineGrid";
import type { IMachineInfo, IMachineVisibilitySettings } from "../../seedComponents/Pages/MachinePageSeedComponent";
import { setSelectedMachineGuidAction } from "../../../slices/DocumentsSlice";
import DocumentsGrid from "./DocumentGrid";
import axios from "axios";
import * as apiEndPoints from "../../../sources/apiEndPoints";
import { IAppState } from "../../../store/Store";

import styles from "./Documents.module.css";

const Documents: FC = () => {
  let { appUrl } = (window as any).appConfig;
  let { currentAuth0Token, activeCustomer, currentUserEmail } = useSelector((state: IAppState) => state.generalState.General);
  let dispatch = useDispatch();

  let handleRowClick = useCallback((machine: IMachineInfo) => {
    dispatch(setSelectedMachineGuidAction(machine?.MachineGuid));
  }, [dispatch]);

  const [visibilitySettings, setVisibilitySettings] = useState<IMachineVisibilitySettings>( {
    AllowAddressEdit: false,
    AllowPropertyEdit: false,
    AutoMachineId: false,
    AutoMachineNo: false,
    MachineWriteAccess: false,
    ShowFilter: false,
    ShowMachineId: false,
    ShowMachineInformation: false,
    ShowProperties: false,
    ShowSortGroup1: false,
    ShowSortGroup2: false,
    ShowSortGroup3: false,
    ShowSortGroup4: false,
    ShowSortGroup5: false
  });

  const [isLoadingMachines, setIsLoadingMachines] = useState<boolean>(false);
  const [machines, setMachines] = useState<IMachineInfo[]>([]);

  useEffect(() => {
    getMachineVisibilitySettings(currentAuth0Token, appUrl, activeCustomer, currentUserEmail)
      .then(settings => setVisibilitySettings(settings));

    setIsLoadingMachines(true);
    getMachinesSearch(currentAuth0Token, appUrl, activeCustomer, currentUserEmail)
      .then(machines => setMachines(machines))
      .then(() => setIsLoadingMachines(false));

  }, [appUrl, currentAuth0Token, activeCustomer, currentUserEmail]);

  const [documentsGridHeight, setDocumentsGridHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const machineGridHeight = 400;

  useEffect(() => {
    setDocumentsGridHeight((ref.current?.clientHeight || 0) - machineGridHeight);
  }, [])

  return (
    <div
      ref={ref}
      className={styles.mainContainer}
    >
      <MachineGrid
        gridStyle={{height: machineGridHeight+'px'}}
        gridData={machines}
        loadingMachines={isLoadingMachines}
        showMachineId={visibilitySettings.ShowMachineId}
        onRowClick={handleRowClick}
        modalOpen={false}
      />
      <DocumentsGrid
        gridStyle={{
          height: documentsGridHeight+'px',
          minHeight: '400px',
        }}
      />
    </div>
  )
}

async function getMachineVisibilitySettings(token: string, appUrl: string, customerGuid: string, email: string): Promise<IMachineVisibilitySettings> {
  const url = appUrl + apiEndPoints.MachineAPIs.GetMachineVisibilitySettings(customerGuid, email);
  const response = await axios.get<IMachineVisibilitySettings>(url, { headers: { token }});
  return response.data;
}

async function getMachines(token: string, appUrl: string, customerGuid: string, email: string): Promise<IMachineInfo[]> {
  const url = appUrl + apiEndPoints.MachineAPIs.GetMachines(customerGuid, email);
  const response = await axios.get<IMachineInfo[]>(url, { headers: { token }});
  return response.data;
}

async function getMachinesSearch(token: string, appUrl: string, customerGuid: string, email: string): Promise<IMachineInfo[]> {
  const url = appUrl + apiEndPoints.MachineAPIs.GetMachinesSearch(customerGuid, email);
  const response = await axios.post<IMachineInfo[]>(url, {},{ headers: { token }});
  return response.data;
}

export default Documents;
