import React from "react";
import './MachineDetailsGroup.css';
import { Col, Container, Row } from 'react-bootstrap';
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import AsolviTextArea from '../../Elements/AsolviTextArea/AsolviTextArea';
import AsolviButton from '../../Elements/AsolviButton/AsolviButton';
import AsolviInput from "../../Elements/AsolviInput/AsolviInput";
import AsolviAdvancedDropdownList, { IColumn } from "../../Elements/AsolviAdvancedDropdownList/AsolviAdvancedDropdownList";
import { ITaskPostalCode } from "../../../reducers/TaskReducer";
import { IMachineInfo, IMachineModelItem, ICodeDescription, ICustomerSortGroupItem, IMachinePropertyItem, ICustomerVisitAddress, IMachineVisibilitySettings } from "../../seedComponents/Pages/MachinePageSeedComponent";
import * as TaskReducer from "../../../reducers/TaskReducer";
import { ISettingsDepartmentCode} from "../../../reducers/SettingsReducer";
import CustomerSortGroupFilter from "./MachineFilter/CustomerSortGroupFilter";
import AsolviDatepicker from '../../Elements/AsolviDatepicker/AsolviDatepicker';
import AsolviCheckbox from '../../Elements/AsolviCheckbox/AsolviCheckbox';
import { ITaskContactGroupData } from "../../../reducers/TaskReducer";
import MachinePropertiesGroup, { IPropertyCodes } from "./MachinePropertiesGroup";
import { Label } from "@progress/kendo-react-labels";

export interface IProps extends ReactI18Next.WithTranslation {
    t: I18Next.TFunction;  
    machineData?: IMachineInfo;
    submitButtonAction:  () => void;
    closeButtonAction: () => void;
    postCodeItems: ITaskPostalCode[];
    customers: TaskReducer.ITaskCustomerItem[];
    departmentCodeItems: ISettingsDepartmentCode[];
    models:IMachineModelItem[];
    sortGroup1Item?: ICustomerSortGroupItem;
    sortGroup2Item?: ICustomerSortGroupItem;
    sortGroup3Item?: ICustomerSortGroupItem;
    sortGroup4Item?: ICustomerSortGroupItem;
    sortGroup5Item?: ICustomerSortGroupItem;
    machineProperties: IMachinePropertyItem[];
    propertyCodes: IPropertyCodes[];
    contactList: ITaskContactGroupData[];
    countries: ICodeDescription[]; 
    visitAddresses: ICustomerVisitAddress[]; 
    visibilitySettings?: IMachineVisibilitySettings;  
    setMachineData: (machineData: IMachineInfo) => void;
    loadingCreateUpdateMachine: boolean;
}

const MachineDetailsGroup: React.FunctionComponent<IProps> = (props) => {
    const { t, sortGroup1Item, sortGroup2Item, sortGroup3Item, sortGroup4Item, sortGroup5Item, models, customers, contactList, countries, departmentCodeItems, postCodeItems, machineData, visitAddresses, visibilitySettings, setMachineData} = props;
    const [editAddress, setEditAddress] = React.useState<boolean>(false);
    const updateMachineData = (newMachineData: Partial<IMachineInfo>) => {
        let merged = {...machineData, ...newMachineData};
        setMachineData(merged);
      };
      
    let postCodeSearchColumns = [] as IColumn[];
    postCodeSearchColumns.push({ field: "Code", header: t("Machine.PostalCode"), width: 120, uniqueKey:"Code" });
    postCodeSearchColumns.push({ field: "Description", header: t("Machine.Description"), width: 300, uniqueKey:"Description" });
  
    let departmentCodeSearchColumns = [] as IColumn[];
    departmentCodeSearchColumns.push({ field: "Code", header: t("Machine.Dept"), width: 120, uniqueKey:"Code" });
    departmentCodeSearchColumns.push({ field: "Description", header: t("Machine.Description"), width: 300, uniqueKey:"Description" });
  
    const customerColumns = [
        {
          field: "CustomerNo",
          header: t("Machine.CustomerNumber"),
          width: 150,
          uniqueKey: "CustomerNo"
        },
        {
          field: "CustomerName",
          header: t("Machine.CustomerName"),
          width: 250,
          uniqueKey: "CustomerName"
        }
    ];

    let modelColumns = [
        {
          field: "ModelType",
          header: t("Machine.ModelType"),
          width: 150,
          uniqueKey: "ModelType"
        },
        {
          field: "Description",
          header: t("Machine.ModelDescription"),
          width: 400,
          uniqueKey: "Description"
        }
      ];

      let contactLayout = [] as IColumn[];
      contactLayout.push({ field: "FirstName", header: t("Contact.FirstName"), width: 150, uniqueKey: "FirstName" });
      contactLayout.push({ field: "LastName", header: t("Contact.LastName"), width: 150, uniqueKey: "LastName" });
      contactLayout.push({ field: "Phone", header: t("Contact.Phone"), width: 150, uniqueKey: "Phone" });
      contactLayout.push({ field: "FaxNo", header: t("Contact.Fax"), width: 150, uniqueKey: "FaxNo" });
      contactLayout.push({ field: "Email", header: t("Contact.Email"), width: 300, uniqueKey: "Email" });

      const countryColumns = [
        {
          field: "Code",
          header: t("Machine.PropertyCode"),
          width: 150,
          uniqueKey: "Code"
        },
        {
          field: "Description",
          header: t("Machine.Country"),
          width: 400,
          uniqueKey: "Description"
        }
      ];

      const visitAddressColumns = [
        {
          field: "AddressName",
          header: t("Machine.AddressName"),
          width: 150,
          uniqueKey: "AddressName"
        },
        {
          field: "Address1",
          header: t("Machine.Address1"),
          width: 250,
          uniqueKey: "Address1"
        },
        {
            field: "Address2",
            header: t("Machine.Address2"),
            width: 250,
            uniqueKey: "Address2"
        },
        {
            field: "PostalLocation",
            header: t("Machine.Description"),
            width: 250,
            uniqueKey: "PostalLocation"
        },

    ];
    return (
        <Container fluid>
          <div className="machineDetailsGroup">      
            <Row>
            <Col sm={6}>     
                <AsolviInput labelText={t("Machine.MachineNo")} 
                onChange={visibilitySettings?.MachineWriteAccess ? (e: any) => updateMachineData({MachineNo: e.value}) : (e) => void(e) }
                required = {true} 
                disabled = {visibilitySettings?.AutoMachineNo?? false}            
                inputValue={machineData?.MachineNo} 
                />         
            </Col>           
            <Col sm={6}> 
                <AsolviCheckbox label = {t("Machine.Active")} onChange={visibilitySettings?.MachineWriteAccess ? (e: any) => updateMachineData({Active: e.value}) : (e) => void(e) } value={machineData?.Active?machineData.Active:false}/>
            </Col>
            </Row><Row>
            {(visibilitySettings?.ShowMachineId ?? false) && <Col sm={6}>     
                <AsolviInput labelText={t("Machine.IdNo")} 
                onChange={visibilitySettings?.MachineWriteAccess ? (e: any) => updateMachineData({MachineId: e.value}) : (e) => void(e) }
                disabled = {visibilitySettings?.AutoMachineId?? false}            
                inputValue={machineData?.MachineId} 
                type={"number"}
                />         
            </Col> }           
            </Row><Row>
            <Col sm={6}>            
            <AsolviDatepicker 
                labelText={t("Machine.InstallationDate")}                                       
                inputValue={machineData?.InstallationDate ? new Date(machineData?.InstallationDate) : undefined}
                onSelect={visibilitySettings?.MachineWriteAccess ? (e: any) => updateMachineData({InstallationDate: e.value}) : (e) => void(e) } />                                    
            </Col>
            </Row><Row>
            <Col sm={6}>
                <AsolviAdvancedDropdownList
                labelText={t("Machine.Dept")}
                shownText={"CodeAndDescription"}
                columns={departmentCodeSearchColumns}
                data={departmentCodeItems}
                onSelect={visibilitySettings?.MachineWriteAccess ? (e) => updateMachineData({Dept: e.value?.Code}) : (e) => void(e) } 
                selectedItem={props.departmentCodeItems?.find(ex => ex.Code === machineData?.Dept)}
                required = {true}                
                />
            </Col>
            </Row><Row>
            <Col sm={6}>
            <AsolviAdvancedDropdownList
                labelText={t("Machine.Model")}
                shownText="ModelType"
                columns={modelColumns}
                data={models}
                onSelect={visibilitySettings?.MachineWriteAccess ? (e) => updateMachineData({ModelType: e.value?.ModelType, ModelGuid: e.value?.ModelGuid}) : (e) => void(e) } 
                selectedItem={models?.find(ex => ex.ModelType === machineData?.ModelType)}
                required = {true}
            />
            </Col>
            </Row>
            </div>
            <div className="machineDetailsGroup">
                <Row>
                <Col sm={6}>
                <AsolviAdvancedDropdownList
                    labelText={t("Machine.Customer")}
                    shownText="CustomerName"
                    columns={customerColumns}
                    data={customers}
                    onSelect={visibilitySettings?.MachineWriteAccess ? (e) => updateMachineData({
                        CustomerName: e.value?.CustomerName, CustomerGuid: e.value?.CustomerGuid,                         
                        AddressName: "",
                        Address1: "",
                        Address2: "",
                        Address3: "",
                        AddressCountry: "",
                        PostalCode: "",
                        PostDescription: "",
                        AddressRowNo: -1,
                        AddressPhone: "",
                        ContactNo: undefined
                    }) : (e) => void(e) } 
                    selectedItem={customers?.find(ex => ex.CustomerName === machineData?.CustomerName)}
                    required = {true}
                />
                </Col>
                </Row><Row>
                    <Col sm={6}>
                        <AsolviAdvancedDropdownList
                            labelText={t("Machine.AddressName")}
                            shownText="AddressName"
                            columns={visitAddressColumns}
                            data={visitAddresses}
                            onSelect={visibilitySettings?.MachineWriteAccess ? (e) => updateMachineData(
                                {
                                    AddressName: e.value?.AddressName,
                                    Address1: e.value?.Address1,
                                    Address2: e.value?.Address2,
                                    Address3: e.value?.Address3,
                                    AddressCountry: e.value?.Country,
                                    PostalCode: e.value?.PostalCode,
                                    PostDescription: e.value?.PostalLocation,
                                    AddressRowNo: e.value?.RowNo,
                                    AddressPhone: e.value?.Phone
                                }) : (e) => void(e) }
                            selectedItem={visitAddresses?.find(ex => ex.RowNo === machineData?.AddressRowNo)}
                            required = {true}
                        />
                    </Col>
                    {((visibilitySettings?.AllowAddressEdit && visibilitySettings?.MachineWriteAccess) ?? false) &&
                    <Col sm={6}> 
                        <AsolviCheckbox label = {t("Machine.EditAddress")} onChange={(e: any) => setEditAddress(e.value)} value={editAddress}/>
                    </Col> 
                    }
                </Row><Row>
                <Col sm={6}>
                    <AsolviInput labelText={t("Machine.Address1")} 
                        onChange={(e: any) => updateMachineData({Address1: e.value, AddressRowNo: 1})} 
                        disabled = {!editAddress}
                        inputValue={machineData?.Address1} />      
                </Col>
                </Row><Row>
                <Col sm={6}>
                    <AsolviInput labelText={t("Machine.Address2")} 
                        onChange={(e: any) => updateMachineData({Address2: e.value})} 
                        disabled = {!editAddress}
                        inputValue={machineData?.Address2} />  
                </Col> 
                </Row><Row> 
                <Col sm={6}>
                    <AsolviInput labelText={t("Machine.Address3")} 
                        onChange={(e: any) => updateMachineData({Address3: e.value})} 
                        disabled = {!editAddress}
                        inputValue={machineData?.Address3} />  
                </Col> 
                </Row>
                <Row> 
                <Col sm={6}>         
                    <AsolviAdvancedDropdownList
                    labelText={t("Machine.Country")}
                    shownText={"CodeAndDescription"}
                    columns={countryColumns}
                    data={countries}
                    onSelect={(e) => updateMachineData({AddressCountry: e.value?.Code})}
                    selectedItem={props.countries?.find(ex => ex.Code === machineData?.AddressCountry)}
                    required = {true}
                    disabled = {!editAddress}
                    /> 
                </Col>                     
                <Col sm={6}>   
                {(visibilitySettings?.ShowSortGroup1 ?? false) && sortGroup1Item && <CustomerSortGroupFilter
                    sortGroup={sortGroup1Item}
                    selectedCode={machineData?.SortGroup1}
                    onSelected={visibilitySettings?.MachineWriteAccess ? (code: ICodeDescription) => updateMachineData({ SortGroup1: code?.Code }) : (e) => void(e) }
                    /> }
                </Col>
                </Row><Row>
                <Col sm={6}>         
                    <AsolviAdvancedDropdownList
                    labelText={t("Machine.PostalCode")}
                    shownText={"CodeAndDescription"}
                    columns={postCodeSearchColumns}
                    data={postCodeItems}
                    onSelect={visibilitySettings?.MachineWriteAccess ? (e) => updateMachineData({PostalCode: e.value?.Code, PostDescription: e.value?.Description}) : (e) => void(e) }
                    selectedItem={props.postCodeItems?.find(ex => ex.Code === machineData?.PostalCode)}
                    required = {true}
                    disabled = {!editAddress}
                    /> 
                </Col>                  
                <Col sm={6}>   
                    {(visibilitySettings?.ShowSortGroup2 ?? false) && sortGroup2Item && <CustomerSortGroupFilter
                    sortGroup={sortGroup2Item}
                    selectedCode={machineData?.SortGroup2}
                    onSelected={visibilitySettings?.MachineWriteAccess ? (code: ICodeDescription) => updateMachineData({ SortGroup2: code?.Code }) : (e) => void(e) }              
                    />}
                </Col>
                </Row>
                <Row>
                <Col sm={6}>
                    <AsolviInput labelText={t("Machine.CustomerPhoneNumber")} 
                    onChange={visibilitySettings?.MachineWriteAccess ? (e: any) => updateMachineData({AddressPhone: e.value}) : (e) => void(e) } 
                    inputValue={machineData?.AddressPhone} />
                </Col>                
                <Col sm={6}>   
                    {(visibilitySettings?.ShowSortGroup3 ?? false) && sortGroup3Item && <CustomerSortGroupFilter
                    sortGroup={sortGroup3Item}
                    selectedCode={machineData?.SortGroup3}
                    onSelected={visibilitySettings?.MachineWriteAccess ? (code: ICodeDescription) => updateMachineData({ SortGroup3: code?.Code }) : (e) => void(e) }              
                    />}
                </Col>  
                </Row>
                <Row> 
                <Col sm={6}>
                    <AsolviInput labelText={t("Machine.Location")} 
                        onChange={visibilitySettings?.MachineWriteAccess ? (e: any) => updateMachineData({Location: e.value}) : (e) => void(e) } 
                        inputValue={machineData?.Location} />
                </Col> 
                <Col sm={6}>   
                    {(visibilitySettings?.ShowSortGroup4 ?? false) && sortGroup4Item && <CustomerSortGroupFilter
                    sortGroup={sortGroup4Item}
                    selectedCode={machineData?.SortGroup4}
                    onSelected={visibilitySettings?.MachineWriteAccess ? (code: ICodeDescription) => updateMachineData({ SortGroup4: code?.Code }) : (e) => void(e) }              
                    />}
                </Col> 
                </Row><Row>
                <Col sm={6}>
                    <AsolviAdvancedDropdownList
                        labelText={t("Contact.SelectLabel")}
                        shownText="FullName"
                        data={contactList}
                        columns={contactLayout}
                        onSelect={visibilitySettings?.MachineWriteAccess ? (e) => updateMachineData({ContactNo: e.value?.ContactNo}) : (e) => void(e) }
                        selectedItem={contactList?.find(ex => ex.ContactNo === machineData?.ContactNo)}
                    />
                </Col>
                <Col sm={6}>   
                    {(visibilitySettings?.ShowSortGroup5 ?? false) && sortGroup5Item && <CustomerSortGroupFilter
                    sortGroup={sortGroup5Item}
                    selectedCode={machineData?.SortGroup5}
                    onSelected={visibilitySettings?.MachineWriteAccess ? (code: ICodeDescription) => updateMachineData({ SortGroup5: code?.Code }) : (e) => void(e) }              
                    />}
                </Col>
                </Row>        
            </div>
            {((visibilitySettings?.ShowProperties ?? false) || (visibilitySettings?.ShowMachineInformation ?? false)) &&
            <div className="machineDetailsGroup">
                <Row>
                    {(visibilitySettings?.ShowProperties ?? false) && 
                    <Col sm={6}>
                        <Label>{t("Properties.machineLabel")}</Label>
                        <MachinePropertiesGroup 
                                machineProperties={props.machineProperties} 
                                propertyCodes={props.propertyCodes} 
                                updateMachinePropertiesData={(properties: IMachinePropertyItem[]) => updateMachineData({Properties: properties})}
                                allowEdit={visibilitySettings ? visibilitySettings.AllowPropertyEdit && visibilitySettings.MachineWriteAccess : false}
                        />
                    </Col>
                    }
                    {(visibilitySettings?.ShowMachineInformation ?? false) &&
                    <Col sm={6}>
                    <AsolviTextArea labelText={t("Machine.Information")} 
                            rows={5} onChange={(e) => {
                                updateMachineData({ Information: e.value })
                            }} 
                            isReadOnly={!visibilitySettings?.MachineWriteAccess} inputText={machineData?.Information?? ""} 
                            labelColSize={6} 
                            valueColSize={12} 
                    />
                    </Col>
                    }
                </Row>      
            </div>
            }

            <Row className="MachineDetailsFooter">
            <Col sm={8}></Col>            
            <Col sm={2}>
                {(visibilitySettings?.MachineWriteAccess ?? false) &&
                    <AsolviButton onClick={props.submitButtonAction}
                                buttonText={t("TaskCreate.SubmitButtonText")} buttonType={'button'} 
                                loading={props.loadingCreateUpdateMachine}
                                className="k-primary" />
                }
            </Col>
            <Col sm={2}>
                <AsolviButton onClick={props.closeButtonAction} buttonText={t("Standard.closeButton")} buttonType={'button'}/>
            </Col>
        </Row>
        
    </Container>
    );
}

export default ReactI18Next.withTranslation()(MachineDetailsGroup);