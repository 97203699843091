import * as React from 'react';
import TaskLogGridGroup from '../../../Groups/Task/TaskLogGridGroup/TaskLogGridGroup';
import * as TaskReducer from '../../../../reducers/TaskReducer'
import * as Store from '../../../../store/Store';
import * as ReactRedux from "react-redux";
import { parseDate } from '@telerik/kendo-intl';

export interface IProps {
  showServiceLog: boolean;
  showHelpdeskLog: boolean;  
  TaskVisibilitySettings: TaskReducer.ITaskVisibilitySettings;  
}

const TaskLogGridGroupSeedComponent: React.FunctionComponent<IProps> = (props) => {
  const TaskLog = ReactRedux.useSelector((state: Store.IAppState) => state.taskState.TaskLog.TaskLog);
  const loadingData = ReactRedux.useSelector((state: Store.IAppState) => state.taskState.TaskLog.Loading);

  const listWithFormattedDates = (logListData: TaskReducer.ITaskLogItem[]) => {
    let result = [] as TaskReducer.ITaskLogItem[];
    result = logListData.map(log => {
      const formattedDate = parseDate(log.ReceivedDate.toString());
      const filterableDate = new Date(formattedDate.getFullYear(), formattedDate.getMonth(), formattedDate.getDate());
      return { ...log, ReceivedDate: formattedDate, FilterDate: filterableDate } as TaskReducer.ITaskLogItem;
    });
    return result;
  }

  return (
    <TaskLogGridGroup
      loadingData={loadingData}
      showHelpdeskLog={props.showHelpdeskLog}
      data={TaskLog && listWithFormattedDates(TaskLog)}
      showMachineId={props.TaskVisibilitySettings.ShowSelectMachine.ShowMachineId}
    />
  );
}

const mapStateToProps = (store: Store.IAppState) => {
  return {
    TaskVisibilitySettings: store.taskState.TaskVisibilitySettings    
  }
}

export default ReactRedux.connect(mapStateToProps)(TaskLogGridGroupSeedComponent);
