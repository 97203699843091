import * as React from "react";
import * as ReactRedux from "react-redux";
import * as Store from "../../../store/Store";
import SettingsPage from "../../Pages/SettingsPage/SettingsPage";
import * as SettingsReducer from "../../../reducers/SettingsReducer";
import * as GeneralActions from "../../../actions/GeneralActions";
import * as GeneralReducer from "../../../reducers/GeneralReducer";
import * as Auth0 from '@auth0/auth0-react';
import AsolviModal from "../../Auxiliary/AsolviModal/AsolviModal";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from "i18next";
import { removeTrailingSlash } from "../../../utils/trailingSlash";

interface IProps extends ReactI18Next.WithTranslation {
  t: I18Next.TFunction;
  Settings: SettingsReducer.ISettings;
  General: GeneralReducer.IGeneral;
  handleAccessDenied(currentAccessDeniedState:boolean): (currentAccessDeniedState:boolean) => void;
  setRenderHomePageSeedComponent: (value: boolean) => void;
}

const SettingsPageSeedComponent: React.FunctionComponent<IProps> = (Props) => {
  const { t, handleAccessDenied} = Props;
  const auth0Logout = Auth0.useAuth0().logout;
  const handleClose = () => {
    handleAccessDenied(false);
    let returnUrl = removeTrailingSlash(window.location.origin + window.location.pathname);    
    auth0Logout({ returnTo: returnUrl });    
  };


  React.useEffect(() => {
    const updateAccessDeniedModal = () => {
      try {
        if (Props.Settings.contactDropdownList) {
          handleAccessDenied(JSON.parse(Props.Settings.contactDropdownList).length < 1);
        }      
      } catch (error) {
        console.log(error)
      }
    };
    updateAccessDeniedModal();   
  }, [Props.Settings.contactDropdownList,handleAccessDenied]);
  
  return (
    <>
      <AsolviModal header={t("Standard.accessDenied")} showModal={Props.General.showAccessDenied} handleClose={handleClose}
                   message={t("Standard.accessDeniedMessage")} shouldDisplayFooter={true} shouldDisplaySpinner= {false}/>
      <SettingsPage setRenderHomePageSeedComponent={Props.setRenderHomePageSeedComponent}/>
    </>
  )
}

const mapStateToProps = (store: Store.IAppState) => {
  return {
    Settings: store.settingsState.Settings,
    General: store.generalState.General,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleAccessDenied: (currentAccessDeniedState:boolean) => dispatch(GeneralActions.handleAccessDeniedModal(currentAccessDeniedState)), 
  };
};

export default ReactRedux.connect(mapStateToProps,mapDispatchToProps)(ReactI18Next.withTranslation()(SettingsPageSeedComponent));