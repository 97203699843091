import React, { FC, useEffect, useState } from "react";
import { IGridColumn } from "../../Elements/AsolviAdvancedGrid/interfaces";
import { useTranslation } from "react-i18next";
import AsolviAdvancedGrid from "../../Elements/AsolviAdvancedGrid/AsolviAdvancedGrid";
import { ReportDTO } from "./Reports";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/Store";
import axios from "axios";
import { ReportAPIs } from "../../../sources/apiEndPoints";
import AsolviFadeInOut from "../../Elements/AsolviFadeInOut/AsolviFadeInOut";
import styles from "./ReportsGrid.module.css";
import { Spinner } from "react-bootstrap";

interface Props {
  onSelectReport: (report: ReportDTO) => void,
}


const ReportsGrid: FC<Props> = ({onSelectReport}) => {
  let { appUrl } = (window as any).appConfig;

  const { t } = useTranslation();

  const [reports, setReports] = useState<ReportDTO[]>([]);
  const [isLoadingReports, setIsLoadingReports] = useState(false);

  let { currentAuth0Token, activeCustomer }
    = useSelector((state: IAppState) => state.generalState.General);

  useEffect(() => {
    const url = appUrl + ReportAPIs.GetReports(activeCustomer)
    setIsLoadingReports(true);
    axios.get<ReportDTO[]>(
      url, { headers: { token: currentAuth0Token } }
    ).then(
      res => {
        setReports(res.data);
        setIsLoadingReports(false);
      }
    );
  }, [activeCustomer, appUrl, currentAuth0Token])

  const gridLayout: IGridColumn<ReportDTO>[] = [{
    fieldName: "ObjNo",
    visible: false,
    fieldLanguageKey: "",
  }, {
    fieldName: "ReportName",
    fieldLanguageKey: t("Reports.Grid.Name"),
    editable: false,
    visible: true,
  }, {
    fieldName: "ReportDescription",
    fieldLanguageKey: t("Reports.Grid.Description"),
    editable: false,
    visible: true,
  }]

  return (
    <div>
      <AsolviFadeInOut show={isLoadingReports} lazyChildren>
        <div className={styles.gridSpinner}>
          <Spinner animation="border" variant="dark" />
        </div>
      </AsolviFadeInOut>
      <AsolviAdvancedGrid
        data={reports}
        dataKeyField="ObjNo"
        gridLayout={gridLayout}
        handleItemDoubleClick={onSelectReport}
      />
    </div>
  )
}

export default ReportsGrid;
