import * as React from "react";
import * as ReactI18Next from 'react-i18next';
import * as I18Next from 'i18next';
import SettingsGroup from "../../Groups/SettingsGroup/SettingsGroup";
import languageDropDownItems from "../../../sources/languageDropDownItems";
import databasesDropDownItems from "../../../sources/databaseDropDownItems";
import customerDropDownItems from "../../../sources/customerDropDownItems";
import * as SettingsReducer from './../../../reducers/SettingsReducer';
import * as Store from "../../../store/Store";
import * as ReactRedux from "react-redux";
import * as GeneralActions from "../../../actions/GeneralActions";
import * as GeneralReducer from "../../../reducers/GeneralReducer";
import * as DisplaySettings from "../../../sources/settingsDisplay";
import { useCallback } from "react";
import AsolviNotification from "../../Elements/AsolviNotification/AsolviNotification";

interface IProps extends ReactI18Next.WithTranslation {
    t: I18Next.TFunction;    
    skipSettingsPage: () => void;    
    Settings: SettingsReducer.ISettings;
    General: GeneralReducer.IGeneral;
    handleSelectedDatabase(selectedDb:string): (selectedDb:string) => void;
    handleSelectedLanguage(selectedLang:string): (selectedLang:string) => void;
    handleSelectedCustomer(selectedCust:string): (selectedCust:string) => void;
    setRenderHomePageSeedComponent: (value: boolean) => void;
}

const SettingsGroupSeedComponent: React.FunctionComponent<IProps> = (Props) => { 
  const hideCustomers = DisplaySettings.default("HIDE_DROPDOWN_CUSTOMER", Props.Settings.defaultSettings);
  const hideDatabase = DisplaySettings.default("HIDE_DROPDOWN_DATABASE",Props.Settings.defaultSettings);
  const hideLanguage = DisplaySettings.default("HIDE_DROPDOWN_LANGUAGE",Props.Settings.defaultSettings);

  const [showNotify, setShowNotify] = React.useState<boolean>(false);
  const [notifyMessage, setNotifyMessage] = React.useState<string>("");
  const [notifyStyle, setNotifyStyle] = React.useState<any>(undefined);
  
  const handleCloseNotification = useCallback(() => {
    setShowNotify(false);
  },[]);

  const handleContinueButton = () => {
    setShowNotify(false);  
    if (hideCustomers || (Props.General.activeCustomer !== null && Props.General.activeCustomer !== undefined && Props.General.activeCustomer !== "")) {
      Props.skipSettingsPage();
      Props.setRenderHomePageSeedComponent(true);
    } else {
      setNotifyMessage(Props.t("Standard.selectCustomer"));      
      setNotifyStyle('warning');
      setShowNotify(true);
    }
  }
  
  return (
    <>
      <SettingsGroup
        languageDropDownItems={languageDropDownItems(Props.handleSelectedLanguage)}
        databasesDropDownItems={databasesDropDownItems(Props.Settings.dbInfo, Props.handleSelectedDatabase)}
        customersDropDownItems={customerDropDownItems(Props.Settings.contactDropdownList, Props.handleSelectedCustomer)}
        continueButton={handleContinueButton}
        hideLanguage = {hideLanguage}
        hideDatabase = {hideDatabase}
        hideCustomers = {hideCustomers}
      />
      <AsolviNotification
        message = {notifyMessage}
        showNotify = {showNotify}
        showIcon = {true}
        closeAction = {handleCloseNotification}
        style = {notifyStyle} 
        setPosition={{ position: "relative", left: "50%" }} />
    </>
  )
}

const mapStateToProps = (store: Store.IAppState) => {
  return {
    Settings: store.settingsState.Settings,
    General: store.generalState.General,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
      skipSettingsPage: () => dispatch(GeneralActions.skipSettings()),
      handleSelectedDatabase: (selectedDb:string) => dispatch(GeneralActions.handleSelectedDatabase(selectedDb)),
      handleSelectedLanguage: (selectedLng:string) => dispatch(GeneralActions.handleSelectedLanguage(selectedLng)),
      handleSelectedCustomer: (selectedCust:string) => dispatch(GeneralActions.handleSelectedCustomer(selectedCust)),
  }
}

export default ReactRedux.connect(mapStateToProps,mapDispatchToProps)(ReactI18Next.withTranslation()(SettingsGroupSeedComponent));